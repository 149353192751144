import {
  IAddDealComparisonForm,
  IComparisonItem,
  IDealComparisonInputs,
  IDealComparisonOutputs,
  IUpdateDealComparisonForm,
} from "../interfaces";
import {
  CARRYFORWARD_CREDITS,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_EQUITY_TYPES,
  DEAL_TE_SOLVE_FORS,
  DEAL_TERM_TERM_DEBT_TYPES,
} from "./deal";
import { PROJECT_COST_TYPE, PROJECT_DEPRECIATION_SYSTEM } from "./project";

export const UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE: IUpdateDealComparisonForm =
  {
    name: "",
    benchmark_deal: "",
    deal_ids: [],
    id: "",
  };

export const ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE: IAddDealComparisonForm = {
  name: "",
  benchmark_deal: "",
  deal_ids: [],
};

export const DEAL_COMMON_COMPARISON_ITEMS: IComparisonItem<
  keyof Pick<
    IDealComparisonInputs["deal"],
    "structure" | "tax_credit_structure" | "term_debt_structure"
  >
>[] = [
  {
    id: "structure",
    hierarchy: ["Deal Structure"],
    property: "Deal Structure",
    enum: DEAL_STRUCTURE_TYPE,
  },
  {
    id: "tax_credit_structure",
    hierarchy: ["Tax Credit Structure"],
    property: "Tax Credit Structure",
    enum: DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  },
  {
    id: "term_debt_structure",
    hierarchy: ["Debt Structure"],
    property: "Debt Structure",
    enum: DEAL_TERM_TERM_DEBT_TYPES,
  },
];

export const DEAL_GENERAL_COMPARISON_ITEMS: IComparisonItem<
  keyof Pick<
    IDealComparisonInputs["deal"],
    "project_type" | "capacity_ac" | "capacity_dc"
  >
>[] = [
  {
    id: "project_type",
    hierarchy: ["General", "Technology"],
  },
  {
    id: "capacity_ac",
    hierarchy: ["General", "Capacity AC"],
    suffix: " MW",
  },
  {
    id: "capacity_dc",
    hierarchy: ["General", "Capacity DC"],
    suffix: " MW",
  },
];

export const DEAL_TIMING_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["timing"]
>[] = [
  {
    id: "start_date",
    hierarchy: ["Timing", "Closing Date"],
  },
  {
    id: "tax_equity_funding_date",
    hierarchy: ["Timing", "Investor Funding Date"],
  },
  {
    id: "tax_equity_date_1",
    hierarchy: ["Timing", "Term Conversion Date"],
  },
  {
    id: "operating_life_years",
    hierarchy: ["Timing", "Deal Operating Life"],
    suffix: " Yrs",
  },
  {
    id: "end_date",
    hierarchy: ["Timing", "Deal End Date"],
  },
];

export const DEAL_COST_COMPARISON_ITEMS: IComparisonItem<
  | keyof IDealComparisonInputs["cost"]["costs"]
  | keyof Pick<
      IDealComparisonInputs["cost"],
      | "fmv"
      | "fmv_step_up"
      | "profit_percent"
      | "total_cost_calculated"
      | "project_indirect_cost"
    >
>[] = [
  ...Object.keys(PROJECT_COST_TYPE).map((costKey) => ({
    id: costKey as keyof typeof PROJECT_COST_TYPE,
    hierarchy: [
      "Project Costs",
      PROJECT_COST_TYPE[costKey as keyof typeof PROJECT_COST_TYPE],
    ],
    isDollar: true,
    nestedObjLevel: 1,
  })),
  {
    id: "project_indirect_cost",
    hierarchy: ["Project Costs", "Deal Project Indirect Cost"],
    isDollar: true,
  },
  {
    id: "total_cost_calculated",
    hierarchy: ["Project Costs", "Total Project Cost"],
    isDollar: true,
  },
  {
    id: "fmv_step_up",
    hierarchy: ["Project Costs", "FMV Step Up Amount"],
    isDollar: true,
  },
  {
    id: "profit_percent",
    hierarchy: ["Project Costs", "FMV Step Up Percent"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "fmv",
    hierarchy: ["Project Costs", "FMV"],
    isDollar: true,
  },
];

export const DEAL_COST_PER_WDC_COMPARISON_ITEMS: IComparisonItem<
  | keyof IDealComparisonInputs["cost_per_watt_dc"]["costs"]
  | keyof Pick<
      IDealComparisonInputs["cost_per_watt_dc"],
      "fmv" | "fmv_step_up" | "total_cost_calculated" | "project_indirect_cost"
    >
>[] = [
  ...Object.keys(PROJECT_COST_TYPE).map((costKey) => ({
    id: costKey as keyof typeof PROJECT_COST_TYPE,
    hierarchy: [
      "Project Costs ($/Wdc)",
      PROJECT_COST_TYPE[costKey as keyof typeof PROJECT_COST_TYPE],
    ],
    nestedObjLevel: 1,
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  })),
  {
    id: "project_indirect_cost",
    hierarchy: ["Project Costs ($/Wdc)", "Deal Project Indirect Cost"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "total_cost_calculated",
    hierarchy: ["Project Costs ($/Wdc)", "Total Project Cost"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "fmv_step_up",
    hierarchy: ["Project Costs ($/Wdc)", "FMV Step Up Amount"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "fmv",
    hierarchy: ["Project Costs ($/Wdc)", "FMV"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
];

export const DEAL_CREDIT_SUPPORT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["credit_support_costs"]
>[] = [
  {
    id: "interconnection",
    hierarchy: ["Credit Support", "Interconnection"],
    isDollar: true,
  },
  {
    id: "lease",
    hierarchy: ["Credit Support", "Lease"],
    isDollar: true,
  },
  {
    id: "offtake",
    hierarchy: ["Credit Support", "Offtake"],
    isDollar: true,
  },
  {
    id: "other",
    hierarchy: ["Credit Support", "Other"],
    isDollar: true,
  },
  {
    id: "total",
    hierarchy: ["Credit Support", "Total"],
    isDollar: true,
  },
];

export const DEAL_FEE_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["deal_fees"]
>[] = [
  {
    id: "deal_fees_amortized",
    hierarchy: ["Deal Fees", "Expensed"],
    isDollar: true,
  },
  {
    id: "deal_fees_expensed",
    hierarchy: ["Deal Fees", "Amortized"],
    isDollar: true,
  },
];

export const DEAL_DEPRECIATION_FEE_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["depreciation"]
>[] = [
  {
    id: "macrs_election",
    hierarchy: ["Depreciation", "Depreciation Election"],
    enum: PROJECT_DEPRECIATION_SYSTEM,
  },
];

export const DEAL_TAX_CREDIT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["tax_credit"]
>[] = [
  {
    id: "itc_total",
    hierarchy: ["Tax Credits", "ITC Total"],
    isDollar: true,
  },
  {
    id: "ptc_total",
    hierarchy: ["Tax Credits", "PTC Total"],
    isDollar: true,
  },
];

export const DEAL_PROFORMA_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["proforma"]
>[] = [
  {
    id: "revenue_total",
    hierarchy: ["Pro Forma", "Total Revenue"],
    isDollar: true,
  },
  {
    id: "expenses",
    hierarchy: ["Pro Forma", "Total Expenses"],
    isDollar: true,
  },
  {
    id: "ebitda",
    hierarchy: ["Pro Forma", "EBITDA"],
    isDollar: true,
  },
];

export const DEAL_PARTNERSHIP_PORTFOLIO_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["partnership"]
>[] = [
  {
    id: "capital_contributions",
    hierarchy: ["Partnership/Portfolio", "(-) Capital Contributions"],
    isDollar: true,
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Partnership/Portfolio", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_with_tax_credit",
    hierarchy: ["Partnership/Portfolio", "Pre-Tax IRR (with Credits)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Partnership/Portfolio", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Partnership/Portfolio", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Partnership/Portfolio", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Partnership/Portfolio", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_SPONSOR_EQUITY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["sponsor_equity"]
>[] = [
  {
    id: "capital_contributions",
    hierarchy: ["Sponsor Equity", "(-) Capital Contributions"],
    isDollar: true,
  },
  {
    id: "fmv_step_up",
    hierarchy: ["Sponsor Equity", "(+) FMV Step Up"],
    isDollar: true,
  },
  {
    id: "debt_proceeds",
    hierarchy: ["Sponsor Equity", "(+) Debt Proceeds"],
    isDollar: true,
  },
  {
    id: "equity",
    hierarchy: ["Sponsor Equity", "(-) Net Equity"],
    isDollar: true,
  },
  {
    id: "negated_equity",
    hierarchy: ["Sponsor Equity", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Sponsor Equity", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_irr_carryforward",
    hierarchy: ["Sponsor Equity", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Sponsor Equity", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Sponsor Equity", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash_yield_5_yr",
    hierarchy: ["Sponsor Equity", "Pre-Tax Cash Yield (5-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_cash_yield_5_yr",
    hierarchy: ["Sponsor Equity", "After-Tax Cash Yield (5-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_cash_yield_10_yr",
    hierarchy: ["Sponsor Equity", "Pre-Tax Cash Yield (10-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_cash_yield_10_yr",
    hierarchy: ["Sponsor Equity", "After-Tax Cash Yield (10-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_moic",
    hierarchy: ["Sponsor Equity", "Pre-Tax MOIC"],
    suffix: "x",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_moic",
    hierarchy: ["Sponsor Equity", "After-Tax MOIC"],
    suffix: "x",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_moic_buyout_term",
    hierarchy: ["Sponsor Equity", "Pre-Tax MOIC (Buyout-Term)"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "after_tax_moic_buyout_term",
    hierarchy: ["Sponsor Equity", "After-Tax MOIC (Buyout-Term)"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_payback_period",
    hierarchy: ["Sponsor Equity", "Pre-Tax Payback Period"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "after_tax_payback_period",
    hierarchy: ["Sponsor Equity", "After-Tax Payback Period"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_discount_rate",
    hierarchy: ["Sponsor Equity", "Pre-Tax Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_discount_rate",
    hierarchy: ["Sponsor Equity", "After-Tax Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_npv",
    hierarchy: ["Sponsor Equity", "Pre-Tax NPV"],
    isDollar: true,
  },
  {
    id: "after_tax_npv",
    hierarchy: ["Sponsor Equity", "After-Tax NPV"],
    isDollar: true,
  },
  {
    id: "pre_tax_npv_buyout_term",
    hierarchy: ["Sponsor Equity", "Pre-Tax NPV (Buyout-Term)"],
    isDollar: true,
  },
  {
    id: "after_tax_npv_buyout_term",
    hierarchy: ["Sponsor Equity", "After-Tax NPV (Buyout-Term)"],
    isDollar: true,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Sponsor Equity", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Sponsor Equity", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Sponsor Equity", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Sponsor Equity", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_DEBT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["debt"]
>[] = [
  {
    id: "debt_amount",
    hierarchy: ["Term Debt", "Debt Amount"],
    isDollar: true,
  },
  {
    id: "debt_term",
    hierarchy: ["Term Debt", "Amortization Tenor"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "min_debt_service_coverage_ratio",
    hierarchy: ["Term Debt", "Min Debt Service Coverage Ratio"],
    suffix: "x",
    decimalDigits: 3,
  },
  {
    id: "avg_debt_service_coverage_ratio",
    hierarchy: ["Term Debt", "Avg Debt Service Coverage Ratio"],
    suffix: "x",
    decimalDigits: 3,
  },
  {
    id: "debt_irr",
    hierarchy: ["Term Debt", "Debt IRR"],
    suffix: "%",
    decimalDigits: 3,
  },
  {
    id: "wal",
    hierarchy: ["Term Debt", "WAL"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "principal",
    hierarchy: ["Term Debt", "Principal Repayments"],
    isDollar: true,
  },
  {
    id: "interest",
    hierarchy: ["Term Debt", "Interest Payments"],
    isDollar: true,
  },
];

export const DEAL_TAX_EQUITY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["tax_equity"]
>[] = [
  {
    id: "type",
    hierarchy: ["Tax Equity", "Type"],
    enum: DEAL_TAX_EQUITY_TYPES,
  },
  {
    id: "solve_for",
    hierarchy: ["Tax Equity", "Solve For"],
    enum: DEAL_TE_SOLVE_FORS,
  },
  {
    id: "capital_contributions",
    hierarchy: ["Tax Equity", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "after_tax_irr_flip_term",
    hierarchy: ["Tax Equity", "After-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Tax Equity", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Tax Equity", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi",
    hierarchy: ["Tax Equity", "ROI"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi_buyout_term",
    hierarchy: ["Tax Equity", "ROI (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_flip_term",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Tax Equity", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_with_tax_credit",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (with Credits)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_floor",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (with Credits) Floor"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_shortfall",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (with Credits) Shortfall"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_cash_split_percent",
    hierarchy: ["Tax Equity", "Pre-Flip Cash Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_tax_split_percent",
    hierarchy: ["Tax Equity", "Pre-Flip Tax Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "target_flip_tenor",
    hierarchy: ["Tax Equity", "Target Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "actual_flip_tenor",
    hierarchy: ["Tax Equity", "Actual Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "flip_delay",
    hierarchy: ["Tax Equity", "Flip Delay"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "dro_cap",
    hierarchy: ["Tax Equity", "DRO Cap"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "max_dro",
    hierarchy: ["Tax Equity", "Max DRO"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_cap_exceedance",
    hierarchy: ["Tax Equity", "DRO Cap Exceedance"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_term",
    hierarchy: ["Tax Equity", "DRO Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "preferred_return_type",
    hierarchy: ["Tax Equity", "Preferred Return Type"],
  },
  {
    id: "preferred_return_amount",
    hierarchy: ["Tax Equity", "Preferred Return %"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Tax Equity", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Tax Equity", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Tax Equity", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Tax Equity", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_CASH_EQUITY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["cash_equity"]
>[] = [
  {
    id: "type",
    hierarchy: ["Cash Equity", "Type"],
    enum: DEAL_TAX_EQUITY_TYPES,
  },
  {
    id: "solve_for",
    hierarchy: ["Cash Equity", "Solve For"],
    enum: DEAL_TE_SOLVE_FORS,
  },
  {
    id: "capital_contributions",
    hierarchy: ["Cash Equity", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "after_tax_irr_flip_term",
    hierarchy: ["Cash Equity", "After-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Cash Equity", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Cash Equity", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi",
    hierarchy: ["Cash Equity", "ROI"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi_buyout_term",
    hierarchy: ["Cash Equity", "ROI (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_flip_term",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Cash Equity", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_with_tax_credit",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (with Credits)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_floor",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (with Credits) Floor"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_shortfall",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (with Credits) Shortfall"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_cash_split_percent",
    hierarchy: ["Cash Equity", "Pre-Flip Cash Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_tax_split_percent",
    hierarchy: ["Cash Equity", "Pre-Flip Tax Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "target_flip_tenor",
    hierarchy: ["Cash Equity", "Target Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "actual_flip_tenor",
    hierarchy: ["Cash Equity", "Actual Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "flip_delay",
    hierarchy: ["Cash Equity", "Flip Delay"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "dro_cap",
    hierarchy: ["Cash Equity", "DRO Cap"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "max_dro",
    hierarchy: ["Cash Equity", "Max DRO"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_cap_exceedance",
    hierarchy: ["Cash Equity", "DRO Cap Exceedance"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_term",
    hierarchy: ["Cash Equity", "DRO Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "preferred_return_type",
    hierarchy: ["Cash Equity", "Preferred Return Type"],
  },
  {
    id: "preferred_return_amount",
    hierarchy: ["Cash Equity", "Preferred Return %"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Cash Equity", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Cash Equity", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Cash Equity", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Cash Equity", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_TRANSFER_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["transfer"]
>[] = [
  {
    id: "payment_total",
    hierarchy: ["Transfer", "Payment Total"],
    isDollar: true,
  },
  {
    id: "payment_rate",
    hierarchy: ["Transfer", "Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "discount_rate",
    hierarchy: ["Transfer", "Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi",
    hierarchy: ["Transfer", "ROI"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "npv_payment_total",
    hierarchy: ["Transfer", "NPV of Payments"],
    isDollar: true,
  },
  {
    id: "npv_tax_credits",
    hierarchy: ["Transfer", "NPV of Credits"],
    isDollar: true,
  },
  {
    id: "adjusted_payment_rate",
    hierarchy: ["Transfer", "Adjusted Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "adjusted_payment_total",
    hierarchy: ["Transfer", "Adjusted Payment Total"],
    isDollar: true,
  },
];

export const DEAL_DIRECT_PAY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["transfer"]
>[] = [
  {
    id: "payment_total",
    hierarchy: ["Direct Pay", "Payment Total"],
    isDollar: true,
  },
  {
    id: "payment_rate",
    hierarchy: ["Direct Pay", "Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "discount_rate",
    hierarchy: ["Direct Pay", "Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "npv_payment_total",
    hierarchy: ["Direct Pay", "NPV of Payments"],
    isDollar: true,
  },
  {
    id: "npv_tax_credits",
    hierarchy: ["Direct Pay", "NPV of Credits"],
    isDollar: true,
  },
  {
    id: "adjusted_payment_rate",
    hierarchy: ["Direct Pay", "Adjusted Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "adjusted_payment_total",
    hierarchy: ["Direct Pay", "Adjusted Payment Total"],
    isDollar: true,
  },
];
