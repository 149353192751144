import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import useStyles from "./styles";
import BulletIcon from "../logos-and-icons/bullet-icon";
import ThemeContext from "../../utils/context/theme-context";
import { PLAN_ADDITIONAL_FEES, PRODUCT_PLANS } from "../../constants";
import { AppContext } from "../../utils/context/app-context";

interface IProps {
  planToExclude?: string;
}

export default function ProductPlanCards({
  planToExclude,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { darkMode } = React.useContext(ThemeContext);

  const { productUsageDetails } = React.useContext(AppContext);

  const currentPlan = React.useMemo(
    () =>
      PRODUCT_PLANS.find(
        (plan) => plan.code === productUsageDetails?.strawman_plan,
      ),
    [productUsageDetails],
  );

  const filteredCards = PRODUCT_PLANS.filter((plan) => {
    let plansToHide = [planToExclude];
    switch (currentPlan?.code) {
      case "BASE": {
        plansToHide = ["TRIAL"];
        break;
      }
      case "PREMIUM": {
        plansToHide = ["TRIAL", "BASE"];
        break;
      }
      case "PRO": {
        plansToHide = ["TRIAL", "BASE", "PREMIUM"];
        break;
      }
    }
    return !plansToHide.includes(plan.code);
  });

  const goToContactUsPage = () => {
    window.open("https://capezero.com/contact", "_blank");
  };

  return (
    <Box>
      <Box className={styles.classes.container}>
        {filteredCards.map((plan, idx) => {
          const btnDisabled =
            plan.code === "TRIAL" || plan.code === currentPlan?.code;
          return (
            <Paper
              key={idx}
              classes={{ root: styles.classes.card }}
              style={{ backgroundColor: plan.bgColor }}
            >
              <Typography fontSize={24} fontWeight="bold">
                {plan.heading}
              </Typography>
              <Typography fontSize={14}>{plan.subHeading}</Typography>

              <Box className={styles.classes.cardBody}>
                {plan.features.map((feature, idx) => (
                  <Typography
                    key={idx}
                    classes={{ root: styles.classes.cardFeature }}
                  >
                    <CheckIcon fontSize="small" />
                    {feature}
                  </Typography>
                ))}
              </Box>

              <MuiButton
                disabled={btnDisabled}
                classes={{
                  root: btnDisabled
                    ? styles.classes.disabledButton
                    : styles.classes.button,
                }}
                onClick={goToContactUsPage}
              >
                {plan.code === currentPlan?.code
                  ? "current plan"
                  : "contact us"}
              </MuiButton>
            </Paper>
          );
        })}
      </Box>
      <Box className={styles.classes.centeredContent}>
        <Typography fontSize={20} fontWeight="bold">
          Additional Fees
        </Typography>
        <List dense>
          {PLAN_ADDITIONAL_FEES?.map((fee, idx) => (
            <ListItem key={idx}>
              <BulletIcon fillColor={darkMode ? "white" : "black"} />
              <ListItemText primary={fee} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}
