import React from "react";
import Typography from "@mui/material/Typography";

import Modal from "../modal";
import { ButtonType } from "../../interfaces";
import { AppContext } from "../../utils/context/app-context";
import { CONFIRM_DELETE_MODAL_DEFAULT_PROPS } from "../../constants";

export interface IConfirmDeleteModalProps {
  open: boolean;
  title: string;
  loading?: boolean;
  description: string;
  onClose?: () => void;
  onConfirm: () => Promise<void>;
  confirmBtnLabel?: string;
  confirmBtnType?: ButtonType;
}

export default function ConfirmDeleteModal({
  open,
  title,
  loading,
  description,
  onClose,
  onConfirm,
  confirmBtnLabel = "Delete",
  confirmBtnType = "danger",
}: IConfirmDeleteModalProps): JSX.Element {
  const { setConfirmDeleteModalProps } = React.useContext(AppContext);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
      return;
    }
    setConfirmDeleteModalProps(CONFIRM_DELETE_MODAL_DEFAULT_PROPS);
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      loading={loading}
      heading={title}
      onClose={handleOnClose}
      onConfirm={onConfirm}
      confirmBtnType={confirmBtnType}
      confirmBtnLabel={confirmBtnLabel}
    >
      <Typography>{description}</Typography>
    </Modal>
  );
}
