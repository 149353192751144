import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import {
  IDealCase,
  IAddDealCaseForm,
  IUpdateDealCaseForm,
  IDuplicateDealCaseForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const getDealCases = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.case.get, { dealId });
  return await AxiosHelper.get<ServerPaginatedResponse<IDealCase[]>>(url);
};

export const addDealCase = async (dealId: number, form: IAddDealCaseForm) => {
  const url = replaceParams(DEAL_API_ROUTES.case.create, { dealId });
  return await AxiosHelper.post<IAddDealCaseForm, IDealCase>(url, form);
};

export const getDealCaseDetails = async (dealId: number, caseId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.case.getDetails, {
    dealId,
    caseId,
  });
  return await AxiosHelper.get<IDealCase>(url);
};

export const updateDealCase = async (
  dealId: number,
  caseId: number,
  form: IUpdateDealCaseForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.case.update, { dealId, caseId });
  return await AxiosHelper.put<IUpdateDealCaseForm, IDealCase>(url, form);
};

export const duplicateDealCase = async (
  dealId: number,
  caseId: number,
  form: IDuplicateDealCaseForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.case.duplicate, { dealId, caseId });
  return await AxiosHelper.post<IDuplicateDealCaseForm, IDealCase>(url, form);
};

export const deleteDealCase = async (dealId: number, caseId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.case.delete, { dealId, caseId });
  return await AxiosHelper.delete(url);
};
