import React from "react";
import Box from "@mui/material/Box";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import DetailsCard from "../../../../components/details-card";
import ViewWrapper from "../../../../components/view-wrapper";
import { AppContext } from "../../../../utils/context/app-context";
import {
  MARKETS,
  MW_IN_OPERATION,
  ORGANIZATION_TYPE,
  PROJECT_TYPES,
  US_STATES,
} from "../../../../constants";

export default function ConfigurationOrganizationProfileView(): JSX.Element {
  const styles = useStyles();
  const { currentOrganization } = React.useContext(AppContext);
  const currentOrgLogo = useOrganization()?.organization?.imageUrl;

  return (
    <ViewWrapper error={false} loading={false}>
      <Box className={styles.classes.grid}>
        <DetailsCard
          heading="General"
          sections={[
            {
              fields: [
                {
                  label: "Organization Type",
                  value: {
                    text:
                      ORGANIZATION_TYPE[
                        currentOrganization?.type as keyof typeof ORGANIZATION_TYPE
                      ] ?? "N/A",
                  },
                },
                {
                  label: "Website",
                  value: {
                    text: currentOrganization?.website_url ?? "N/A",
                  },
                },
                {
                  label: "Logo",
                  value: {
                    text: (
                      <img src={currentOrgLogo} alt="" height={30} width={30} />
                    ),
                  },
                },
              ],
            },
          ]}
        />
        <DetailsCard
          heading="Details"
          sections={[
            {
              fields: [
                {
                  label: "Project Types",
                  value: {
                    text:
                      currentOrganization?.project_types
                        ?.map(
                          (t) => PROJECT_TYPES[t as keyof typeof PROJECT_TYPES],
                        )
                        .join(", ") || "N/A",
                  },
                },
                {
                  label: "MW in operations",
                  value: {
                    text:
                      MW_IN_OPERATION[
                        currentOrganization?.mw_in_operation as keyof typeof MW_IN_OPERATION
                      ] ?? "N/A",
                  },
                },
                {
                  label: "Markets",
                  value: {
                    text:
                      currentOrganization?.markets
                        ?.map((m) => MARKETS[m as keyof typeof MARKETS])
                        ?.join(", ") || "N/A",
                  },
                },
                {
                  label: "States",
                  value: {
                    text:
                      currentOrganization?.states
                        ?.map((s) => US_STATES[s as keyof typeof US_STATES])
                        .join(", ") || "N/A",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </ViewWrapper>
  );
}
