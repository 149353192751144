import { ITableColumn } from "../../interfaces";

export const PROJECT_COD_OPTIONS = new Array(11)
  .fill(2019)
  .map((year, idx) => ({
    label: String(year + idx),
    value: String(year + idx),
  }));

export const PROJECT_ESCALATOR_BASE_YEAR_OPTIONS = new Array(100)
  .fill(2000)
  .map((year, idx) => ({
    label: String(year + idx),
    value: String(year + idx),
  }));

export const PROJECTS_TABLE_COLUMNS: ITableColumn[] = [
  { id: "name", label: "Project Name", minWidth: 120, align: "left" },
  { id: "sponsor_name", label: "Sponsor Name", minWidth: 150, align: "left" },
  { id: "type", label: "Type", minWidth: 100, align: "left" },
  { id: "state", label: "State", minWidth: 50, align: "left" },
  { id: "capacity_ac", label: "Capacity (AC)", minWidth: 50, align: "left" },
  { id: "full_stage", label: "Stage", minWidth: 100, align: "left" },
  { id: "cod", label: "COD", minWidth: 75, align: "left" },
  {
    id: "tax_credit_type",
    label: "Tax Credit Type",
    minWidth: 75,
    align: "left",
  },
  { id: "modified", label: "Last Modified", minWidth: 100, align: "left" },
];
