import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import SignInPage from "./pages/sign-in";
import MyClerkProvider from "./components/clerk-provider";
import AppContextProvider from "./components/app-context-provider";
import AxiosProvider from "./components/axios-provider";
import ThemeContextProvider from "./components/theme-context-provider";
import MyToastProvider from "./components/toast-provider";
import { validateEnvironmentVariables } from "./utils/helpers";
import { initializeSentry } from "./utils/error-handling";
import { privateRoutes } from "./utils/router/routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

validateEnvironmentVariables();
initializeSentry();

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY!);

root.render(
  <>
    <ThemeContextProvider>
      <MyClerkProvider>
        <AxiosProvider>
          <CssBaseline />
          <SignedIn>
            <BrowserRouter>
              <AppContextProvider>
                <Routes>
                  {privateRoutes.map((route, idx) => {
                    const Component = route.element;
                    return (
                      <Route
                        key={idx}
                        path={route.path}
                        element={<Component />}
                      />
                    );
                  })}
                </Routes>
              </AppContextProvider>
            </BrowserRouter>
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn redirectUrl={"/sign-in"} />
          </SignedOut>
          <BrowserRouter>
            <Routes>
              <Route path="/sign-in" element={<SignInPage />} />
            </Routes>
          </BrowserRouter>
        </AxiosProvider>
      </MyClerkProvider>
      <MyToastProvider />
    </ThemeContextProvider>
  </>,
);
