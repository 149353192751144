import { REPORT_API_ROUTES } from "../../constants";
import { IPipelineReportByYear } from "../../interfaces";
import { AxiosHelper } from "../../utils/helpers";

export const getPipelineReportByYear = async (
  projectStage: "DEV" | "CON" | "OP" | "",
) => {
  return await AxiosHelper.get<IPipelineReportByYear>(
    REPORT_API_ROUTES.pipeline.getByYear,
    {
      params: {
        project_stage: projectStage,
      },
    },
  );
};
