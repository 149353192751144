import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SyncIcon from "@mui/icons-material/Sync";
import { SelectChangeEvent } from "@mui/material";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import SelectInput from "../select-input";
import CaseActionButtons from "../case-action-buttons";
import ThemeContext from "../../utils/context/theme-context";
import DownloadDealReportsButton from "../download-reports-button";
import { externalDarkTheme, externalLightTheme } from "../../utils/theme";
import { AppContext } from "../../utils/context/app-context";
import { getDealDetails } from "../../apis/deal/base";
import { getDealDebt } from "../../apis/deal/debt";
import { useAPI } from "../../utils/hooks";
import { ISelectOption } from "../../interfaces";
import {
  cn,
  getLayoutTabs,
  navigateAndScroll,
  trimString,
} from "../../utils/helpers";
import {
  deleteDealCase,
  duplicateDealCase,
  getDealCaseDetails,
  getDealCases,
  updateDealCase,
} from "../../apis/deal/case";
import {
  DEAL_CASE_TYPE,
  SIDEBAR_NAVIGATION,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON,
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
} from "../../constants";
import {
  downloadABCInputs,
  downloadDealReport,
  downloadDealReportSet,
  downloadUserDealReport,
} from "../../apis/report/deal";

interface IProps {
  children: JSX.Element;
}

export default function DealPagesLayout({ children }: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { caseId, dealId, caseDealId } = useParams();

  const { darkMode } = React.useContext(ThemeContext);
  const {
    casesOfDeal,
    currentDeal,
    setCurrentDeal,
    selectedDealOutputPageTab,
    setSelectedDealOutputPageTab,
    setCasesOfDeal,
    setCurrentDealDebt,
    setDealOutputLoaded,
    contentLoaded,
  } = React.useContext(AppContext);

  const hasStickyTabs: boolean = React.useMemo(() => {
    const pagesWithStickyTabs = ["summary", "charts", "splits"];
    const containsTabs = pagesWithStickyTabs.some((page) =>
      pathname.includes(page),
    );
    return containsTabs;
  }, [pathname]);

  const styles = useStyles({ contentLoaded, hasStickyTabs });

  const hideCaseSelector = DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON.some((path) =>
    pathname.includes(path),
  );

  React.useEffect(() => {
    if (dealId && dealId !== selectedDealOutputPageTab.dealId) {
      setSelectedDealOutputPageTab({
        ...DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
        dealId,
      });
    }

    if (currentDeal?.id !== Number(caseDealId)) {
      const dealIdToUse = hideCaseSelector ? dealId : caseDealId;

      getDealCasesCallAPI(Number(dealId)).then((res) => {
        res && setCasesOfDeal(res.results);
      });
      getDealDetailsCallAPI(Number(dealIdToUse)).then((res) => {
        res && setCurrentDeal(res);
      });
      getDealDebtCallAPI(Number(dealIdToUse)).then((res) => {
        res && setCurrentDealDebt(res[0]);
      });
    }

    if (!pathname.includes("/output/")) {
      setDealOutputLoaded(false);
    }

    if (pathname.includes("/case/")) {
      getDealCaseDetailsCallAPI(dealId, caseId);
    }
  }, [dealId, caseDealId, caseId]);

  const { callAPI: getDealDetailsCallAPI } = useAPI((dealId: number) =>
    getDealDetails(dealId),
  );

  const { callAPI: getDealDebtCallAPI } = useAPI((dealId: number) =>
    getDealDebt(dealId),
  );

  const { callAPI: getDealCasesCallAPI } = useAPI((dealId: number) =>
    getDealCases(dealId),
  );

  const { callAPI: getDealCaseDetailsCallAPI, response: caseDetails } = useAPI(
    (dealId: number, caseId: number) => getDealCaseDetails(dealId, caseId),
    { initialLoading: true },
  );

  const onProjectChipClick = (projectId: number) => {
    window.open(`/project/${projectId}/general`, "_blank");
  };

  const onCaseChange = async (e: SelectChangeEvent<unknown>) => {
    const caseDealId = e.target.value as string;
    const splitPath = pathname.split("/");
    splitPath[4] = caseDealId;
    // call api to refresh tabs when in sizing/output page
    if (pathname.includes("/output/") || pathname.includes("/sizing/")) {
      const dealDetails = await getDealDetailsCallAPI(Number(e.target.value));
      const dealTermDebt = await getDealDebtCallAPI(Number(e.target.value));
      const t = pathname.split("/")[5];
      if (dealDetails && dealTermDebt) {
        const links =
          SIDEBAR_NAVIGATION.find((i) => i.label === "Deals")?.subLinks?.find(
            (i) => i.label === (t === "sizing" ? "Sizing" : "Output"),
          )?.subLinks || [];

        const tabUrls = getLayoutTabs({
          module: "Deals",
          currentDeal: dealDetails,
          currentDealDebt: dealTermDebt[0],
          links,
        }).map((t) =>
          t.path
            .replace(":dealId", String(dealId))
            .replace(":caseDealId", String(caseDealId)),
        );

        if (t === "output") {
          splitPath.pop();
        }
        if (!tabUrls.includes(splitPath.join("/"))) {
          navigate(tabUrls[0]);
          return;
        }
      }
    }
    navigate(splitPath.join("/"));
  };

  const handleCaseSwitch = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    const selectedCase = casesOfDeal.find(
      (c) => c.id === Number(event.target.value),
    );
    selectedCase && navigate(`/deal/${dealId}/case/${selectedCase.id}`);
  };

  const goToCase = () => {
    const caseId = casesOfDeal.find(
      (c) => c.child_deal.id === Number(caseDealId),
    )?.id;

    if (caseId) {
      navigate(`/deal/${dealId}/case/${caseId}`);
    }
  };

  const goToOutputPage = (caseDealId: number) => {
    navigate(
      `/deal/${dealId}/case-deal/${caseDealId}/output/partnership/summary`,
    );
  };

  const casesOptions: ISelectOption[] = React.useMemo(() => {
    if (currentDeal && casesOfDeal) {
      const cases = casesOfDeal.map((c) => ({
        label: c.name,
        value: String(c.child_deal.id),
      }));
      return [{ label: "Base Case", value: String(dealId) }, ...cases];
    }
    return [];
  }, [currentDeal, casesOfDeal]);

  const caseSwitchOptions: ISelectOption[] = React.useMemo(() => {
    return casesOfDeal.length
      ? casesOfDeal.map((c) => ({ label: c.name, value: String(c.id) }))
      : [];
  }, [casesOfDeal]);

  const activeCase = React.useMemo(() => {
    const caseIdToUse = caseDealId || caseId;
    return casesOfDeal.find((c) => c.id === Number(caseIdToUse));
  }, [caseId, casesOfDeal]);

  const handleNavigate = async (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  const chipElements = React.useMemo(() => {
    if (pathname.includes("/case/")) {
      if (activeCase) {
        const elements = [
          DEAL_CASE_TYPE[activeCase.type],
          DEAL_STRUCTURE_TYPE[activeCase.child_deal.structure],
          DEAL_TAX_CREDIT_STRUCTURE_TYPE[
            activeCase.child_deal.tax_credit_structure
          ],
        ].map((l, i) => <Chip key={i} label={l} />);

        if (activeCase.is_synced_with_base_case) {
          elements.push(
            <Tooltip
              key={3}
              title="Any unmodified data in this Case syncs automatically with the Base Case"
            >
              <Chip icon={<SyncIcon />} label="Synced" />
            </Tooltip>,
          );
        }

        elements.push(
          <Chip
            key={4}
            variant="outlined"
            label="View Output"
            onClick={() => goToOutputPage(activeCase.child_deal.id)}
            icon={<ArrowForwardIcon color="inherit" fontSize="small" />}
            classes={{ root: "!text-secondary !border-secondary" }}
          />,
        );

        return elements;
      }
    }
    if (currentDeal && (dealId === caseDealId || !caseDealId)) {
      const elements = [
        DEAL_STRUCTURE_TYPE[currentDeal.structure],
        DEAL_TAX_CREDIT_STRUCTURE_TYPE[currentDeal.tax_credit_structure],
      ].map((l, i) => <Chip key={i} label={l} />);

      if (currentDeal.projects.length > 5) {
        elements.push(
          <Chip
            key={4}
            label="Portfolio"
            variant="outlined"
            onClick={() =>
              handleNavigate(
                `/deal/${currentDeal?.id}/case-deal/${currentDeal?.id}/general`,
                "deal-projects",
              )
            }
          />,
        );
      } else {
        currentDeal.projects.forEach((p, idx) => {
          elements.push(
            <Chip
              key={idx + 5}
              label={p.name}
              variant="outlined"
              icon={<OpenInNewIcon fontSize="small" />}
              onClick={() => {
                onProjectChipClick(p.id);
              }}
            />,
          );
        });
      }
      return elements;
    } else {
      const caseDetails = casesOfDeal.find(
        (c) => c.child_deal.id === Number(caseDealId),
      );

      if (caseDetails) {
        const elements = [
          DEAL_CASE_TYPE[caseDetails.type],
          DEAL_STRUCTURE_TYPE[caseDetails.child_deal.structure],
          DEAL_TAX_CREDIT_STRUCTURE_TYPE[
            caseDetails.child_deal.tax_credit_structure
          ],
        ].map((l, i) => <Chip key={i} label={l} />);

        elements.push(
          <Chip
            key={3}
            variant="outlined"
            label="View Case Details"
            onClick={goToCase}
            icon={<ArrowForwardIcon color="inherit" fontSize="small" />}
            classes={{ root: "!text-secondary !border-secondary" }}
          />,
        );

        return elements;
      }

      return [];
    }
  }, [currentDeal, casesOfDeal]);

  const backButtonDetails = React.useMemo(() => {
    let name = "Deals";
    let path = "/deal-list";

    if (pathname.includes("/case/")) {
      name = "Cases";
      path = `/deal/${dealId}/cases`;
    }
    if (pathname.includes("/analysis/")) {
      name = "Analysis";
      path = `/deal/${dealId}/analysis`;
    }

    return {
      name,
      path,
    };
  }, [pathname]);

  const selectedTheme = React.useMemo(
    () => (darkMode ? externalDarkTheme : externalLightTheme),
    [darkMode],
  );

  return (
    <Layout title={currentDeal?.name}>
      <>
        {currentDeal && (
          <Box className={cn("sticky top-68 p-4 pb-0 z-6")}>
            <Link
              to={backButtonDetails.path}
              className={cn(
                `flex items-center text-${selectedTheme.color.text} !no-underline w-fit hover:text-secondary`,
              )}
            >
              <ArrowBackIcon />
              <Typography>{`Back to ${backButtonDetails.name} List`}</Typography>
            </Link>
            <Box className={cn("flex justify-between items-end mb-2")}>
              <Box className={cn("flex flex-col")}>
                <Box className={cn("mt-2")}>
                  <Typography variant="h5" className={cn("text-nowrap !mr-6")}>
                    {trimString(currentDeal?.name, 45)}
                  </Typography>
                </Box>

                <Box className={cn("flex flex-wrap gap-2 my-2")}>
                  {!hideCaseSelector && (
                    <SelectInput
                      label="Case"
                      items={casesOptions}
                      selected={caseDealId ?? ""}
                      onChange={onCaseChange}
                      className={cn("w-64 h-9 mb-[-12px]")}
                      fullWidth={false}
                    />
                  )}
                  {pathname.includes("/case/") && caseDetails && (
                    <SelectInput
                      variant="outlined"
                      label="Case"
                      onChange={handleCaseSwitch}
                      selected={String(caseId)}
                      items={caseSwitchOptions}
                      className={cn("w-64 h-9 mb-[-12px]")}
                      fullWidth={false}
                    />
                  )}
                  {chipElements}
                </Box>
              </Box>
              {pathname.includes("/output/") && (
                <DownloadDealReportsButton
                  downloadABCInputs={downloadABCInputs}
                  downloadDealReport={downloadDealReport}
                  downloadDealReportSet={downloadDealReportSet}
                  downloadUserDealReport={downloadUserDealReport}
                />
              )}

              {pathname.includes("/case/") && caseDetails && (
                <CaseActionButtons
                  deleteDealCase={deleteDealCase}
                  duplicateDealCase={duplicateDealCase}
                  getDealCaseDetails={getDealCaseDetails}
                  updateDealCase={updateDealCase}
                />
              )}
            </Box>
            <Divider />
          </Box>
        )}

        <Box className={styles.classes.children}>{children}</Box>
      </>
    </Layout>
  );
}
