import DealPagesLayout from "../../../components/deal-pages-layout";
import DealCaseDetailsView from "../../../views/deal/cases/details/index";
import { getCurves } from "../../../apis/configuration/base";
import { getProjectDetailsFat } from "../../../apis/project/base";
import { updateDealTiming } from "../../../apis/deal/timing";
import { editProjectTiming } from "../../../apis/project/timing";
import { updateProjectProduction } from "../../../apis/project/production";
import { updateProjectTaxCredit } from "../../../apis/project/tax-credit";
import { updateDealTaxEquity } from "../../../apis/deal/tax-equity";
import { updateProjectScheduleOverride } from "../../../apis/project/schedule-overrides";
import { updateDealSponsorEquity } from "../../../apis/deal/sponsor-equity";
import { updateDealTaxCreditTransferDetails } from "../../../apis/deal/tax-credit";
import { updateDealProjectDepreciation } from "../../../apis/deal/depreciation";
import { getDealCaseDetails } from "../../../apis/deal/case";
import {
  addConstructionLoan,
  addGeneralAssumption,
  addInvestorLoan,
  addTransferLoan,
  deleteConstructionLoan,
  deleteInvestorLoan,
  deleteTransferLoan,
  getGeneralAssumption,
  updateConstructionLoan,
  updateGeneralAssumption,
  updateInvestorLoan,
  updateTransferLoan,
} from "../../../apis/deal/construction-debt";
import {
  addProjectOtherFlow,
  deleteProjectOtherFlow,
  editProjectOtherFlow,
} from "../../../apis/project/other-flows";
import {
  addDealDebt,
  deleteDealDebt,
  updateDealDebt,
} from "../../../apis/deal/debt";
import {
  addProjectExpenses,
  deleteProjectExpense,
  updateProjectExpense,
} from "../../../apis/project/expenses";
import {
  addMerchantRevenue,
  addProjectRevenueContract,
  deleteProjectMerchantRevenueContract,
  deleteProjectRevenueContract,
  editProjectRevenueContract,
  updateMerchantRevenue,
} from "../../../apis/project/revenue-contracts";
import {
  getDealDetailsFat,
  updateDealTransferConstraints,
} from "../../../apis/deal/base";
import {
  getDealOtherSizing,
  updateDealOtherSizing,
} from "../../../apis/deal/other";
import {
  addDealFee,
  deleteDealFee,
  updateDealFee,
} from "../../../apis/deal/fee";
import {
  addProjectCreditSupport,
  createProjectCostItem,
  deleteProjectCreditSupport,
  editProjectCreditSupport,
  updateProjectCosts,
} from "../../../apis/project/cost";

export default function CaseDetailsPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealCaseDetailsView
        getDealCaseDetails={getDealCaseDetails}
        getDealDetailsFat={getDealDetailsFat}
        getProjectDetailsFat={getProjectDetailsFat}
        addDealDebt={addDealDebt}
        deleteDealDebt={deleteDealDebt}
        updateDealDebt={updateDealDebt}
        updateDealTiming={updateDealTiming}
        addProjectRevenueContract={addProjectRevenueContract}
        editProjectRevenueContract={editProjectRevenueContract}
        deleteProjectRevenueContract={deleteProjectRevenueContract}
        addMerchantRevenue={addMerchantRevenue}
        updateMerchantRevenue={updateMerchantRevenue}
        deleteProjectMerchantRevenueContract={
          deleteProjectMerchantRevenueContract
        }
        addProjectExpense={addProjectExpenses}
        updateProjectExpense={updateProjectExpense}
        deleteExpense={deleteProjectExpense}
        editProjectTiming={editProjectTiming}
        updateProjectProduction={updateProjectProduction}
        updateProjectTaxCredit={updateProjectTaxCredit}
        updateDealTaxEquity={updateDealTaxEquity}
        editDealDepreciation={updateDealProjectDepreciation}
        updateDealTaxCreditTransferDetails={updateDealTaxCreditTransferDetails}
        updateProjectEBITDA={updateProjectScheduleOverride}
        getDealOtherSizing={getDealOtherSizing}
        updateDealOtherSizing={updateDealOtherSizing}
        updateDeal={updateDealTransferConstraints}
        updateDealSponsorEquity={updateDealSponsorEquity}
        getCurves={getCurves}
        addProjectOtherFlow={addProjectOtherFlow}
        editProjectOtherFlow={editProjectOtherFlow}
        deleteProjectOtherFlow={deleteProjectOtherFlow}
        addDealFee={addDealFee}
        updateDealFee={updateDealFee}
        deleteDealFee={deleteDealFee}
        updateProjectCosts={updateProjectCosts}
        deleteProjectCreditSupport={deleteProjectCreditSupport}
        addProjectCreditSupport={addProjectCreditSupport}
        editProjectCreditSupport={editProjectCreditSupport}
        createProjectCostItem={createProjectCostItem}
        addGeneralAssumption={addGeneralAssumption}
        getGeneralAssumption={getGeneralAssumption}
        updateGeneralAssumption={updateGeneralAssumption}
        addConstructionLoan={addConstructionLoan}
        addTransferLoan={addTransferLoan}
        deleteConstructionLoan={deleteConstructionLoan}
        deleteTransferLoan={deleteTransferLoan}
        updateConstructionLoan={updateConstructionLoan}
        updateTransferLoan={updateTransferLoan}
        addInvestorLoan={addInvestorLoan}
        updateInvestorLoan={updateInvestorLoan}
        deleteInvestorLoan={deleteInvestorLoan}
      />
    </DealPagesLayout>
  );
}
