import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import Charts from "./charts";
import CashChart from "./cash-charts";
import useStyles from "./styles";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import { useAPI } from "../../../../../utils/hooks";
import { AppContext } from "../../../../../utils/context/app-context";
import { useAxios } from "../../../../../components/axios-provider";
import { getDealSponsorCashEquityChartUrl } from "../../../../../utils/helpers";
import {
  ISponsorCashEquityChart,
  ISponsorCashFlowChart,
} from "../../../../../interfaces";

interface IProps {
  getDealSponsorCashEquityChart: (
    dealId: number,
    is_percent: string,
  ) => Promise<ISponsorCashEquityChart | null>;
  getDealSponsorEquityCashChart: (
    dealId: number,
    is_percent: string,
  ) => Promise<ISponsorCashFlowChart | null>;
}

export default function DealOutputSponsorCashChartsView({
  getDealSponsorCashEquityChart,
  getDealSponsorEquityCashChart,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { currentDeal } = React.useContext(AppContext);

  const [sponsorEquityChart, setSponsorCashEquityChartView] =
    React.useState<ISponsorCashEquityChart>();
  const [sponsorEquityChartPercent, setSponsorCashEquityChartViewPercent] =
    React.useState<ISponsorCashEquityChart>();

  const [sponsorCashFlowChart, setSponsorCashFlowChartView] =
    React.useState<ISponsorCashFlowChart>();
  const [sponsorCashFlowChartPercent, setSponsorCashFlowChartViewPercent] =
    React.useState<ISponsorCashFlowChart>();

  const { setDealOutputLoaded } = React.useContext(AppContext);

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealSponsorCashEquityChartCallAPI(Number(caseDealId), "no").then(
        (response) => {
          if (response) {
            setSponsorCashEquityChartView(response);
          }
        },
      );
      getDealSponsorCashEquityChartCallAPI(Number(caseDealId), "yes").then(
        (response) => {
          if (response) {
            setSponsorCashEquityChartViewPercent(response);
          }
        },
      );

      getDealSponsorEquityCashChartCallAPI(Number(caseDealId), "no").then(
        (response) => {
          if (response) {
            setSponsorCashFlowChartView(response);
          }
        },
      );
      getDealSponsorEquityCashChartCallAPI(Number(caseDealId), "yes").then(
        (response) => {
          if (response) {
            setSponsorCashFlowChartViewPercent(response);
          }
        },
      );
    }
  }, [caseDealId]);

  const {
    callAPI: getDealSponsorCashEquityChartCallAPI,
    errored: getDealSponsorCashEquityChartFailed,
    loading: loadingDealSponsorCashEquityChart,
    errorObj: getDealSponsorCashEquityChartErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealId: number, is_percent: string) =>
      getDealSponsorCashEquityChart(dealId, is_percent),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingDealSponsorCashEquityChart &&
      setDealOutputLoaded(!getDealSponsorCashEquityChartFailed);
  }, [getDealSponsorCashEquityChartFailed, loadingDealSponsorCashEquityChart]);

  const { callAPI: getDealSponsorEquityCashChartCallAPI } = useAPI(
    (dealId: number, is_percent: string) =>
      getDealSponsorEquityCashChart(dealId, is_percent),
  );

  return (
    <ViewWrapper
      loading={loadingDealSponsorCashEquityChart}
      error={getDealSponsorCashEquityChartFailed}
      errorHeading={getDealSponsorCashEquityChartErrorObj?.heading}
      errorDescription={getDealSponsorCashEquityChartErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealSponsorCashEquityChartUrl(Number(caseDealId), "yes")
        ]
      }
    >
      <Box>
        {sponsorCashFlowChart && sponsorCashFlowChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper title="Sponsor Equity Cash Flow">
              <CashChart
                cashReportChart={sponsorCashFlowChart}
                showChart={"cashReportChart"}
                stack
                isPercentage={false}
                label="Sponsor Equity"
                isDirectPay={currentDeal?.tax_credit_structure === "DP"}
              />
            </ChartWrapper>
            <ChartWrapper title="Sponsor Equity Cash Flow">
              <CashChart
                cashReportChart={sponsorCashFlowChartPercent}
                showChart={"cashReportChart"}
                stack
                isPercentage
                label="Sponsor Equity"
                isDirectPay={currentDeal?.tax_credit_structure === "DP"}
              />
            </ChartWrapper>
          </Box>
        )}

        {sponsorEquityChart && sponsorEquityChartPercent && (
          <Box className={styles.classes.projectChartsContainer}>
            <ChartWrapper title="Sponsor Equity Taxable Income">
              <Charts
                cashReportChart={sponsorEquityChart}
                showChart={"cashReportChart"}
                stack
                isPercentage={false}
                label="Sponsor Equity"
              />
            </ChartWrapper>
            <ChartWrapper title="Sponsor Equity Taxable Income">
              <Charts
                cashReportChart={sponsorEquityChartPercent}
                showChart={"cashReportChart"}
                stack
                isPercentage
                label="Sponsor Equity"
              />
            </ChartWrapper>
          </Box>
        )}
      </Box>
    </ViewWrapper>
  );
}
