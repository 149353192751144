import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Protect } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import ProjectTaxCreditFormModal from "../../../../components/project-tax-credit-form-modal";
import { useAPI, useLogs, useDrawer } from "../../../../utils/hooks";
import { AppContext } from "../../../../utils/context/app-context";
import { numberToUSD } from "../../../../utils/helpers";
import DetailsCard, {
  IDetailsCardSection,
} from "../../../../components/details-card";
import {
  PROJECT_TAX_CREDIT_TYPE,
  UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
  USER_PERMISSIONS,
} from "../../../../constants";
import {
  ITableColumn,
  IProjectDetails,
  IProjectTaxCredit,
  IUpdateProjectTaxCreditForm,
  IUpdateProjectTaxCreditFormErrors,
  ILogsConfiguration,
} from "../../../../interfaces";

export const PTCS_TABLE_COLUMNS: ITableColumn[] = [
  { id: "dates", label: "Date", minWidth: 80, align: "left" },
  { id: "ptcs", label: "PTC", minWidth: 80, align: "left" },
];

interface IProps {
  getProjectTaxCredit: (projectId: number) => Promise<IProjectTaxCredit[]>;
  updateProjectTaxCredit: (
    projectId: number,
    taxCreditId: number,
    form: IUpdateProjectTaxCreditForm,
  ) => Promise<IProjectTaxCredit>;
}

export default function ProjectTaxCreditView({
  getProjectTaxCredit,
  updateProjectTaxCredit,
}: IProps): JSX.Element {
  const styles = useStyles();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();
  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const { projectId } = useParams();

  const { currentProject, setCurrentProject } = React.useContext(AppContext);

  const [updateTaxCreditModalOpen, setUpdateTaxCreditModalOpen] =
    React.useState<boolean>(false);
  const [taxCredit, setTaxCredit] = React.useState<IProjectTaxCredit>();
  const [form, setForm] = React.useState<IUpdateProjectTaxCreditForm>(
    UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getProjectTaxCreditCallAPI().then((response) => {
      response && setTaxCredit(response[0]);
    });
  }, [projectId]);

  const {
    callAPI: getProjectTaxCreditCallAPI,
    errored: getTaxCreditFailed,
    loading: loadingTaxCredit,
  } = useAPI(() => getProjectTaxCredit(Number(projectId)), {
    initialLoading: true,
  });

  const {
    callAPI: updateProjectTaxCreditCallAPI,
    fieldErrors: updateProjectTaxCreditFormErrors,
    setFieldErrors: setUpdateProjectTaxCreditFormErrors,
    loading: updateProjectTaxCreditLoading,
  } = useAPI<IProjectTaxCredit, IUpdateProjectTaxCreditFormErrors>(
    (
      projectId: number,
      taxCreditId: number,
      form: IUpdateProjectTaxCreditForm,
    ) => updateProjectTaxCredit(projectId, taxCreditId, form),
  );

  const handleOnUpdateProjectTaxCredit = async (
    form: IUpdateProjectTaxCreditForm,
  ) => {
    const updatedTaxCredit = await updateProjectTaxCreditCallAPI(
      Number(projectId),
      Number(taxCredit?.id),
      form,
    );
    if (updatedTaxCredit) {
      setTaxCredit(updatedTaxCredit);
      setCurrentProject(
        (project) =>
          ({
            ...project,
            tax_credit_type: updatedTaxCredit.credit_type,
          }) as IProjectDetails,
      );
    }
    return updatedTaxCredit;
  };

  const visibleData: IDetailsCardSection[] = React.useMemo(() => {
    if (taxCredit) {
      const defaultData: IDetailsCardSection[] = [
        {
          heading: "Key Selections",
          fields: [
            {
              label: "Type",
              value: { text: PROJECT_TAX_CREDIT_TYPE[taxCredit.credit_type] },
            },
            {
              label: "Placed in Service Date",
              value: { text: taxCredit.placed_in_service_date },
            },
            ...(taxCredit.credit_type !== "NONE"
              ? [
                  {
                    label: "PWA Requirements Met",
                    value: {
                      text: taxCredit.pwa_requirements_met ? "Yes" : "No",
                    },
                  },
                  {
                    label: "PTC Inflation", // logic to hide it for None/ITC is below
                    value: {
                      text: `${taxCredit.ptc_inflation?.toString() || ""}%`,
                    },
                  },
                ]
              : []),
          ],
        },

        ...(taxCredit.credit_type !== "NONE"
          ? [
              {
                heading: "Adders",
                fields: [
                  {
                    label: "Domestic Content",
                    value: {
                      text: taxCredit.is_ira_domestic_content_adder
                        ? "Yes"
                        : "No",
                    },
                  },
                  {
                    label: "Energy Community",
                    value: {
                      text: taxCredit.is_ira_energy_community_adder
                        ? "Yes"
                        : "No",
                    },
                  },
                  ...(taxCredit.credit_type === "ITC" &&
                  currentProject?.type !== "BAST"
                    ? [
                        {
                          label: "Environmental Justice (EJ)",
                          value: {
                            text: taxCredit.is_ira_environmental_justice_adder
                              ? "Yes"
                              : "No",
                          },
                        },
                        {
                          label: "Special Qualified EJ",
                          value: {
                            text: taxCredit.is_ira_special_qualfied_ej_adder
                              ? "Yes"
                              : "No",
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(taxCredit.credit_type !== "NONE"
          ? [
              {
                heading: "Amounts",
                fields: [
                  ...(taxCredit.credit_type === "ITC"
                    ? [
                        {
                          label: "ITC",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_base) ||
                              " ",
                          },
                        },
                        {
                          label: "ITC Adder",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_bonus) ||
                              " ",
                          },
                        },
                        {
                          label: "ITC Total",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_total) ||
                              " ",
                          },
                        },
                      ]
                    : [
                        {
                          label: "PTC",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_base) ||
                              " ",
                          },
                        },
                        {
                          label: "PTC Adder",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_bonus) ||
                              " ",
                          },
                        },
                        {
                          label: "PTC Total",
                          value: {
                            text:
                              numberToUSD.format(taxCredit.tax_credits_total) ||
                              " ",
                          },
                        },
                      ]),
                ],
              },
            ]
          : []),
      ];
      switch (taxCredit.credit_type) {
        case "PTC": {
          return defaultData;
        }
        case "ITC": {
          return defaultData.map((d) => {
            const { fields } = d;
            const updatedFields = fields.filter(
              (f) => f.label !== "PTC Inflation",
            );
            return { ...d, fields: updatedFields };
          });
        }
        case "NONE": {
          return defaultData.filter((d) => {
            const { fields } = d;
            const updatedFields = fields.filter((f) => f.label !== "Type");
            return { ...d, fields: updatedFields };
          });
        }
      }
    }
    return [];
  }, [taxCredit]);

  const handleOpenEditProjectTaxCreditModal = async () => {
    if (taxCredit) {
      const { created, created_by, id, modified, project, ...formDetails } =
        taxCredit;
      setForm({ ...formDetails });
      setUpdateTaxCreditModalOpen(true);
    }
  };

  const handleCloseUpdateTaxCreditModal = () => {
    setUpdateTaxCreditModalOpen(false);
  };

  const taxCreditLogConfiguration: ILogsConfiguration = {
    id: Number(taxCredit?.id),
    type: "projecttaxcredit",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      taxCreditLogConfiguration.type,
      taxCreditLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingTaxCredit} error={getTaxCreditFailed}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box>
              {taxCredit && (
                <Box className={styles.classes.content}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    gap={2}
                    marginBottom={2}
                  >
                    <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                      <IconButton onClick={handleOnOpenLogs}>
                        <LogsIcon />
                      </IconButton>
                      <Button
                        canOpenUpgrade
                        startIcon={<EditIcon />}
                        label="Edit"
                        btnType="primary"
                        onClick={handleOpenEditProjectTaxCreditModal}
                      />
                    </Protect>
                  </Grid>

                  <DetailsCard
                    autoHeight
                    heading="Tax Credits"
                    sections={visibleData}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          {taxCredit?.credit_type === "PTC" && (
            <Grid item xs={12} sm={6}>
              <Box className={styles.classes.content} marginBottom={1}>
                <Typography fontWeight={600} marginBottom={1}>
                  PTC Generated ($)
                </Typography>
                <Paper
                  sx={{
                    width: { xs: "100%", sm: "80%", md: "60%" },
                    overflow: "hidden",
                  }}
                >
                  <TableContainer
                    classes={{ root: styles.classes.tableContainer }}
                  >
                    <Table stickyHeader>
                      <TableHead className={styles.classes.tableHeader}>
                        <TableRow>
                          {PTCS_TABLE_COLUMNS.map((column, idx) => {
                            return (
                              <TableCell
                                key={idx}
                                align={column.align as "left"}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Total
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            {numberToUSD.format(
                              taxCredit?.tax_credits_total || 0,
                            )}
                          </TableCell>
                        </TableRow>
                        {(taxCredit?.dates || []).map((date, idx) => {
                          return (
                            <TableRow hover key={idx} tabIndex={-1}>
                              <TableCell align="left">{date}</TableCell>
                              <TableCell align="left">
                                {numberToUSD.format(taxCredit?.ptcs[idx])}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            {numberToUSD.format(
                              taxCredit?.tax_credits_total || 0,
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Grid>
          )}
        </Grid>
      </ViewWrapper>

      <ProjectTaxCreditFormModal
        open={updateTaxCreditModalOpen}
        headerLabel="Edit Tax Credit"
        loading={updateProjectTaxCreditLoading}
        formErrors={updateProjectTaxCreditFormErrors}
        setFormErrors={setUpdateProjectTaxCreditFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateTaxCreditModal}
        onConfirm={handleOnUpdateProjectTaxCredit}
        projectType={currentProject?.type}
      />
      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={taxCreditLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={taxCreditLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
