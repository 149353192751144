import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";

import useStyles from "./styles";
import { IMenu, IMenuItem } from "../../interfaces";
import { AppContext } from "../../utils/context/app-context";

interface IProps {
  uniqueId: number;
  items: IMenuItem[];
  canOpenUpgrade?: boolean;
  icon?: JSX.Element;
}

export default function PopoverMenu({
  uniqueId,
  items,
  canOpenUpgrade,
  icon,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { productUsageDetails, setShowUsageLimitHitModal } =
    React.useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<null | IMenu>(null);

  const onClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    event.stopPropagation();
    setAnchorEl({ id, element: event.currentTarget });
  };

  const closeMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    onClick: (...p: []) => void,
  ) => {
    event.stopPropagation();

    if (canOpenUpgrade && productUsageDetails?.strawman_plan === "TRIAL") {
      setShowUsageLimitHitModal(true);
      closeMenu(event);
      return;
    }

    onClick();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-haspopup onClick={(e) => onClick(e, uniqueId)}>
        {icon ?? <MoreIcon />}
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl ? anchorEl.element : null}
        open={anchorEl ? anchorEl.id === uniqueId : false}
        onClose={closeMenu}
        classes={{ root: styles.classes.menu }}
      >
        {items.map((item, idx) => {
          return (
            <MenuItem
              key={idx}
              data-pw={item.dataPw}
              onClick={(e) => handleClick(e, item.onClick)}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
