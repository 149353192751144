import React from "react";

interface IProps {
  onOpen?: (...args: any[]) => void;
  onClose?: () => void;
}

export const useDrawer = ({
  onOpen = () => undefined,
  onClose = () => undefined,
}: IProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);

  const handleOpenDrawer = (...args: any[]) => {
    onOpen(...args);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    onClose();
    setIsDrawerOpen(false);
  };

  return {
    isDrawerOpen,
    handleOpenDrawer,
    handleCloseDrawer,
  };
};
