import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LigtModeIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeIcon from "@mui/icons-material/DarkModeOutlined";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { UserButton } from "@clerk/clerk-react";

import useStyles from "./styles";
import ThemeContext from "../../utils/context/theme-context";
import CapeZeroLogoLight from "../logos-and-icons/capezero-logo-light";
import CapeZeroLogoDark from "../logos-and-icons/capezero-logo-dark";
import OrganizationSwitcher from "../organization-switcher";
import { cn } from "../../utils/helpers";

interface IProps {
  toggleSidebar: () => void;
}

export default function Header({ toggleSidebar }: IProps): JSX.Element {
  const styles = useStyles();

  const { darkMode, setDarkMode } = React.useContext(ThemeContext);

  const environment = process.env.REACT_APP_NODE_ENV;
  let environmentLabel = "";
  let borderColor = "";

  switch (environment) {
    case "dev":
      environmentLabel = "Development";
      borderColor = "#AAA"; // gray
      break;
    case "staging":
      environmentLabel = "Staging";
      borderColor = "#FFA500"; // orange
      break;
    case "uat":
      environmentLabel = "UAT";
      borderColor = "#2AAA8A"; // green
      break;
    case "test":
      environmentLabel = "Test";
      borderColor = "#FF5252"; // red
      break;
    default:
      environmentLabel = "";
      borderColor = "";
  }

  const CapeZeroLogo = React.useMemo(() => {
    return darkMode ? <CapeZeroLogoDark /> : <CapeZeroLogoLight />;
  }, [darkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      classes={{ root: styles.classes.appbarRoot }}
    >
      <Toolbar classes={{ root: styles.classes.toolbarRoot }}>
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box className={cn("ml-2")}>
            <Link to="/">{CapeZeroLogo}</Link>
          </Box>
        </Box>

        <Box className={styles.classes.controls}>
          <IconButton onClick={toggleTheme}>
            {darkMode ? <LigtModeIcon /> : <DarkModeIcon />}
          </IconButton>

          {environment !== "prod" && (
            <Chip
              variant="outlined"
              label={environmentLabel}
              style={{ border: `2px solid ${borderColor}` }}
            />
          )}

          <OrganizationSwitcher />
          <UserButton
            userProfileMode="modal"
            afterSignOutUrl="/sign-in"
            signInUrl="/sign-in"
            userProfileProps={{
              appearance: {
                elements: {
                  profileSectionPrimaryButton__emailAddresses: {
                    display: "none",
                  },
                  profileSectionPrimaryButton__connectedAccounts: {
                    display: "none",
                  },
                },
              },
            }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
