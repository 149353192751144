import ProjectTimingView from "../../views/project/timing";
import ProjectPagesLayout from "../../components/project-pages-layout";
import { getProjectTiming, editProjectTiming } from "../../apis/project/timing";

export default function Timing(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <ProjectTimingView
        editProjectTiming={editProjectTiming}
        getProjectTiming={getProjectTiming}
      />
    </ProjectPagesLayout>
  );
}
