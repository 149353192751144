import React from "react";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import MuiButton from "@mui/material/Button";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import ProjectOtherFlowFormModal from "../../../../components/other-flow-form-modal";
import { AppContext } from "../../../../utils/context/app-context";
import { cn, numberToUSD, numberWithCommas } from "../../../../utils/helpers";
import { useAPI } from "../../../../utils/hooks";
import {
  PROJECT_OTHER_STREAM_TYPE,
  PROJECT_OTHER_STREAM_INPUT_TYPE,
  USER_PERMISSIONS,
  PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IProjectTiming,
  IProjectOtherFlow,
  IProjectOtherFlowForm,
  IProjectOtherFlowFormErrors,
} from "../../../../interfaces";

interface IProps {
  getProjectOtherFlowDetails: (
    projectId: number,
    id: number,
  ) => Promise<IProjectOtherFlow>;
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[] | null>;
  editProjectOtherFlow: (
    projectId: number,
    id: number,
    form: IProjectOtherFlowForm,
  ) => Promise<IProjectOtherFlow>;
  deleteProjectOtherFlow: (projectId: number, id: number) => Promise<boolean>;
}

export default function ProjectOtherFlowDetailView({
  getProjectOtherFlowDetails,
  getProjectTiming,
  editProjectOtherFlow,
  deleteProjectOtherFlow,
}: IProps) {
  const { projectId, id } = useParams();
  const navigate = useNavigate();

  const { setConfirmDeleteModalProps } = React.useContext(AppContext);

  const [projectOtherStreamDetails, setProjectOtherFlowDetails] =
    React.useState<IProjectOtherFlow>();
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [visibleCurveRows, setVisibleCurveRows] = React.useState<number>(8);
  const [openEditStreamFormModal, setOpenEditStreamFormModal] =
    React.useState<boolean>(false);
  const [editStreamForm, setEditStreamForm] =
    React.useState<IProjectOtherFlowForm>(
      PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
    );

  const {
    callAPI: getProjectOtherFlowDetailsCallAPI,
    loading: getProjectOtherFlowDetailsLoading,
    errored: getProjectOtherFlowDetailsErrored,
  } = useAPI((projectId, id) => getProjectOtherFlowDetails(projectId, id), {
    initialLoading: true,
  });

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: editProjectOtherFlowCallAPI,
    fieldErrors: editProjectOtherFlowFormErrors,
    setFieldErrors: setEditProjectOtherFlowFormErrors,
    loading: loadingEditProjectOtherFlow,
  } = useAPI<IProjectOtherFlow, IProjectOtherFlowFormErrors>(
    (projectId: number, id: number, form: IProjectOtherFlowForm) =>
      editProjectOtherFlow(projectId, id, form),
  );

  const { callAPI: deleteProjectOtherFlowCallAPI } = useAPI(
    (projectId: number, id: number) => deleteProjectOtherFlow(projectId, id),
    { setConfirmModalLoading: true },
  );

  React.useEffect(() => {
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setDateSchedule(response[0]?.date_schedule || []);
    });
  }, [projectId]);

  React.useEffect(() => {
    getProjectOtherFlowDetailsCallAPI(projectId, id).then((res) => {
      res && setProjectOtherFlowDetails(res);
    });
  }, [projectId, id]);

  const toggleShowMoreCurveRows = () => {
    setVisibleCurveRows((prevState) => {
      const streamStripLength =
        projectOtherStreamDetails?.stream_strip?.length ?? 0;
      return prevState === 8 ? streamStripLength : 8;
    });
  };

  const handleOpenEditStreamModal = () => {
    if (projectOtherStreamDetails) {
      const { name, stream_strip, type, input_type } =
        projectOtherStreamDetails;
      setEditStreamForm({
        name,
        stream_strip,
        type,
        input_type,
      });
      setOpenEditStreamFormModal(true);
    }
  };

  const handleCloseEditStreamModal = () => {
    setOpenEditStreamFormModal(false);
  };

  const onEditStream = async (form: IProjectOtherFlowForm) => {
    const { id: otherCashId } = projectOtherStreamDetails!;
    const stream = await editProjectOtherFlowCallAPI(
      Number(projectId),
      otherCashId,
      form,
    );
    stream && setProjectOtherFlowDetails(stream);
    return stream;
  };

  const handleDeleteCash = async (id: number) => {
    const deleted = await deleteProjectOtherFlowCallAPI(Number(projectId), id);

    if (deleted) {
      // navigate to Other Flow list
      deleted && navigate(`/project/${projectId}/pro-forma/other-flows`);
    }
  };

  const onDeleteCash = (id: number) => {
    if (id) {
      setConfirmDeleteModalProps({
        open: true,
        title: "Delete Other Cash",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteCash(id),
      });
    }
  };

  return (
    <>
      <ViewWrapper
        loading={getProjectOtherFlowDetailsLoading}
        error={getProjectOtherFlowDetailsErrored}
      >
        <Box className={cn("flex justify-between items-center m-2")}>
          <IconButton
            className={cn("bg-gray-300 hover:bg-gray-400")}
            onClick={() => window.history.back()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box>
            <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
              <Box className={cn("flex gap-4 items-center")}>
                <Button
                  canOpenUpgrade
                  startIcon={<EditIcon />}
                  label="Edit"
                  btnType="primary"
                  onClick={handleOpenEditStreamModal}
                />
                <Button
                  canOpenUpgrade
                  label="Delete"
                  btnType="danger"
                  onClick={() =>
                    onDeleteCash(projectOtherStreamDetails?.id ?? 0)
                  }
                />
              </Box>
            </Protect>
          </Box>
        </Box>
        <Box className={cn("grid gap-4 md:grid-cols-2")}>
          {projectOtherStreamDetails && (
            <DetailsCard
              heading="Stream Details"
              sections={[
                {
                  fields: [
                    {
                      label: "Name",
                      value: {
                        text: projectOtherStreamDetails.name,
                      },
                    },
                    {
                      label: "Type",
                      value: {
                        text: PROJECT_OTHER_STREAM_TYPE[
                          projectOtherStreamDetails.type
                        ],
                      },
                    },
                    {
                      label: "Input Type",
                      value: {
                        text: PROJECT_OTHER_STREAM_INPUT_TYPE[
                          projectOtherStreamDetails.input_type
                        ],
                      },
                    },
                  ],
                },
              ]}
            />
          )}

          {projectOtherStreamDetails && (
            <DetailsCard
              heading="Schedule"
              autoHeight
              actionButton={
                projectOtherStreamDetails.stream_strip &&
                projectOtherStreamDetails.stream_strip.length > 8 ? (
                  <Box className={cn("flex justify-end")}>
                    <MuiButton
                      onClick={toggleShowMoreCurveRows}
                      classes={{ root: cn("!text-secondary !capitalize") }}
                    >
                      {visibleCurveRows ===
                      projectOtherStreamDetails.stream_strip.length
                        ? "Show Less"
                        : "Show More"}
                    </MuiButton>
                  </Box>
                ) : undefined
              }
              sections={[
                {
                  fields: (projectOtherStreamDetails.stream_strip || [])
                    .map((e, i) => ({
                      label: dateSchedule[i],
                      value: {
                        text: `$${numberWithCommas.format(Number(e))}`,
                      },
                    }))
                    .slice(0, visibleCurveRows),
                },
              ]}
              postActionButtonSections={[
                {
                  fields: [
                    {
                      label: "Total",
                      value: {
                        text: numberToUSD.format(
                          projectOtherStreamDetails.schedule_total || 0,
                        ),
                      },
                      textWeight: 600,
                    },
                  ],
                },
              ]}
            />
          )}
        </Box>
      </ViewWrapper>

      <ProjectOtherFlowFormModal
        open={openEditStreamFormModal}
        headerLabel="Edit Other Flow"
        form={editStreamForm}
        loading={loadingEditProjectOtherFlow}
        formErrors={editProjectOtherFlowFormErrors}
        setFormErrors={setEditProjectOtherFlowFormErrors}
        setForm={setEditStreamForm}
        onClose={handleCloseEditStreamModal}
        onConfirm={onEditStream}
        dateSchedule={dateSchedule}
      />
    </>
  );
}
