import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";

import useStyles from "./styles";
import useCommonStyles from "../common-styles";
import ViewWrapper from "../../../../components/view-wrapper";
import { useAPI } from "../../../../utils/hooks";
import { AppContext } from "../../../../utils/context/app-context";
import {
  IDealCostCell,
  IDealCostRow,
  IDealTaxCredit,
} from "../../../../interfaces";
import {
  generateDealTaxCreditRows,
  generateDealTaxCreditHeaders,
  generateDealTaxCreditPtcListHeaders,
  generateDealTaxCreditPtcListRows,
} from "../../../../utils/helpers";

interface IProps {
  getTaxCredit: (dealId: number) => Promise<IDealTaxCredit[]>;
}

export default function DealTaxCredits({ getTaxCredit }: IProps): JSX.Element {
  const styles = useStyles();
  const commonStyles = useCommonStyles({ columnToHide: 2 }); // hide the second column

  const { caseDealId } = useParams();

  const { currentDeal } = React.useContext(AppContext);

  React.useEffect(() => {
    getDealProjectDepreciationCallAPI(Number(caseDealId));
  }, [caseDealId]);

  const {
    callAPI: getDealProjectDepreciationCallAPI,
    loading: getDealProjectDepreciationLoading,
    errored: getDealProjectDepreciationFailed,
    response: deal_project_depreciation,
  } = useAPI((id) => getTaxCredit(id), { initialLoading: true });

  const headers = React.useMemo(() => {
    if (deal_project_depreciation) {
      const a = generateDealTaxCreditHeaders(deal_project_depreciation);
      return a;
    }
    return [];
  }, [deal_project_depreciation]);

  const ptcListHeaders = React.useMemo(() => {
    if (deal_project_depreciation) {
      const a = generateDealTaxCreditPtcListHeaders(deal_project_depreciation);
      return a;
    }
    return [];
  }, [deal_project_depreciation]);

  const rows: IDealCostRow[] = React.useMemo(() => {
    if (deal_project_depreciation) {
      return generateDealTaxCreditRows(deal_project_depreciation);
    }
    return [];
  }, [deal_project_depreciation]);

  const ptcListRows = React.useMemo(() => {
    if (deal_project_depreciation) {
      return generateDealTaxCreditPtcListRows(deal_project_depreciation);
    }
    return [];
  }, [deal_project_depreciation]);

  // When only ITC projects in a deal, remove PTC and ITC/PTC rows. Example.
  const rowsToIgnoreWhenITC = [
    "PTC",
    "PTC Adder",
    "PTC Total",
    "ITC/PTC",
    "ITC/PTC Adder",
    "ITC/PTC Total",
  ];

  // When only PTC projects in a deal, remove ITC and ITC/PTC rows
  const rowsToIgnoreWhenPTC = [
    "ITC",
    "ITC Adder",
    "ITC Total",
    "ITC/PTC",
    "ITC/PTC Adder",
    "ITC/PTC Total",
  ];

  // When NONE - hide the PTC and ITC rows. Only show ITC/PTC (they will be 0 anyway)
  const rowsToIgnoreWhenNone = [
    "PTC",
    "ITC",
    "PTC Adder",
    "PTC Total",
    "ITC Adder",
    "ITC Total",
  ];

  return (
    <ViewWrapper
      loading={getDealProjectDepreciationLoading}
      error={getDealProjectDepreciationFailed}
    >
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer classes={{ root: styles.classes.tableContainer }}>
          <Table
            stickyHeader
            classes={{ root: commonStyles.classes.tableWithHideableColumn }}
            data-hide-total={
              (currentDeal?.projects?.length ?? 0) <= 1 // set this attribute true to hide the total column when only one project is present
            }
          >
            <TableHead className={styles.classes.header}>
              <TableRow>
                {headers.map((header, idx) => {
                  if (idx > 1) {
                    return (
                      <TableCell key={idx} align="right">
                        <Link
                          to={`/project/${header.id}/tax/tax-credit`}
                          target="_blank"
                        >
                          {header.label}
                        </Link>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={idx} align="right">
                        {header.label}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: IDealCostRow, idx: number) => {
                if (
                  currentDeal?.tax_credit_type === "ITC" &&
                  rowsToIgnoreWhenITC.includes(row.columns[0]?.value.trim())
                ) {
                  return undefined;
                } else if (
                  currentDeal?.tax_credit_type === "PTC" &&
                  rowsToIgnoreWhenPTC.includes(row.columns[0]?.value.trim())
                ) {
                  return undefined;
                } else if (
                  currentDeal?.tax_credit_type === "NONE" &&
                  rowsToIgnoreWhenNone.includes(row.columns[0]?.value.trim())
                ) {
                  return undefined;
                }
                return (
                  <TableRow
                    key={idx}
                    className={row.is_bold ? styles.classes.boldRow : ""}
                  >
                    {row.columns.map((cell: IDealCostCell, iIdx: number) => (
                      <TableCell key={iIdx} align="right">
                        {cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* If ptcListRows.length > 2 then show this table. 2 since its totals rows. */}
      {ptcListRows.length > 2 && (
        <>
          <Typography fontWeight={600} sx={{ margin: 2 }}>
            PTC Generated ($)
          </Typography>

          <Paper sx={{ overflow: "hidden" }}>
            <TableContainer classes={{ root: styles.classes.tableContainer }}>
              <Table stickyHeader>
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {ptcListHeaders.map((header, idx) => {
                      if (idx > 0) {
                        return (
                          <TableCell key={idx} align="right">
                            <Link
                              to={`/project/${header.id}/tax/tax-credit`}
                              target="_blank"
                            >
                              {header.label}
                            </Link>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={idx} align="right">
                            {header.label}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ptcListRows.map((row: IDealCostRow, idx: number) => (
                    <TableRow
                      key={idx}
                      className={row.is_bold ? styles.classes.boldRow : ""}
                    >
                      {row.columns.map((cell: IDealCostCell, iIdx: number) => (
                        <TableCell key={iIdx} align="right">
                          {cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
    </ViewWrapper>
  );
}
