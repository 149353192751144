import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import FormControl from "@mui/material/FormControl";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Property as CssProperty } from "csstype";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { IExtendedFormHelperTextProps } from "../../interfaces";
import {
  formatNumberForView,
  keepNumbersDotAndMinus,
} from "../../utils/helpers";

interface IProps {
  label?: string;
  dataPw?: string;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  tooltip?: string;
  fullWidth?: boolean;
  isNumeric?: boolean;
  tooltipOptions?: {
    alignment: CssProperty.AlignItems;
  };
  helperText?: string;
}

export default function TextInput({
  label,
  dataPw,
  startAdornment,
  endAdornment,
  tooltip,
  fullWidth = true,
  tooltipOptions,
  isNumeric,
  value,
  onChange,
  helperText,
  ...restProps
}: IProps & TextFieldProps): JSX.Element {
  const styles = useStyles({ fullWidth, tooltipOptions });

  const formatError = (error?: string[] | string) => {
    if (!error) {
      return "";
    }
    return Array.isArray(error)
      ? error.find((element) => typeof element === "string") ?? ""
      : error;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNumeric) {
      e.target.value = keepNumbersDotAndMinus(e?.target.value);
      onChange && onChange(e);
      return;
    }
    onChange && onChange(e);
  };

  const getValue = () => {
    if (!isNumeric || !value) {
      return value;
    }
    return formatNumberForView(String(value));
  };

  return (
    <Box className={styles.classes.container}>
      <FormControl classes={{ root: styles.classes.formControl }} fullWidth>
        <TextField
          fullWidth
          size="small"
          value={getValue()}
          onChange={handleOnChange}
          {...restProps}
          label={label}
          autoComplete="off"
          variant="outlined"
          inputProps={{
            className: styles.classes.input,
            "data-pw": dataPw || label,
          }}
          InputProps={{ startAdornment, endAdornment }}
          FormHelperTextProps={
            {
              "data-pw": `helper-text-${dataPw || label}`,
            } as IExtendedFormHelperTextProps
          }
          InputLabelProps={{
            classes: {
              shrink: styles.classes.labelShrink,
              animated: styles.classes.labelAnimated,
            },
          }}
          onWheel={(e) => {
            (e.target as HTMLInputElement).blur();
          }}
          helperText={formatError(helperText)}
        />
      </FormControl>

      {tooltip && (
        <Tooltip title={tooltip} placement="top-end">
          <InfoIcon classes={{ root: styles.classes.infoIcon }} />
        </Tooltip>
      )}
    </Box>
  );
}
