import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { ICashReportChart, IDealOfCase } from "../../../../../interfaces";

ChartJS.register(BarElement, BarController);

type ChartTypes = "cashReportChart" | "cashReportChart";

interface IProps {
  cashReportChart: ICashReportChart;
  showChart: ChartTypes;
  stack?: boolean;
  isPercentage?: boolean;
  label: string;
  activeDeal?: IDealOfCase;
}

export default function Chart({
  cashReportChart,
  showChart,
  stack,
  isPercentage,
  label,
  activeDeal,
}: IProps): JSX.Element {
  const width = useScreenWidth();
  const cashReportChartRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    const cashReportChart2DRef = cashReportChartRef?.current?.getContext("2d");

    const labelPrefix = activeDeal?.structure === "CEP" ? "CE" : "TE";

    const cashReportChartObj =
      cashReportChart2DRef &&
      new ChartJS(cashReportChart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: Object.keys(cashReportChart.se_cash),
          datasets: [
            {
              label: `${labelPrefix} ${label}`,
              data: Object.values(cashReportChart.te_cash),
              backgroundColor: "#5335FA",
            },
            {
              label: `SE ${label}`,
              data: Object.values(cashReportChart.se_cash),
              backgroundColor: "#00A768",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    if (isPercentage) {
                      // div by 100 since backend already sends in percentage, this assumes fraction
                      label += new Intl.NumberFormat("en-US", {
                        style: "percent",
                        minimumFractionDigits: 2,
                      }).format(context.parsed.y / 100);
                    } else {
                      label += new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(context.parsed.y);
                    }
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              stacked: stack,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              stacked: stack,
              title: {
                display: true,
                text: isPercentage ? "Share (%)" : "Amount ($)",
              },
            },
          },
        },
      });

    return () => {
      cashReportChartObj?.destroy();
    };
  }, [width, cashReportChart]);

  const selectedChart = (chart: ChartTypes) => {
    switch (chart) {
      case "cashReportChart":
        return cashReportChartRef;
    }
    return undefined;
  };

  return <canvas id={showChart} ref={selectedChart(showChart)} />;
}
