import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import {
  PROJECT_TYPE_OPTIONS,
  PROJECT_STAGE_OPTIONS,
  US_STATES_OPTIONS,
  MARKETS_OPTIONS,
  PROJECT_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  IProjectForm,
  IProjectFormErrors,
  SetStateAction,
  ISelectOption,
  IAddUpdateProjectResponse,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IProjectFormErrors;
  setFormErrors: SetStateAction<IProjectFormErrors | undefined>;
  form: IProjectForm;
  setForm: SetStateAction<IProjectForm>;
  onClose: () => void;
  onConfirm: (
    form: IProjectForm,
  ) => Promise<IAddUpdateProjectResponse | undefined>;
  hide?: (keyof IProjectForm)[];
}

export default function ProjectFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  hide = [],
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const project = await onConfirm(form);
    project && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(PROJECT_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {!hide.includes("name") && (
          <TextInput
            required
            label="Project Name"
            name="name"
            value={form.name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
          />
        )}
        {!hide.includes("sponsor_name") && (
          <TextInput
            required
            label="Sponsor Name"
            name="sponsor_name"
            value={form.sponsor_name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.sponsor_name)}
            helperText={formErrors?.sponsor_name}
            disabled={loading}
          />
        )}
        {!hide.includes("type") && (
          <AutocompleteField
            label="Project Type"
            name="type"
            onChange={handleSingleAutoCompleteChange}
            options={PROJECT_TYPE_OPTIONS}
            value={String(form.type)}
            helperText={formErrors?.type}
            onFocus={() => {
              clearSelectErrorOnFocus("type");
            }}
            disabled={loading}
          />
        )}
        {!hide.includes("state") && (
          <AutocompleteField
            label="Project State"
            name="state"
            onChange={handleSingleAutoCompleteChange}
            options={US_STATES_OPTIONS}
            value={String(form.state)}
            helperText={formErrors?.state}
            onFocus={() => {
              clearSelectErrorOnFocus("state");
            }}
            disabled={loading}
          />
        )}
        {!hide.includes("power_market") && (
          <AutocompleteField
            label="Power Market"
            name="power_market"
            onChange={handleSingleAutoCompleteChange}
            options={MARKETS_OPTIONS}
            value={String(form.power_market)}
            helperText={formErrors?.power_market}
            onFocus={() => {
              clearSelectErrorOnFocus("power_market");
            }}
            disabled={loading}
          />
        )}
        {!hide.includes("sub_stage") && (
          <AutocompleteField
            label="Project Stage"
            name="sub_stage"
            onChange={handleSingleAutoCompleteChange}
            options={PROJECT_STAGE_OPTIONS}
            value={String(form.sub_stage)}
            helperText={formErrors?.sub_stage}
            onFocus={() => {
              clearSelectErrorOnFocus("sub_stage");
            }}
            disabled={loading}
          />
        )}
      </Box>
    </Modal>
  );
}
