import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import {
  IDeal,
  IDealFat,
  IDealDetails,
  IAddDealForm,
  IUpdateDealForm,
  IGetDealsParams,
  IAddDealResponse,
  IDuplicateDealForm,
  IUpdateDealResponse,
  IDuplicateDealResponse,
  ServerPaginatedResponse,
  IUpdateDealTaxCreditTranferConstraintsForm,
} from "../../interfaces";

export const addDeal = async (form: IAddDealForm) => {
  const url = replaceParams(DEAL_API_ROUTES.base.create, {});
  return await AxiosHelper.post<IAddDealForm, IAddDealResponse>(url, form);
};

export const getDeals = async (params: IGetDealsParams) => {
  const url = replaceParams(DEAL_API_ROUTES.base.get, {});
  const { limit, stage, structure, tax_credit_structure } = params;
  return await AxiosHelper.get<ServerPaginatedResponse<IDeal[]>>(url, {
    params: {
      limit,
      stage__in: stage,
      structure__in: structure,
      tax_credit_structure__in: tax_credit_structure,
    },
  });
};

export const getDealDetails = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.base.getDetails, { dealId });
  return await AxiosHelper.get<IDealDetails>(url);
};

export const updateDeal = async (dealId: number, form: IUpdateDealForm) => {
  const url = replaceParams(DEAL_API_ROUTES.base.update, { dealId });
  return await AxiosHelper.patch<IUpdateDealForm, IUpdateDealResponse>(
    url,
    form,
  );
};

export const updateDealTransferConstraints = async (
  dealId: number,
  form: IUpdateDealTaxCreditTranferConstraintsForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.base.update, { dealId });
  return await AxiosHelper.patch<
    IUpdateDealTaxCreditTranferConstraintsForm,
    IDealDetails
  >(url, form);
};

export const duplicateDeal = async (
  dealId: number,
  form: IDuplicateDealForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.base.duplicate, { dealId });
  return await AxiosHelper.post<IDuplicateDealForm, IDuplicateDealResponse>(
    url,
    form,
  );
};

export const getDealDetailsFat = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.base.getDetailsFat, {
    dealId,
  });
  return await AxiosHelper.get<IDealFat>(url);
};
