import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  container: {
    height: "100%",
    margin: theme.spacing(2, 0),
    "& > *:first-of-type": {
      marginBottom: theme.spacing(4),
    },
  },
  tableTitle: {
    marginBottom: "10px !important",
    fontWeight: 600,
  },
  filtersContainer: {
    display: "flex",
    gap: "1rem",
    marginBlock: theme.spacing(1),
  },
  filters: {
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    marginBlock: theme.spacing(2),
  },
}));
