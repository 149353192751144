import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import Select from "../select-input";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import MultiAutocompleteField from "../multi-autocomplete-field";
import {
  DEAL_STAGES_OPTIONS,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS,
  UPDATE_DEAL_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  ISelectOption,
  SetStateAction,
  IUpdateDealForm,
  IUpdateDealFormErrors,
  IUpdateDealResponse,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  form: IUpdateDealForm;
  existingProjects: ISelectOption[];
  formErrors?: IUpdateDealFormErrors;
  hide?: (keyof IUpdateDealForm)[];
  setForm: SetStateAction<IUpdateDealForm>;
  setFormErrors: SetStateAction<IUpdateDealFormErrors | undefined>;
  onClose: () => void;
  onUpdate: (form: IUpdateDealForm) => Promise<IUpdateDealResponse | undefined>;
  openAddProjectModal: () => void;
}

export default function UpdateDealFormModal({
  open,
  loading,
  form,
  existingProjects,
  formErrors,
  hide = [],
  setFormErrors,
  setForm,
  onClose,
  onUpdate,
  openAddProjectModal,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleChangeDealInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption[] | null,
    name: string,
  ) => {
    if (Array.isArray(value)) {
      const v = value?.map((o: ISelectOption) => o.value);
      setForm((prev) => {
        return {
          ...prev,
          [name]: v as Array<string>,
        };
      });
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleStageChange = (e: SelectChangeEvent<unknown>, field: string) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleUpdateDeal = async () => {
    const deal = await onUpdate(form);
    deal && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(UPDATE_DEAL_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading="Update Deal"
      onClose={handleOnClose}
      onConfirm={handleUpdateDeal}
      resetForm={handleResetForm}
    >
      <Box>
        {!hide.includes("name") && (
          <TextInput
            required
            label="Deal Name"
            name="name"
            value={form.name}
            onFocus={clearErrorOnFocus}
            onChange={handleChangeDealInfo}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
            fullWidth={false}
          />
        )}

        {!hide.includes("stage") && (
          <AutocompleteField
            label="Deal Stage"
            name="stage"
            onChange={handleSingleAutoCompleteChange}
            options={DEAL_STAGES_OPTIONS}
            value={String(form.stage)}
            helperText={formErrors?.stage}
            onFocus={() => {
              clearSelectErrorOnFocus("stage");
            }}
            disabled={loading}
            fullWidth={false}
          />
        )}

        {!hide.includes("tax_credit_structure") && (
          <Select
            required
            label="Tax Credit Structure"
            selected={form.tax_credit_structure}
            items={DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("tax_credit_structure")}
            onChange={(e) => handleStageChange(e, "tax_credit_structure")}
            error={Boolean(formErrors?.tax_credit_structure)}
            helperText={formErrors?.tax_credit_structure}
            disabled={loading}
            tooltip="<b>Regular</b> = No changes to tax credit election \n<b>Transfer</b> =  Section 6418 transferability \n<b>Direct Pay</b> =  Section 6417 elective pay"
            fullWidth={false}
          />
        )}

        {!hide?.includes("project_ids") && (
          <MultiAutocompleteField
            label="Select Projects"
            name="project_ids"
            onChange={handleAutoCompleteChange}
            options={existingProjects}
            value={form.project_ids}
            helperText={formErrors?.project_ids}
            firstItem={{
              label: "Add Project",
              onClick: openAddProjectModal,
              value: "add",
            }}
            onFocus={() => clearSelectErrorOnFocus("project_ids")}
            disabled={loading}
            fullWidth={false}
          />
        )}
      </Box>
    </Modal>
  );
}
