import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";

import { cn } from "../../utils/helpers";
import { AppContext } from "../../utils/context/app-context";

interface IProps {
  canOpenUpgrade?: boolean;
  actionType: "edit" | "delete" | "duplicate";
}

export default function ActionButton({
  canOpenUpgrade = false,
  actionType,
  onClick,
  ...props
}: ButtonProps & IProps): JSX.Element {
  const { productUsageDetails, setShowUsageLimitHitModal } =
    React.useContext(AppContext);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (canOpenUpgrade) {
      if (productUsageDetails?.strawman_plan === "TRIAL") {
        setShowUsageLimitHitModal(true);
        return;
      }
    }
    onClick && onClick(e);
  };

  const buttonStyles: string = React.useMemo(() => {
    let twClassName = "!font-semibold ";

    if (actionType === "edit") {
      twClassName += "!text-white !bg-secondary";
    }
    if (actionType === "delete") {
      twClassName += "!text-danger !border-danger";
    }
    if (actionType === "duplicate") {
      twClassName += "!text-gray !border-gray";
    }

    return twClassName;
  }, [actionType]);

  const buttonVariant = React.useMemo(() => {
    return actionType === "edit" ? "contained" : "outlined";
  }, [actionType]);

  return (
    <Button
      className={cn(buttonStyles)}
      variant={buttonVariant}
      onClick={handleOnClick}
      {...props}
    />
  );
}
