import DealPagesLayout from "../../../components/deal-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import DealSizingDebt from "../../../views/deal/sizing/debt";
import { getCurves } from "../../../apis/configuration/base";
import {
  getDealDebt,
  deleteDealDebt,
  addDealDebt,
  updateDealDebt,
} from "../../../apis/deal/debt";

export default function TaxEquity(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealSizingDebt
          getDealDebt={getDealDebt}
          deleteDealDebt={deleteDealDebt}
          addDealDebt={addDealDebt}
          updateDealDebt={updateDealDebt}
          getCurves={getCurves}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
