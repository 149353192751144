import { getDealTiming } from "../../../../apis/deal/timing";
import { getConstructionDebtSummary } from "../../../../apis/report/deal";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputConstructionDebtSummaryView from "../../../../views/deal/output/construction-debt/summary";

export default function DealOutputConstructionDebtSummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputConstructionDebtSummaryView
          getDealTiming={getDealTiming}
          getConstructionDebtSummary={getConstructionDebtSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
