import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import CheckboxInput from "../../../../../components/checkbox-input";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { AppContext } from "../../../../../utils/context/app-context";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../../../../constants";
import {
  cn,
  getSponsorEquityBOL_ReportSummaryUrl,
  generateSponsorEquityBOL_ReportSummaryRows,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  ISponsorEquityBOL_ReportSummary,
} from "../../../../../interfaces";

interface IProps {
  getSponsorEquityBOL_ReportSummary: (
    dealId: number,
    params: { force_unlevered: boolean },
  ) => Promise<ISponsorEquityBOL_ReportSummary>;
}

export default function DealOutputSponsorEquityBOL_SummaryView({
  getSponsorEquityBOL_ReportSummary,
}: IProps): JSX.Element {
  const { caseDealId } = useParams();
  const { urlLoadingPercentages } = useAxios();
  const { setDealOutputLoaded, currentDealDebt, casesOfDeal } =
    React.useContext(AppContext);

  const [activeShowUnlevered, setActiveShowUnlevered] = useSessionStorage<
    null | boolean
  >(`active-deal-${caseDealId}-show-unlevered`, false);

  const [summary, setSummary] =
    React.useState<ISponsorEquityBOL_ReportSummary>();

  const {
    callAPI: getSummaryCallAPI,
    errored: getSummaryFailed,
    loading: loadingSummary,
    errorObj: getSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealId: number, forceUnlevered: boolean) =>
      getSponsorEquityBOL_ReportSummary(dealId, {
        force_unlevered: forceUnlevered,
      }),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      // Get the current value from sessionStorage
      const currentValue = sessionStorage.getItem(
        `active-deal-${caseDealId}-show-unlevered`,
      );
      // If there's a value in sessionStorage for the new caseDealId, update activeShowUnlevered
      if (currentValue !== null) {
        setActiveShowUnlevered(JSON.parse(currentValue));
      }

      getSummaryCallAPI(
        Number(caseDealId),
        JSON.parse(currentValue ?? "false"),
      ).then((response) => {
        if (response) {
          setSummary(response);
        }
      });
    }
  }, [caseDealId, activeShowUnlevered]);

  React.useEffect(() => {
    !loadingSummary && setDealOutputLoaded(!getSummaryFailed);
  }, [getSummaryFailed, loadingSummary]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActiveShowUnlevered(e.target.checked);
  };

  const cashTableRows: ITableRow[] | null = React.useMemo(() => {
    if (summary) {
      return generateSponsorEquityBOL_ReportSummaryRows(summary);
    }
    return [];
  }, [summary]);

  const isSensitive: boolean = React.useMemo(() => {
    const item = casesOfDeal?.find(
      (c) => c?.child_deal?.id === Number(caseDealId),
    );
    return item?.type === "SENS";
  }, [caseDealId, casesOfDeal]);

  return (
    <>
      <Box marginBlock={1}>
        {!isSensitive && currentDealDebt && (
          <CheckboxInput
            label="Show Unlevered"
            onChange={handleCheckboxChange}
            checked={activeShowUnlevered ?? false}
            disabled={loadingSummary}
          />
        )}
      </Box>
      <ViewWrapper
        loading={loadingSummary}
        error={getSummaryFailed}
        errorHeading={getSummaryErrorObj?.heading}
        errorDescription={getSummaryErrorObj?.description}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getSponsorEquityBOL_ReportSummaryUrl(
              Number(caseDealId),
              activeShowUnlevered ?? false,
            )
          ]
        }
      >
        <Box marginBlock={1}>
          <Box className={cn("w-[40%] min-w-[400px]")}>
            <ReportTable
              columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
              rows={cashTableRows}
            />
          </Box>
        </Box>
      </ViewWrapper>
    </>
  );
}
