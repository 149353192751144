import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MuiButtom from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Protect } from "@clerk/clerk-react";
import { useParams } from "react-router-dom";

import useStyles from "./styles";
import Logs from "../../../components/logs";
import Button from "../../../components/button";
import LogsWrapper from "../../../components/logs-wrapper";
import ViewWrapper from "../../../components/view-wrapper";
import DetailsCard from "../../../components/details-card";
import ChartWrapper from "../../../components/chart-wrapper";
import CheckboxInput from "../../../components/checkbox-input";
import ProjectTimingFormModal from "../../../components/project-timing-form-modal";
import TimingChart, {
  ITimingChartProps,
} from "../../../components/timing-chart";
import { useAPI, useLogs, useDrawer } from "../../../utils/hooks";
import { AppContext } from "../../../utils/context/app-context";
import {
  IProjectTiming,
  IProjectDetails,
  IUpdateProjectTimingForm,
  IUpdateProjectTimingFormErrors,
  ILogsConfiguration,
} from "../../../interfaces";
import {
  USER_PERMISSIONS,
  PROJECT_TIMING_HORIZON,
  PROJECT_TIMING_ADJUSTMENT,
  PROJECT_TIMING_PERIODICITY,
  CONFIRM_DELETE_MODAL_DEFAULT_PROPS,
  PROJECT_TIMING_HORIZON_FIELD_MAPPING,
  UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
} from "../../../constants";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  editProjectTiming: (
    projectId: number,
    projectTimingId: number,
    form: IUpdateProjectTimingForm,
  ) => Promise<IProjectTiming>;
}

export default function ProjectTimingView({
  getProjectTiming,
  editProjectTiming,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { projectId } = useParams();

  const {
    setConfirmDeleteModalProps: setConfirmModalProps,
    setCurrentProject,
  } = React.useContext(AppContext);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();
  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [showOperationPeriod, setShowOperationPeriod] =
    React.useState<boolean>(false);

  const [showAllDates, setShowAllDates] = React.useState<boolean>(false);
  const [editTimingModalOpen, setEditTimingModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IUpdateProjectTimingForm>(
    UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
  );
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();

  React.useEffect(() => {
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      if (response) {
        setProjectTiming(response[0]);
        if (response[0]?.horizon === "OS") {
          setShowOperationPeriod(true);
        }
      }
    });
  }, [projectId]);

  const {
    callAPI: getProjectTimingCallAPI,
    errored: getTimingFailed,
    loading: loadingTiming,
  } = useAPI((projectId: number) => getProjectTiming(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: editProjectTimingCallAPI,
    fieldErrors: editProjectTimingFormErrors,
    setFieldErrors: setEditProjectTimingFormErrors,
    loading: editProjectTimingLoading,
  } = useAPI<IProjectTiming, IUpdateProjectTimingFormErrors>(
    (projectId: number, timingId: number, form: IUpdateProjectTimingForm) =>
      editProjectTiming(projectId, timingId, form),
    { setConfirmModalLoading: true },
  );

  const handleOnEditProjectTiming = async (form: IUpdateProjectTimingForm) => {
    const updatedForm = { ...form };

    Object.keys(updatedForm).forEach((key) => {
      if (updatedForm[key as keyof IUpdateProjectTimingForm] === "") {
        updatedForm[key as keyof IUpdateProjectTimingForm] = null as never;
      }
    });

    const fieldsToInclude =
      PROJECT_TIMING_HORIZON_FIELD_MAPPING[
        updatedForm.horizon as keyof typeof PROJECT_TIMING_HORIZON_FIELD_MAPPING
      ];

    const formToSubmit = Object.keys(updatedForm).reduce(
      (obj, key) => ({
        ...obj,
        [key as keyof IUpdateProjectTimingForm]: fieldsToInclude.includes(
          key as keyof IUpdateProjectTimingForm,
        )
          ? updatedForm[key as keyof IUpdateProjectTimingForm]
          : null,
      }),
      {} as IUpdateProjectTimingForm,
    );

    if (
      // if anything in projectTiming is changed, show confirm modal
      Object.keys(formToSubmit).some(
        (key) =>
          formToSubmit[key as keyof IUpdateProjectTimingForm] !==
          projectTiming?.[key as keyof IUpdateProjectTimingForm],
      )
    ) {
      setConfirmModalProps({
        open: true,
        title: "Confirm Timing Changes",
        description:
          "You are modifying timing, operating life, or periodicity, which may impact any price curves or strips of data you entered in expenses, revenue, etc. We will do our best to adjust them automatically, but please re-check or edit those as appropriate",
        confirmBtnLabel: "Confirm",
        confirmBtnType: "primary",
        onConfirm: async () => {
          const timing = await editProjectTimingCallAPI(
            Number(projectId),
            Number(projectTiming?.id),
            formToSubmit,
          );
          if (timing) {
            setProjectTiming(timing);
            handleCloseEditTimingModal();
            setCurrentProject(
              (project) =>
                ({
                  ...project,
                  bol: timing.start_date,
                  ntp: timing.ntp_date,
                  cod: timing.cod,
                }) as IProjectDetails,
            );
          }
          setConfirmModalProps(CONFIRM_DELETE_MODAL_DEFAULT_PROPS);
        },
      });
    } else {
      const timing = await editProjectTimingCallAPI(
        Number(projectId),
        Number(projectTiming?.id),
        formToSubmit,
      );
      timing && setProjectTiming(timing);
      timing &&
        setCurrentProject(
          (project) =>
            ({
              ...project,
              bol: timing.start_date,
              ntp: timing.ntp_date,
              cod: timing.cod,
            }) as IProjectDetails,
        );
      return timing;
    }
    return undefined;
  };

  const handleOpenEditProjectTimingModal = async () => {
    if (projectTiming) {
      const {
        created,
        created_by,
        id,
        modified,
        project,
        date_schedule,
        end_date,
        num_dates_in_schedule,
        ...formDetails
      } = projectTiming;
      setForm({ ...formDetails });
      setEditTimingModalOpen(true);
    }
  };

  const handleCloseEditTimingModal = () => {
    setEditTimingModalOpen(false);
  };

  const toggleShowAllDates = () => {
    setShowAllDates((s) => {
      s && window.scrollTo(0, 0);
      return !s;
    });
  };

  const timingChartData: ITimingChartProps | undefined = React.useMemo(() => {
    if (projectTiming) {
      const {
        horizon,
        cod,
        start_date,
        end_date,
        ntp_date,
        mechanical_completion_date,
        placed_in_service_date,
        substantial_completion_date,
      } = projectTiming;

      const dataPoints = [
        // {
        //   date: new Date(start_date),
        //   color: "#FFA700",
        //   symbol: "rect",
        //   tooltip: "Start Date",
        //   id: "start",
        // },
        {
          date: new Date(ntp_date),
          color: "#F44336",
          symbol: "rectRot",
          tooltip: "Notice to Proceed",
          id: "ntp",
        },
        {
          date: new Date(mechanical_completion_date),
          color: "#00B7C3",
          symbol: "triangle",
          tooltip: "Mechanical Completion Date",
          rotation: 180,
          id: "mc",
        },
        {
          date: new Date(substantial_completion_date),
          color: "#007EC5",
          symbol: "triangle",
          tooltip: "Substantial Completion Date",
          id: "sc",
        },
        {
          date: new Date(placed_in_service_date),
          color: "#AB47BC",
          symbol: "circle",
          tooltip: "Placed In Service Date",
          id: "pis",
        },
        {
          date: new Date(cod),
          color: "#00B96A",
          symbol: "rect",
          tooltip: "COD",
          id: "cod",
        },
        ...(showOperationPeriod
          ? [
              {
                date: new Date(end_date),
                color: "#F44336",
                symbol: "circle",
                tooltip: "End Date",
                id: "end",
              },
            ]
          : []),
      ];

      const filteredDataPoints = dataPoints.filter((d) => {
        return horizon === "OS" ? d.id === "cod" || d.id === "end" : true;
      });

      return {
        startDate: new Date(start_date),
        endDate: new Date(end_date),
        data: filteredDataPoints,
      };
    }
    return undefined;
  }, [projectTiming, showOperationPeriod]);

  const handleShowOperationPeriodToggle = () => {
    setShowOperationPeriod((s) => !s);
  };

  const projectTimingLogsConfiguration: ILogsConfiguration = {
    id: Number(projectTiming?.id),
    type: "projecttiming",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectTimingLogsConfiguration.type,
      projectTimingLogsConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingTiming} error={getTimingFailed}>
        <Box>
          {projectTiming && (
            <Box>
              <Box className={styles.classes.header}>
                <Box>
                  <IconButton onClick={handleOnOpenLogs}>
                    <LogsIcon />
                  </IconButton>
                  <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                    <Button
                      canOpenUpgrade
                      startIcon={<EditIcon />}
                      label="Edit"
                      btnType="primary"
                      onClick={handleOpenEditProjectTimingModal}
                    />
                  </Protect>
                </Box>
              </Box>
              <Box>
                {timingChartData && (
                  <Box className={styles.classes.chartContainer}>
                    <ChartWrapper title="Timing">
                      <Box>
                        <CheckboxInput
                          checked={showOperationPeriod}
                          label="Show Operations Period"
                          onChange={handleShowOperationPeriodToggle}
                        />
                        <TimingChart {...timingChartData} />
                      </Box>
                    </ChartWrapper>
                  </Box>
                )}

                <Box className={styles.classes.content}>
                  <Box className={styles.classes.tables}>
                    <DetailsCard
                      heading="Evaluation Timing"
                      sections={[
                        {
                          fields: [
                            {
                              label: "Project Evaluation Horizon",
                              value: {
                                text: PROJECT_TIMING_HORIZON[
                                  projectTiming.horizon
                                ],
                              },
                            },
                            {
                              label:
                                projectTiming.horizon === "DS"
                                  ? "Development Start Date (BOL)"
                                  : projectTiming.horizon === "CS"
                                    ? "Notice to Proceed Date (NTP)"
                                    : "Commercial Operations Date (COD)",
                              value: {
                                text:
                                  projectTiming.horizon === "DS"
                                    ? projectTiming.start_date
                                    : projectTiming.horizon === "CS"
                                      ? projectTiming.ntp_date
                                      : projectTiming.cod,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                    {projectTiming.horizon !== "OS" && (
                      <DetailsCard
                        heading="Development & Construction Timing"
                        sections={[
                          {
                            fields: [
                              ...(projectTiming.horizon === "DS"
                                ? [
                                    {
                                      label: "Notice to Proceed Date (NTP)",
                                      value: { text: projectTiming.ntp_date },
                                    },
                                  ]
                                : []),
                              {
                                label: "Mechanical Completion Date (MC)",
                                value: {
                                  text: projectTiming.mechanical_completion_date,
                                },
                              },
                              {
                                label: "Substantial Completion Date (SC)",
                                value: {
                                  text: projectTiming.substantial_completion_date,
                                },
                              },
                              {
                                label: "Placed In Service Date (PIS)",
                                value: {
                                  text: projectTiming.placed_in_service_date,
                                },
                              },
                              {
                                label: "Permission to Operate Date (PTO)",
                                value: {
                                  text: projectTiming.permission_to_operate_date,
                                },
                              },
                            ],
                          },
                        ]}
                      />
                    )}
                    <DetailsCard
                      heading="Operations Timing"
                      sections={[
                        {
                          fields: [
                            ...(projectTiming.horizon !== "OS"
                              ? [
                                  {
                                    label: "Commercial Operations Date (COD)",
                                    value: { text: projectTiming.cod },
                                  },
                                ]
                              : []),
                            {
                              label: "Project Operating Life",
                              value: {
                                text:
                                  projectTiming.operating_life_years + " Yrs",
                              },
                            },
                            {
                              label: "Project Dates Periodicity",
                              value: {
                                text: PROJECT_TIMING_PERIODICITY[
                                  projectTiming.periodicity
                                ],
                              },
                            },
                            // hide if monthly, since end of period is assumed
                            ...(projectTiming.periodicity !== "MO"
                              ? [
                                  {
                                    label: "Project Dates Adjustment",
                                    value: {
                                      text: PROJECT_TIMING_ADJUSTMENT[
                                        projectTiming.adjustment
                                      ],
                                    },
                                  },
                                ]
                              : []),
                            {
                              label: "Project End of Life Date (EOL)",
                              value: { text: projectTiming.end_date },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>

                  <Box>
                    <DetailsCard
                      autoHeight
                      heading={`Operations Date Schedule (${projectTiming.num_dates_in_schedule})`}
                      dataPw="date-schedule"
                      sections={[
                        {
                          fields: projectTiming.date_schedule
                            .map((d) => ({
                              label: d,
                              value: { text: "" },
                            }))
                            .slice(0, showAllDates ? undefined : 10),
                        },
                      ]}
                      actionButton={
                        <MuiButtom
                          onClick={toggleShowAllDates}
                          classes={{ root: styles.classes.showMoreDatesBtn }}
                          endIcon={
                            showAllDates ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          }
                        >
                          Show {showAllDates ? "Less" : "More"} Details
                        </MuiButtom>
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <ProjectTimingFormModal
        open={editTimingModalOpen}
        headerLabel="Edit Timing"
        loading={editProjectTimingLoading}
        formErrors={editProjectTimingFormErrors}
        setFormErrors={setEditProjectTimingFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseEditTimingModal}
        onConfirm={handleOnEditProjectTiming}
      />

      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectTimingLogsConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectTimingLogsConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
