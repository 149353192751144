import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogsIcon from "@mui/icons-material/Restore";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate, useParams } from "react-router-dom";
import { Protect } from "@clerk/clerk-react";

import useStyles from "../../details-page-styles";
import DateSchedule from "../../../../../../components/date-schedule";
import Button from "../../../../../../components/button";
import ViewWrapper from "../../../../../../components/view-wrapper";
import DetailsCard from "../../../../../../components/details-card";
import ProjectRevenueContractFormModal from "../../../../../../components/project-revenue-contract-form-modal";
import LogsWrapper from "../../../../../../components/logs-wrapper";
import CurveFieldLabel from "../../../../../../components/curve-field-label";
import Logs from "../../../../../../components/logs";
import { AppContext } from "../../../../../../utils/context/app-context";
import { useAPI, useLogs, useDrawer } from "../../../../../../utils/hooks";
import { numberToUSD } from "../../../../../../utils/helpers";
import {
  PROJECT_MERCHANT_PRICE_INPUT_TYPES,
  PROJECT_REVENUE_CONTRACT_TYPE,
  PROJECT_REVENUE_FORM_DEFAULT_STATE,
  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES,
  TERM_END_POINT_TYPES_OPTIONS,
  USER_PERMISSIONS,
} from "../../../../../../constants";
import {
  IProjectRevenueForm,
  IProjectRevenueFormErrors,
  IProjectRevenue,
  IGetCurvesParams,
  IOrganizationCurve,
  IProjectTiming,
  ISelectOption,
  ServerPaginatedResponse,
  ILogsConfiguration,
} from "../../../../../../interfaces";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getProjectRevenueContractDetails: (
    projectId: number,
    revenueId: number,
  ) => Promise<IProjectRevenue>;
  editProjectRevenueContract: (
    projectId: number,
    revenueContractId: number,
    form: IProjectRevenueForm,
  ) => Promise<IProjectRevenue>;
  deleteProjectRevenueContract: (
    projectId: number,
    revenueContractId: number,
  ) => Promise<boolean>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function ProjectRevenueContractDetails({
  getProjectTiming,
  getProjectRevenueContractDetails,
  editProjectRevenueContract,
  deleteProjectRevenueContract,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();
  const navigate = useNavigate();

  const { projectId, revenueId } = useParams();

  const {
    setConfirmDeleteModalProps,
    setCurrentOrganizationCurves,
    currentOrganizationCurves,
    currentProject,
  } = React.useContext(AppContext);
  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [priceCurveToggle, setPriceCurveToggle] =
    React.useState<boolean>(false);
  const [revenueCurveToggle, setRevenueCurveToggle] =
    React.useState<boolean>(false);
  const [basisCurveToggle, setBasisCurveToggle] =
    React.useState<boolean>(false);
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [editRevenueContractModalOpen, setEditRevenueContractModalOpen] =
    React.useState<boolean>(false);
  const [revenue, setRevenue] = React.useState<IProjectRevenue | null>(null);
  const [form, setForm] = React.useState<IProjectRevenueForm>(
    PROJECT_REVENUE_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getProjectRevenueDetailsCallAPI(Number(projectId), Number(revenueId)).then(
      (response) => {
        response && setRevenue(response);
      },
    );
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setProjectTiming(response[0]);
    });
  }, [projectId, revenueId]);

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: getProjectRevenueDetailsCallAPI,
    errored: getRevenueFailed,
    loading: loadingRevenue,
  } = useAPI(
    (projectId: number, revenueId: number) =>
      getProjectRevenueContractDetails(projectId, revenueId),
    { initialLoading: true },
  );

  const {
    callAPI: editProjectRevenueContractCallAPI,
    fieldErrors: editProjectRevenueContractFormErrors,
    setFieldErrors: setEditProjectRevenueContractFormErrors,
    loading: editProjectRevenueContractLoading,
  } = useAPI<IProjectRevenue, IProjectRevenueFormErrors>(
    (projectId: number, revenueId: number, form: IProjectRevenueForm) =>
      editProjectRevenueContract(projectId, revenueId, form),
  );

  const { callAPI: deleteProjectRevenueContractCallAPI } = useAPI(
    (projectId: number, revenueId: number) =>
      deleteProjectRevenueContract(projectId, revenueId),
    { setConfirmModalLoading: true },
  );

  const handleDelete = async () => {
    const deleted = await deleteProjectRevenueContractCallAPI(
      Number(projectId),
      Number(revenueId),
    );

    if (deleted) {
      navigate(`/project/${projectId}/pro-forma/revenue`);
    }
  };

  const handleEditRevenueContract = async (form: IProjectRevenueForm) => {
    const revenue = await editProjectRevenueContractCallAPI(
      Number(projectId),
      Number(revenueId),
      form,
    );
    revenue && setRevenue(revenue);

    return revenue;
  };

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: "PC" }).catch(() => null);
    curves && setCurrentOrganizationCurves(curves.results);
  };

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleEditClick = async () => {
    await handleGetCurves();
    const { id, created, modified, project, created_by, ...formDetails } =
      revenue!;
    setForm({
      ...formDetails,
      base_year:
        formDetails.base_year || getDefaultBaseYear(projectTiming?.cod),
    });
    setEditRevenueContractModalOpen(true);
  };

  const handleCloseEditRevenueContractModal = () => {
    setEditRevenueContractModalOpen(false);
  };

  const handleOnDeleteClick = () => {
    setConfirmDeleteModalProps({
      open: true,
      title: "Delete Revenue",
      description: "Are you sure you want to delete this revenue?",
      onConfirm: handleDelete,
    });
  };

  const organizationCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrganizationCurves?.map((c) => {
      return {
        label: c.name,
        value: String(c.id),
      };
    });
  }, [currentOrganizationCurves]);

  const gotoCurveDetailPage = (url: string) => {
    window.open(url);
  };

  const projectRevenueLogConfiguration: ILogsConfiguration = {
    id: Number(revenue?.id),
    type: "projectrevenuecontract",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectRevenueLogConfiguration.type,
      projectRevenueLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingRevenue} error={getRevenueFailed}>
        {revenue ? (
          <>
            <Box>
              <Box className={styles.classes.header}>
                <IconButton
                  data-pw="go-back-btn"
                  classes={{ root: styles.classes.iconButton }}
                  onClick={() => window.history.back()}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Box className={styles.classes.buttonsContainer}>
                  <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                    <IconButton onClick={handleOnOpenLogs}>
                      <LogsIcon />
                    </IconButton>
                    <Button
                      canOpenUpgrade
                      startIcon={<EditIcon />}
                      label="Edit"
                      btnType="primary"
                      onClick={handleEditClick}
                    />
                    <Button
                      canOpenUpgrade
                      label="Delete"
                      btnType="danger"
                      onClick={handleOnDeleteClick}
                    />
                  </Protect>
                </Box>
              </Box>
              <Box className={styles.classes.content}>
                <DetailsCard
                  heading=""
                  autoHeight
                  sections={[
                    {
                      fields: [
                        {
                          label: "Type",
                          value: {
                            text:
                              revenue.type === "CAPACITY" &&
                              currentProject?.type === "BAST"
                                ? "Tolling Agreement"
                                : PROJECT_REVENUE_CONTRACT_TYPE[revenue.type],
                          },
                        },
                        {
                          label: "Name",
                          value: { text: revenue.name || "" },
                        },
                        ...(revenue.type !== "OTHER"
                          ? [
                              {
                                label: "Start Date",
                                value: { text: revenue.start_date },
                              },
                              revenue.term_input_method === "YR"
                                ? {
                                    label: "Term",
                                    value: {
                                      text: revenue.term_years + " Yrs",
                                    },
                                  }
                                : {
                                    label: "Term End Point",
                                    value: {
                                      text: TERM_END_POINT_TYPES_OPTIONS.find(
                                        (option) =>
                                          option.value ===
                                          revenue.term_end_point,
                                      )?.label,
                                    },
                                  },
                              {
                                label: "End Date",
                                value: { text: revenue.end_date },
                              },
                              {
                                label: "Price Input Type",
                                value: {
                                  text:
                                    revenue.price_input_type &&
                                    PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                      revenue.price_input_type
                                    ],
                                },
                              },
                              ...(revenue.price !== null &&
                              revenue.price_input_type &&
                              PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                revenue.price_input_type
                              ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED
                                ? [
                                    {
                                      label:
                                        PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED,
                                      value: {
                                        text:
                                          numberToUSD.format(revenue.price) +
                                          (revenue.type === "CAPACITY"
                                            ? " / kW-Month"
                                            : " / MWh"),
                                      },
                                    },
                                  ]
                                : []),
                              ...(revenue.price_input_type &&
                              PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                revenue.price_input_type
                              ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.CURVE
                                ? [
                                    {
                                      label: (
                                        <CurveFieldLabel
                                          label="Price Curve"
                                          primaryAction={setPriceCurveToggle}
                                          value={revenue.price_curve}
                                          toggled={priceCurveToggle}
                                        />
                                      ),
                                      value: {
                                        text: (
                                          <DateSchedule
                                            curve={revenue.price_curve || []}
                                            dateSchedule={
                                              projectTiming?.date_schedule || []
                                            }
                                            inUSD
                                            toggle={priceCurveToggle}
                                            handleToggle={setPriceCurveToggle}
                                            endAdornment={`${
                                              revenue.type === "CAPACITY"
                                                ? " /kW-Month"
                                                : " /MWh"
                                            }`}
                                          />
                                        ),
                                      },
                                    },
                                  ]
                                : []),
                              ...(revenue.price_input_type &&
                              PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                revenue.price_input_type
                              ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.OC
                                ? [
                                    {
                                      label: "Organization Curve Name",
                                      value: {
                                        text: (
                                          <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() =>
                                              gotoCurveDetailPage(
                                                `/configuration/data/curves/${revenue.org_curve_detail?.curve_group}/${revenue.org_curve_detail?.id}`,
                                              )
                                            }
                                          >
                                            {revenue?.org_curve_detail?.name}
                                            <OpenInNewIcon fontSize="small" />
                                          </Link>
                                        ),
                                      },
                                    },
                                    {
                                      label: "Curve Start Date",
                                      value: {
                                        text:
                                          revenue?.org_curve_detail
                                            ?.start_date ?? "N/A",
                                      },
                                    },
                                    {
                                      label: "Curve Term Years",
                                      value: {
                                        text:
                                          revenue?.org_curve_detail?.term_years.toString() +
                                          " Yrs",
                                      },
                                    },
                                  ]
                                : []),
                              {
                                label: "Escalator",
                                value: { text: revenue.escalator + "%" },
                              },
                              {
                                label: "Escalator Base Year",
                                value: { text: revenue.base_year },
                              },
                              {
                                label: "Volume %",
                                value: {
                                  text: revenue.volume_percentage + " %",
                                },
                              },
                              {
                                label: "Volume (AC)",
                                value: { text: revenue.volume + " MW" },
                              },
                              // show Capacity / RECs only if PPA / VPPA Hedge
                              ...(revenue.type === "PPA" ||
                              revenue.type === "VPPA" ||
                              revenue.type === "HEDGE"
                                ? [
                                    {
                                      label: "Includes Capacity",
                                      value: {
                                        text: revenue.includes_capacity
                                          ? "Yes"
                                          : "No",
                                      },
                                    },
                                    {
                                      label: "Includes RECs",
                                      value: {
                                        text: revenue.includes_recs
                                          ? "Yes"
                                          : "No",
                                      },
                                    },
                                  ]
                                : []),
                              {
                                label: "Cash Lag (Months)",
                                value: {
                                  text:
                                    revenue.cash_basis_lag !== null
                                      ? revenue.cash_basis_lag
                                      : "N/A",
                                },
                              },
                              ...(revenue.type === "VPPA" ||
                              revenue.type === "HEDGE"
                                ? [
                                    {
                                      label: "Basis Differential Method",
                                      value: {
                                        text:
                                          revenue.differential_method &&
                                          PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES[
                                            revenue.differential_method
                                          ],
                                      },
                                    },
                                    ...(revenue.differential_method &&
                                    PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES[
                                      revenue.differential_method
                                    ] ===
                                      PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES.BDP
                                      ? [
                                          {
                                            label: "Basis Differential",
                                            value: {
                                              text:
                                                "$" +
                                                revenue.basis_differential +
                                                " / MWh",
                                            },
                                          },
                                        ]
                                      : []),
                                    ...(revenue.differential_method &&
                                    PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES[
                                      revenue.differential_method
                                    ] ===
                                      PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES.BDC
                                      ? [
                                          {
                                            label: (
                                              <CurveFieldLabel
                                                label="Basis Curve"
                                                primaryAction={
                                                  setBasisCurveToggle
                                                }
                                                value={revenue.basis_curve}
                                                toggled={basisCurveToggle}
                                              />
                                            ),
                                            value: {
                                              text: (
                                                <DateSchedule
                                                  curve={
                                                    revenue.basis_curve || []
                                                  }
                                                  dateSchedule={
                                                    projectTiming?.date_schedule ||
                                                    []
                                                  }
                                                  inUSD
                                                  toggle={basisCurveToggle}
                                                  handleToggle={
                                                    setBasisCurveToggle
                                                  }
                                                  endAdornment=" /MWh"
                                                />
                                              ),
                                            },
                                          },
                                        ]
                                      : []),
                                  ]
                                : []),
                            ]
                          : [
                              {
                                label: (
                                  <CurveFieldLabel
                                    label="Revenue Schedule"
                                    primaryAction={setRevenueCurveToggle}
                                    value={revenue.basis_curve}
                                    toggled={revenueCurveToggle}
                                  />
                                ),
                                value: {
                                  text: (
                                    <DateSchedule
                                      curve={revenue.revenue_curve || []}
                                      dateSchedule={
                                        projectTiming?.date_schedule || []
                                      }
                                      inUSD
                                      showTotal={{
                                        total: revenue.schedule_total,
                                      }}
                                      toggle={revenueCurveToggle}
                                      handleToggle={setRevenueCurveToggle}
                                    />
                                  ),
                                },
                              },
                            ]),
                      ],
                    },
                  ]}
                />
              </Box>
            </Box>
          </>
        ) : null}
      </ViewWrapper>

      <ProjectRevenueContractFormModal
        headerLabel="Edit Revenue"
        open={editRevenueContractModalOpen}
        loading={editProjectRevenueContractLoading}
        dateSchedule={projectTiming?.date_schedule || []}
        formErrors={editProjectRevenueContractFormErrors}
        setFormErrors={setEditProjectRevenueContractFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseEditRevenueContractModal}
        onConfirm={handleEditRevenueContract}
        org_curves={organizationCurvesOptions}
      />

      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectRevenueLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectRevenueLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
