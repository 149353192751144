export default function CapeZeroLogoDark(): JSX.Element {
  return (
    <svg
      width="111"
      height="23"
      viewBox="0 0 111 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3104_41249)">
        <g clipPath="url(#clip1_3104_41249)">
          <path
            d="M35.1878 5.16802L34.832 5.05427C34.2121 4.78786 33.674 4.61724 33.2192 4.54091C32.7629 4.46458 32.1703 4.42716 31.4413 4.42716C30.3838 4.42716 29.4641 4.66514 28.6806 5.13958C27.8972 5.61552 27.2902 6.27107 26.8626 7.10771C26.435 7.94435 26.2198 8.87528 26.2198 9.902C26.2198 11.6127 26.7206 12.9672 27.7236 13.9655C28.7265 14.9637 30.0925 15.4621 31.8245 15.4621C32.6266 15.4621 33.7113 15.2152 35.0774 14.7213L35.1864 14.6644V17.2881C34.4761 17.5545 33.8233 17.7446 33.2321 17.8583C32.6395 17.9721 32.0153 18.0289 31.3596 18.0289C29.7554 18.0289 28.3606 17.6682 27.1769 16.9453C25.9916 16.2225 25.0805 15.2481 24.4434 14.0223C23.8049 12.7966 23.4863 11.4316 23.4863 9.93043C23.4863 8.42927 23.8149 7.04036 24.4707 5.82356C25.1451 4.60677 26.0921 3.65638 27.3132 2.9724C28.5343 2.28842 29.929 1.94568 31.4959 1.94568C32.8447 1.94568 34.0744 2.17317 35.1864 2.62966V5.16802H35.1878Z"
            fill="white"
          />
          <path
            d="M36.9658 7.22148C37.4393 7.05086 38.0362 6.91316 38.7565 6.8084C39.4769 6.70363 40.0738 6.65125 40.5473 6.65125C42.2419 6.65125 43.4587 6.97902 44.1976 7.63456C44.9352 8.2901 45.3054 9.36022 45.3054 10.8434V17.773H42.9536L42.9263 16.8032C42.1056 17.4872 41.0854 17.8299 39.8643 17.8299C38.7336 17.8299 37.831 17.5306 37.1581 16.9319C36.4837 16.3332 36.1465 15.549 36.1465 14.5791C36.1465 13.515 36.5239 12.6828 37.2815 12.0842C38.0377 11.4855 39.1181 11.1473 40.5215 11.0724L42.5719 10.9587C42.5719 10.2552 42.5073 9.77031 42.3811 9.50391C42.2534 9.20008 42.0252 8.99055 41.6981 8.8768C41.3695 8.76305 40.85 8.70618 40.1398 8.70618C39.6663 8.70618 39.1138 8.75407 38.4853 8.84836C37.8569 8.94415 37.3504 9.06688 36.9672 9.21954V7.22297L36.9658 7.22148ZM42.6795 12.8101L40.8199 12.9522C40.1096 13.0091 39.5902 13.1513 39.2616 13.3803C38.933 13.6078 38.7695 13.9415 38.7695 14.3786C38.7695 14.8156 38.9244 15.1583 39.2344 15.4053C39.5443 15.6522 39.9719 15.7765 40.52 15.7765C40.9577 15.7765 41.3164 15.7391 41.6005 15.6627C41.8832 15.5864 42.2433 15.4158 42.681 15.1494V12.8116L42.6795 12.8101Z"
            fill="white"
          />
          <path
            d="M48.5297 6.8219L48.5024 7.70643C49.5054 7.11674 50.5615 6.8219 51.6735 6.8219C52.4756 6.8219 53.2677 7.01198 54.0526 7.39213C54.8906 7.8112 55.5377 8.45178 55.994 9.31686C56.4488 10.1819 56.677 11.2416 56.677 12.4958C56.677 14.1122 56.1992 15.3904 55.2421 16.3318C54.285 17.2732 52.9779 17.7431 51.3191 17.7431C50.936 17.7431 50.5845 17.6997 50.2659 17.6144C49.9474 17.5291 49.5327 17.3824 49.0219 17.1729V23.646H46.3975V6.8219H48.5297ZM50.936 8.98908C50.3348 8.98908 49.7063 9.12229 49.0491 9.3887V15.0641C49.5585 15.4248 50.1612 15.6059 50.8528 15.6059C51.8916 15.6059 52.6708 15.3455 53.1902 14.8216C53.7096 14.2993 53.9694 13.4761 53.9694 12.3551C53.9694 11.1772 53.7283 10.3211 53.2447 9.78831C52.7612 9.25699 51.9907 8.98908 50.9346 8.98908H50.936Z"
            fill="white"
          />
          <path
            d="M66.82 17.6308C65.8357 17.9167 64.6691 18.0589 63.3203 18.0589C61.5339 18.0589 60.1162 17.541 59.0687 16.5053C58.0198 15.4696 57.4961 14.0867 57.4961 12.3565C57.4961 11.2161 57.7242 10.2088 58.1791 9.33325C58.634 8.47716 59.2266 7.81712 59.9555 7.35166C60.6844 6.88619 61.4865 6.65271 62.3618 6.65271C63.9287 6.65271 65.1412 7.15709 65.9978 8.16435C66.8544 9.17161 67.282 10.5695 67.282 12.3565V13.4117H60.1463C60.2927 15.0655 61.4679 15.8932 63.6733 15.8932C65.0579 15.8932 66.1068 15.7989 66.8171 15.6073V17.6323L66.82 17.6308ZM62.4178 8.78996C61.8165 8.78996 61.3057 9.0414 60.8867 9.54578C60.4677 10.0502 60.2396 10.7012 60.2037 11.4989H64.6877C64.6877 10.6428 64.4869 9.97832 64.0865 9.50238C63.6848 9.02793 63.1295 8.78996 62.4192 8.78996H62.4178Z"
            fill="white"
          />
          <path
            d="M69.8544 2.08789H80.9819V3.77015L72.9437 15.2347H81.0365V17.773H69.335V16.0339L77.1537 4.59931H69.8544V2.08789Z"
            fill="white"
          />
          <path
            d="M91.0426 17.6308C90.0583 17.9167 88.8917 18.0589 87.543 18.0589C85.7565 18.0589 84.3388 17.541 83.2914 16.5053C82.2425 15.4696 81.7188 14.0867 81.7188 12.3565C81.7188 11.2161 81.9469 10.2088 82.4018 9.33325C82.8566 8.47716 83.4492 7.81712 84.1781 7.35166C84.9071 6.88619 85.7092 6.65271 86.5844 6.65271C88.1513 6.65271 89.3638 7.15709 90.2205 8.16435C91.0771 9.17161 91.5047 10.5695 91.5047 12.3565V13.4117H84.369C84.5153 15.0655 85.6905 15.8932 87.8959 15.8932C89.2806 15.8932 90.3295 15.7989 91.0398 15.6073V17.6323L91.0426 17.6308ZM86.6404 8.78996C86.0392 8.78996 85.5284 9.0414 85.1094 9.54578C84.6904 10.0502 84.4623 10.7012 84.4264 11.4989H88.9104C88.9104 10.6428 88.7095 9.97832 88.3092 9.50238C87.9074 9.02793 87.3521 8.78996 86.6418 8.78996H86.6404Z"
            fill="white"
          />
          <path
            d="M92.6001 17.7731H95.1973V9.44557C95.7985 9.23603 96.3724 9.13127 96.9191 9.13127C97.7399 9.13127 98.4501 9.30189 99.0514 9.64463V6.96408C98.5951 6.86979 98.2033 6.8219 97.8762 6.8219H97.6308C96.3007 6.8219 95.1241 7.0449 94.1039 7.49241C93.0837 7.93991 92.5815 8.65682 92.6001 9.64463V17.7716V17.7731Z"
            fill="white"
          />
          <path
            d="M105.586 18.029C104.51 18.029 103.571 17.782 102.769 17.2881C101.948 16.7942 101.316 16.1192 100.869 15.2631C100.423 14.407 100.199 13.4387 100.199 12.3536C100.199 10.586 100.691 9.1926 101.676 8.17636C102.66 7.16011 103.972 6.65125 105.613 6.65125C107.255 6.65125 108.562 7.1646 109.536 8.19132C110.51 9.21804 110.998 10.5965 110.998 12.3266C110.998 13.4491 110.78 14.4369 110.342 15.293C109.886 16.1491 109.249 16.8182 108.428 17.3031C107.608 17.788 106.661 18.0305 105.584 18.0305L105.586 18.029ZM105.586 8.78999C104.802 8.78999 104.16 9.11776 103.659 9.77331C103.157 10.4289 102.907 11.2894 102.907 12.3536C102.907 13.4177 103.157 14.2693 103.659 14.9054C104.16 15.543 104.802 15.8603 105.586 15.8603C106.369 15.8603 107.062 15.546 107.554 14.9189C108.047 14.2918 108.292 13.4357 108.292 12.3521C108.292 11.2685 108.037 10.4274 107.527 9.77181C107.016 9.11627 106.369 8.7885 105.586 8.7885V8.78999Z"
            fill="white"
          />
          <path
            d="M3.85124 0C1.72473 0 0 1.58198 0 3.53215V18.0693H15.8469C17.9734 18.0693 19.6981 16.4874 19.6981 14.5372V0H3.85124Z"
            fill="#1DED85"
          />
          <path
            d="M8.87007 7.82462L10.4714 6.01514H4.27986C3.35293 6.01514 2.59961 6.79939 2.59961 7.76774V14.8949L8.86863 7.82462H8.87007Z"
            fill="url(#paint0_linear_3104_41249)"
          />
          <path
            d="M15.739 4.70853L17.0964 3.17444H10.9049C9.97793 3.17444 9.22461 3.9587 9.22461 4.92704V12.0482L15.739 4.70853Z"
            fill="#212121"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3104_41249"
          x1="7.64467"
          y1="11.1218"
          x2="2.16308"
          y2="8.08767"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stopColor="#1DED85" />
          <stop offset="0.08" stopColor="#1CCE79" />
          <stop offset="0.16" stopColor="#1B9965" />
          <stop offset="0.24" stopColor="#1A6D54" />
          <stop offset="0.32" stopColor="#194B47" />
          <stop offset="0.4" stopColor="#19323E" />
          <stop offset="0.47" stopColor="#192438" />
          <stop offset="0.52" stopColor="#191F37" />
        </linearGradient>
        <clipPath id="clip0_3104_41249">
          <rect width="111" height="22.6973" fill="white" />
        </clipPath>
        <clipPath id="clip1_3104_41249">
          <rect width="111" height="22.7244" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
