import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiButton from "@mui/material/Button";
import { useUser } from "@clerk/clerk-react";
import { useLocation, useParams } from "react-router-dom";

import useStyles from "./styles";
import Spinner from "../spinner";
import { AppContext } from "../../utils/context/app-context";
import { cn } from "../../utils/helpers";
import {
  OutputReportPeriodicityType,
  ReportPerspective,
  ReportTerm,
  ReportType,
} from "../../interfaces";

const PERSPECTIVE_MAP = {
  "sponsor-equity": "sponsorequity",
  partnership: "project",
  "tax-equity": "taxequity",
  debt: "debt",
  transfer: "transfer",
};

interface IProps {
  downloadABCInputs: (dealId: number) => Promise<void>;
  downloadDealReport: (dealId: number) => Promise<void>;
  downloadDealReportSet: (dealId: number) => Promise<void>;
  downloadUserDealReport: (
    dealId: number,
    reportPerspective?: ReportPerspective,
    reportType?: ReportType,
    reportTerm?: ReportTerm,
    periodicity?: OutputReportPeriodicityType,
  ) => Promise<void>;
}

export default function DownloadDealReportsButton({
  downloadABCInputs,
  downloadDealReport,
  downloadDealReportSet,
  downloadUserDealReport,
}: IProps): JSX.Element {
  const { user } = useUser();

  const styles = useStyles();

  const { pathname } = useLocation();
  const { dealId, caseDealId } = useParams();

  const { currentDeal, dealOutputLoaded } = React.useContext(AppContext);

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const toggleDownloadMenu = (e: React.MouseEvent<HTMLElement>) => {
    setDownloadMenuAnchorEl((prevState) =>
      prevState ? null : e.currentTarget,
    );
  };

  const selectedSidebarItem = React.useMemo(
    () => pathname.split("/")[6] || "",
    [pathname],
  );

  const checkIfInternalUser = (): boolean => {
    // Regular expression to match Gmail domain
    const capezeroEmailRegex = /@capezero\.com$/i;
    // Test if the email address matches the Gmail domain
    return capezeroEmailRegex.test(
      user?.primaryEmailAddress?.emailAddress.toString() || "",
    );
  };

  const handleDownloadAllReport = async () => {
    // close download menu
    setDownloadMenuAnchorEl(null);
    setIsDownloading(true);
    const currentPeriodicity = (
      sessionStorage.getItem(`deal-${dealId}-output-periodicity`) ?? "MO"
    ).replaceAll(`"`, "") as OutputReportPeriodicityType;

    await downloadUserDealReport(
      Number(caseDealId),
      undefined,
      undefined,
      undefined,
      currentPeriodicity,
    ).catch(() => null);
    setIsDownloading(false);
  };

  const handleDownloadABC_ReportReportSet = async () => {
    // close download menu
    setDownloadMenuAnchorEl(null);
    setIsDownloading(true);
    await downloadDealReportSet(Number(caseDealId)).catch(() => null);
    setIsDownloading(false);
  };

  const handleDownloadReportSets = async () => {
    // close download menu
    setDownloadMenuAnchorEl(null);
    setIsDownloading(true);
    const perspectiveKey = selectedSidebarItem as keyof typeof PERSPECTIVE_MAP;
    const perspectiveValue = PERSPECTIVE_MAP[
      perspectiveKey
    ] as ReportPerspective;

    const currentPeriodicity = (
      sessionStorage.getItem(`deal-${dealId}-output-periodicity`) ?? "MO"
    ).replaceAll(`"`, "") as OutputReportPeriodicityType;

    await downloadUserDealReport(
      Number(caseDealId),
      perspectiveValue,
      undefined,
      undefined,
      currentPeriodicity,
    ).catch(() => null);
    setIsDownloading(false);
  };

  const handleDownloadABC_APIReport = async () => {
    // close download menu
    setDownloadMenuAnchorEl(null);
    setIsDownloading(true);
    await downloadDealReport(Number(caseDealId)).catch(() => null);
    setIsDownloading(false);
  };

  const handleDownloadABCInputs = async () => {
    // close download menu
    setDownloadMenuAnchorEl(null);
    setIsDownloading(true);
    await downloadABCInputs(Number(caseDealId)).catch(() => null);
    setIsDownloading(false);
  };

  return (
    <Box>
      <MuiButton
        startIcon={<DownloadIcon />}
        endIcon={<ExpandMoreIcon />}
        disabled={!dealOutputLoaded || isDownloading}
        classes={{
          root: styles.classes.downloadBtn,
          disabled: styles.classes.disabledDownloadBtn,
        }}
        onClick={toggleDownloadMenu}
      >
        Download
        {isDownloading && (
          <Box className={cn("ml-2")}>
            <Spinner />
          </Box>
        )}
        <Divider orientation="vertical" flexItem />
      </MuiButton>
      <Menu
        anchorEl={downloadMenuAnchorEl}
        open={Boolean(downloadMenuAnchorEl)}
        onClose={toggleDownloadMenu}
        classes={{ paper: styles.classes.downloadMenu }}
      >
        <MenuItem onClick={handleDownloadAllReport}>All Reports</MenuItem>
        {currentDeal?.organization_has_abc_license && (
          <MenuItem onClick={handleDownloadABC_ReportReportSet}>
            All Reports (Formulas)
          </MenuItem>
        )}
        <MenuItem onClick={handleDownloadReportSets}>Report Sets</MenuItem>
        {checkIfInternalUser() && (
          <MenuItem onClick={handleDownloadABC_APIReport}>
            ABC API Reports
          </MenuItem>
        )}
        {checkIfInternalUser() && process.env.REACT_APP_NODE_ENV !== "prod" && (
          <MenuItem onClick={handleDownloadABCInputs}>Inputs</MenuItem>
        )}
      </Menu>
    </Box>
  );
}
