import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import useStyles from "./styles";
import Chart from "./chart";
import Tabs from "../../../../components/tabs";
import Menu from "../../../../components/menu";
import ViewWrapper from "../../../../components/view-wrapper";
import ProformaTableRow from "../../../../components/proforma-table-row";
import { cn } from "../../../../utils/helpers";
import { downloadProjectProforma } from "../../../../apis/project/proforma";
import { useAPI, useSessionStorage } from "../../../../utils/hooks";
import { AppContext } from "../../../../utils/context/app-context";
import {
  PROJECT_TIMING_PERIODICITY_OPTIONS,
  PROJECT_TIMING_PERIODICITY,
  PROFORMA_TABLE_CONFIG,
  PROFORMA_DETAIL_COLUMNS,
  PROFORMA_SUMARY_COLUMNS,
  PROFORMA_DEFAULT_STATE,
  PROFORMA_BASIS,
  PROFORMA_BASIS_OPTIONS,
} from "../../../../constants";
import {
  ITab,
  IProjectTiming,
  IProjectProforma,
  ProformaReportType,
  IProjectProformaResponse,
} from "../../../../interfaces";

interface IProps {
  getProjectProformaDetails: (
    id: number,
    periodicity: ProformaReportType,
    cash_basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[] | null>;
}

export default function ProjectProFormaView({
  getProjectProformaDetails,
  getProjectTiming,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();

  const [headerRowHeight, setHeaderRowHeight] = React.useState<number>(0);
  const { currentProject } = React.useContext(AppContext);
  const [selectedPeriodicity, setSelectedPeriodicity] = useSessionStorage<
    ProformaReportType | ""
  >(`project-periodicity-${projectId}`, "");
  const [selectedBasis, setSelectedBasis] = useSessionStorage<
    "cash" | "accrual"
  >(`project-selected-basis-${projectId}`, "cash");
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [selectedTab, setSelectedTab] = React.useState<
    "proforma_summary" | "proforma_detail"
  >("proforma_summary");

  const getProformaColumnsForProjectType = (
    selected_tab: string,
    projectType?: string,
  ): string[] => {
    // Hide production columns for Battery Storage projects and capacity columns for all other projects
    if (selected_tab === "proforma_summary") {
      const hideCols =
        projectType === "BAST" ? ["net_production"] : ["net_capacity"];
      const otherFlowColumnsToHide =
        selectedBasis === "cash"
          ? ["other_flow_tax_total"]
          : ["other_flow_cash_total"];
      hideCols.push(...otherFlowColumnsToHide);

      if (projectProforma.is_ebitda_overridden) {
        hideCols.push("revenue_total", "expenses");
        for (const column of PROFORMA_SUMARY_COLUMNS) {
          if (
            column.startsWith("expense_type_") ||
            column.startsWith("contracted_revenue_") ||
            column.startsWith("merchant_revenue_")
          ) {
            hideCols.push(column);
          }
        }
      }
      return PROFORMA_SUMARY_COLUMNS.filter(
        (column) => !hideCols.includes(column),
      );
    } else if (selected_tab === "proforma_detail") {
      const hideCols =
        projectType === "BAST"
          ? ["gross_production", "net_production", "production_lossees"]
          : ["gross_capacity", "net_capacity"];

      const otherFlowColumnsToHide =
        selectedBasis === "cash"
          ? ["other_flow_tax_total"]
          : ["other_flow_cash_total"];

      hideCols.push(...otherFlowColumnsToHide);

      if (projectProforma.is_ebitda_overridden) {
        hideCols.push("revenue_total", "expenses");
        for (const column of PROFORMA_DETAIL_COLUMNS) {
          if (
            column.startsWith("expense_type_") ||
            column.startsWith("contracted_revenue_") ||
            column.startsWith("merchant_revenue_")
          ) {
            hideCols.push(column);
          }
        }
      }

      return PROFORMA_DETAIL_COLUMNS.filter(
        (column) => !hideCols.includes(column),
      );
    }
    return [];
  };

  const [projectProforma, setProjectProforma] =
    React.useState<IProjectProforma>(PROFORMA_DEFAULT_STATE);

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get("periodicity") as ProformaReportType;
      setSelectedPeriodicity(periodicity);
    }
  }, []);

  React.useEffect(() => {
    handleGetProjectTiming(Number(projectId));
  }, [projectId]);

  React.useEffect(() => {
    if (selectedPeriodicity) {
      getProjectProformaDetailsCallAPI(selectedPeriodicity, selectedBasis).then(
        (response) => {
          response && setProjectProforma(response.data);
        },
      );
    }
  }, [selectedPeriodicity, selectedBasis]);

  const handleGetProjectTiming = async (projectId: number) => {
    const response = await getProjectTiming(projectId).catch(() => null);
    if (!selectedPeriodicity && response) {
      setSelectedPeriodicity(response[0].periodicity);
      navigate(`${location.pathname}?periodicity=${response[0].periodicity}`, {
        replace: true,
      });
    }
  };

  const handleDownloadProjectProforma = async () => {
    if (selectedPeriodicity && projectId) {
      setIsDownloading(true);
      await downloadProjectProforma(
        Number(projectId),
        selectedPeriodicity,
        selectedBasis,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const {
    callAPI: getProjectProformaDetailsCallAPI,
    errored: getProFormaFailed,
    loading: loadingProForma,
  } = useAPI(
    (reportType, basis) =>
      getProjectProformaDetails(Number(projectId), reportType, basis),
    { initialLoading: true },
  );

  const handleSwitchTab = (tab: ITab) => {
    setSelectedTab(tab.value as "proforma_summary" | "proforma_detail");
  };

  const handleReportTypeChange = (v: ProformaReportType) => {
    setSelectedPeriodicity(v);
    navigate(`${location.pathname}?periodicity=${v}`, { replace: true });
  };

  const handleBasisChange = (basis: "cash" | "accrual") => {
    setSelectedBasis(basis);
  };

  const handleSetHeaderRwHeight = (ref: HTMLTableRowElement) => {
    ref && setHeaderRowHeight(ref.clientHeight);
  };

  const requiredColumns = getProformaColumnsForProjectType(
    selectedTab,
    currentProject?.type,
  );

  return (
    <>
      <Tabs
        onClick={handleSwitchTab}
        selectedTab={selectedTab}
        tabs={[
          {
            label: "Summary",
            value: "proforma_summary",
          },
          {
            label: "Detail",
            value: "proforma_detail",
          },
        ]}
      />
      <Box className={cn("flex justify-between items-center mt-4")}>
        <Box className={cn("flex gap-4")}>
          <Menu
            selectedItem={
              selectedPeriodicity
                ? PROJECT_TIMING_PERIODICITY[selectedPeriodicity]
                : selectedPeriodicity
            }
            placeholder="Periodicity"
            menuItems={PROJECT_TIMING_PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleReportTypeChange(o.value as ProformaReportType),
            }))}
          />
          <Menu
            selectedItem={
              selectedBasis ? PROFORMA_BASIS[selectedBasis] : selectedBasis
            }
            placeholder="Basis"
            menuItems={PROFORMA_BASIS_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleBasisChange(o.value as keyof typeof PROFORMA_BASIS),
            }))}
          />
        </Box>
        {!loadingProForma && !getProFormaFailed && (
          <IconButton
            title="Download Project Pro Forma"
            onClick={handleDownloadProjectProforma}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </Box>

      <ViewWrapper loading={loadingProForma} error={getProFormaFailed}>
        {projectProforma?.proforma_data.length > 0 ? (
          <Box>
            <Chart
              chartData={projectProforma.proforma_data}
              chartFormat={
                selectedTab === "proforma_summary" ? "summary" : "detail"
              }
              chartElementConfig={PROFORMA_TABLE_CONFIG}
              selectedBasis={selectedBasis}
              hideMwAxis={currentProject?.type === "BAST"}
            />

            <Paper>
              <TableContainer
                classes={{ root: styles.classes.proformaTableContainer }}
              >
                <Table stickyHeader>
                  <TableHead className={styles.classes.header}>
                    <ProformaTableRow
                      key={-2}
                      boldRow
                      headermode
                      ref={handleSetHeaderRwHeight}
                      data={projectProforma.totals}
                      requiredColumns={requiredColumns}
                    />
                  </TableHead>
                  <TableBody>
                    <ProformaTableRow // handles logic of showing the data or not so columns are the same in the table
                      key={-1}
                      boldRow
                      sticky
                      headerHeight={headerRowHeight}
                      data={projectProforma.totals}
                      requiredColumns={requiredColumns}
                    />
                    {projectProforma.proforma_data.map((revenue, idx) => {
                      return (
                        <ProformaTableRow
                          key={idx}
                          data={revenue}
                          requiredColumns={requiredColumns}
                        />
                      );
                    })}
                    <ProformaTableRow
                      key={projectProforma.proforma_data.length + 1}
                      boldRow
                      data={projectProforma.totals}
                      requiredColumns={requiredColumns}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        ) : null}
      </ViewWrapper>
    </>
  );
}
