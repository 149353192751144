import React from "react";
import Box from "@mui/material/Box";
import { isInteger, some } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import ContentWrappedTabs from "../content-wrapped-tabs";
import { AppContext } from "../../utils/context/app-context";
import { getLayoutTabs } from "../../utils/helpers";
import { SIDEBAR_NAVIGATION } from "../../constants";
import { ITab } from "../../interfaces";

interface IProps {
  children: JSX.Element;
  module: "Projects" | "Deals" | "Configuration";
  pages?: "Pro Forma" | "Tax" | "Components" | "Sizing" | "Output" | "Cost";
  withPadding?: boolean;
  hasStickyTabs?: boolean;
}

export default function TabbedLayout({
  children,
  module,
  pages,
  withPadding,
  hasStickyTabs = false,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { projectId, dealId, caseDealId } = useParams();
  const { pathname } = useLocation();

  const {
    currentDeal,
    currentDealDebt,
    casesOfDeal,
    contentLoaded,
    ctrlPressed,
  } = React.useContext(AppContext);

  const styles = useStyles({ contentLoaded, withPadding });

  const tabs: ITab[] = React.useMemo(() => {
    const links = !pages
      ? SIDEBAR_NAVIGATION.find((i) => i.label === module)?.subLinks || []
      : SIDEBAR_NAVIGATION.find((i) => i.label === module)?.subLinks?.find(
          (i) => i.label === pages,
        )?.subLinks || [];

    return getLayoutTabs({
      module,
      currentDeal,
      currentDealDebt,
      links,
    });
  }, [module, pages, caseDealId, casesOfDeal, currentDeal, currentDealDebt]);

  const selectedTab = React.useMemo(() => {
    // url path
    const splitPath = pathname.split("/");

    // For organization pages
    if (pathname.includes("/organization/")) {
      return "organization";
    }
    if (pathname.includes("/data/")) {
      return "data";
    }

    // For deal output pages
    if (pathname.includes("/output/")) {
      // Get the tab value e.g sponsor-equity / tax-equity ...
      // splitPath[6] gives the current tab value from the url
      const t = splitPath[6];
      if (some(tabs, { value: t })) {
        return t;
      } else {
        const firstTab = tabs[0];
        // Replace placeholder with actual value
        const firstTabUrl =
          firstTab?.path
            ?.replace(":dealId", String(dealId))
            ?.replace(":caseDealId", String(caseDealId)) || "";
        // Navigate to the first tab
        navigate(firstTabUrl);
        return firstTab.value;
      }
    }

    // For expense detail page, or revenue detail page, or otherflows detail page
    if (isInteger(Number(splitPath[splitPath.length - 1]))) {
      // when splitPath[splitPath.length - 1] is the id (number)
      return (
        tabs.find(
          (tab) =>
            // Ignore pro-forma because it's included in every expense, revenue detail's URL
            tab.value !== "pro-forma" && pathname.includes(`/${tab.value}/`),
        )?.value || ""
      );
    }

    // For revenue pages
    if (pathname.includes("/merchant") || pathname.includes("ebitda")) {
      return "revenue";
    }

    if (pathname.includes(`/project/${projectId}/cost`)) {
      if (pathname.includes("cost-inputs")) {
        return "cost-inputs";
      } else if (pathname.includes("credit-support")) {
        return "credit-support";
      } else {
        return "cost-summary";
      }
    }

    // select the last element of splitPath as tab value
    return splitPath[splitPath.length - 1];
  }, [tabs, dealId, caseDealId, pathname]);

  const goToPage = (tab: ITab) => {
    let link = tab.path || "";
    // If the tab is already selected then no need to go further just return
    if (selectedTab === tab.value) {
      return;
    }
    // Replace placeholders with actual values
    link = link
      .replace(":projectId", String(projectId))
      .replace(":dealId", String(dealId))
      .replace(":caseDealId", String(caseDealId));

    // If Ctrl key is pressed, open in new tab
    if (ctrlPressed) {
      window.open(link);
      return;
    }
    // Navigate to the updated link
    navigate(link);
  };

  return (
    <Box className={styles.classes.container}>
      <ContentWrappedTabs
        onClick={goToPage}
        tabs={tabs}
        selectedTab={selectedTab}
        hasStickyTabs={hasStickyTabs}
      >
        {children}
      </ContentWrappedTabs>
    </Box>
  );
}
