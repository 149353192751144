import DealPagesLayout from "../../../components/deal-pages-layout";
import DealTaxCredits from "../../../views/deal/components/tax-credits";
import TabbedLayout from "../../../components/tabbed-layout";
import { getTaxCredit } from "../../../apis/deal/tax-credit";

export default function DealTaxCredit(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Components">
        <DealTaxCredits getTaxCredit={getTaxCredit} />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
