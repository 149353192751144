import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IProjectProduction,
  IUpdateProjectProductionForm,
} from "../../interfaces";

export const getProjectProduction = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.get, { projectId });
  return await AxiosHelper.get<IProjectProduction[]>(url);
};

export const updateProjectProduction = async (
  projectId: number,
  productionId: number,
  form: IUpdateProjectProductionForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.production.update, {
    projectId,
    productionId,
  });
  return await AxiosHelper.patch<
    IUpdateProjectProductionForm,
    IProjectProduction
  >(url, form);
};
