import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IUpdateProjectTimingForm,
  IProjectTiming,
  IProjectTimingDateScheduleParams,
  IProjectTimingDateScheduleResults,
} from "../../interfaces";

export const getProjectTiming = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.get, { projectId });
  return await AxiosHelper.get<IProjectTiming[]>(url);
};

export const editProjectTiming = async (
  projectId: number,
  timingId: number,
  form: IUpdateProjectTimingForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.update, {
    projectId,
    timingId,
  });
  return await AxiosHelper.patch<IUpdateProjectTimingForm, IProjectTiming>(
    url,
    form,
  );
};

export const getProjectTimingDateSchedule = async (
  params: IProjectTimingDateScheduleParams,
  projectId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.timing.dateSchedule, {
    projectId,
  });
  return await AxiosHelper.post<
    IProjectTimingDateScheduleParams,
    IProjectTimingDateScheduleResults
  >(url, params);
};
