import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  tableHeader: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  content: {
    marginTop: theme.spacing(3),
    width: "80%",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 260px) : auto",
  },
}));
