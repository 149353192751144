import { IDealSponsorEquityForm } from "../../interfaces";

export const DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE: IDealSponsorEquityForm = {
  pre_tax_discount_rate: null,
  after_tax_discount_rate: null,
  carryforward_credits_and_losses: null,
  federal_tax_rate: null,
  new_federal_tax_rate: null,
  federal_tax_rate_change_year: null,
  change_in_federal_tax_rate: null,
  distribution_periodicity: "",
  is_partnership_deal: false,
};
