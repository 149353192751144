import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowBackIcon from "@mui/icons-material/NavigateBefore";
import { Link, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import Layout from "../layout";
import { PROJECT_TAX_CREDIT_TYPE } from "../../constants";
import { getProjectDetails } from "../../apis/project/base";
import { AppContext } from "../../utils/context/app-context";
import { navigateAndScroll, trimString } from "../../utils/helpers";

interface IProps {
  children: JSX.Element;
}

export default function ProjectPagesLayout({ children }: IProps): JSX.Element {
  const { currentProject, setCurrentProject, contentLoaded } =
    React.useContext(AppContext);

  const styles = useStyles({ contentLoaded });

  const navigate = useNavigate();
  const { projectId } = useParams();

  React.useEffect(() => {
    if (currentProject?.id !== Number(projectId)) {
      handleGetCurrentProject(Number(projectId));
    }
  }, []);

  const handleGetCurrentProject = async (projectId: number) => {
    const response = await getProjectDetails(projectId).catch(() => null);
    if (response) {
      // If the deal is a case deal, navigate to the parent deal > cases
      if (
        response?.deals &&
        response?.deals?.length > 0 &&
        response.deals[0]?.parent_deal_id
      ) {
        const deal = response.deals[0];
        navigate(`/deal/${deal.parent_deal_id}/case/${deal.case_id}`);
      }
      setCurrentProject(response);
    }
  };

  const onDealChipClick = (id: number) => {
    window.open(`/deal/${id}/case-deal/${id}/general`, "_blank");
  };

  const handleNavigate = (url: string, elementId: string) => {
    navigateAndScroll(() => navigate(url), elementId);
  };

  return (
    <Layout title={currentProject?.name}>
      <>
        {currentProject && (
          <Box className={styles.classes.stickyHeader}>
            <Box className={styles.classes.infoSection}>
              <Link to="/project-list" className={styles.classes.backButton}>
                <ArrowBackIcon />
                <Typography>Back to Projects List</Typography>
              </Link>
              <Typography variant="h5" component="h5" marginRight={3}>
                {trimString(currentProject?.name, 45)}
              </Typography>
              <Box className={styles.classes.chipsContainer}>
                <Box>
                  <Chip label={currentProject?.sponsor_name} />
                  <Chip label={currentProject?.capacity_ac + " MW (AC)"} />
                  <Chip label={currentProject?.capacity_dc + " MW (DC)"} />
                  <Chip label={"BOL: " + currentProject?.bol} />
                  <Chip label={"NTP: " + currentProject?.ntp} />
                  <Chip label={"COD: " + currentProject?.cod} />
                  <Chip
                    label={
                      "Tax Credit Type: " +
                      PROJECT_TAX_CREDIT_TYPE[currentProject?.tax_credit_type]
                    }
                  />
                  {(currentProject.deals || []).length > 4 ? (
                    <Chip
                      label="Deals"
                      variant="outlined"
                      onClick={() =>
                        handleNavigate(
                          `/project/${currentProject?.id}/general`,
                          "project-deals",
                        )
                      }
                    />
                  ) : (
                    (currentProject?.deals || []).map((d, idx) => (
                      <Chip
                        key={idx}
                        label={d.name}
                        variant="outlined"
                        icon={<OpenInNewIcon fontSize="small" />}
                        onClick={() => onDealChipClick(d.id)}
                      />
                    ))
                  )}
                </Box>
              </Box>
              <Divider className={styles.classes.divider} />
            </Box>
          </Box>
        )}
        <Box className={styles.classes.children}>{children}</Box>
      </>
    </Layout>
  );
}
