import { IDealFeeForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_FEE_TYPES {
  LEG = "Legal",
  AD = "Advisory",
  CON = "Consultant",
  UF = "Upfront",
  OT = "Other",
}

export const DEAL_FEE_TYPES_OPTIONS = enumToOptions(DEAL_FEE_TYPES);

export enum DEAL_FEE_ALLOCATION_METHODS {
  PIC = "Project Indirect Cost",
  DFE = "Deal Fees Expensed",
  DFA = "Deal Fees Amortized",
}

export const DEAL_FEE_ALLOCATION_METHODS_OPTIONS = enumToOptions(
  DEAL_FEE_ALLOCATION_METHODS,
);

export enum DEAL_FEE_COST_WEIGHTING_METHODS {
  WBMW = "Weight by MW (AC)",
  SE = "Split Equally",
}

export const DEAL_FEE_COST_WEIGHTING_METHODS_OPTIONS = enumToOptions(
  DEAL_FEE_COST_WEIGHTING_METHODS,
);

export const DEAL_FEE_FORM_DEFAULT_STATE: IDealFeeForm = {
  fee_type: "",
  name: "",
  amount: null,
  allocation_method: "",
  amortization_term: null,
  cost_weighting_method: "",
};
