import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DealsIcon from "../../../components/logos-and-icons/deals-icon";
import ProjectsIcon from "../../../components/logos-and-icons/projects-icon";
import CapacityIcon from "@mui/icons-material/ChargingStation";
import DollarIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

import useStyles from "./styles";
import Charts from "./charts";
import Tabs from "../../../components/tabs";
import ViewWrapper from "../../../components/view-wrapper";
import DashboardChart from "./split-charts";
import ChartWrapper from "../../../components/chart-wrapper";
import { numberWithCommas } from "../../../utils/helpers";
import ThemeContext from "../../../utils/context/theme-context";
import { AppContext } from "../../../utils/context/app-context";
import { useAPI, useSessionStorage } from "../../../utils/hooks";
import {
  ITab,
  IDealReportByStage,
  IPipelineReportByYear,
  IProjectsStageReport,
  IDashboardMetrics,
  IDashboardChartData,
  IProject,
  ServerPaginatedResponse,
  IGetProjectsParams,
} from "../../../interfaces";
import {
  DashboardCard,
  DashboardCardHeader,
  DashboardCardDescription,
} from "../../../components/card";

type ProjectStage = "DEV" | "CON" | "OP" | "";

interface IProps {
  getProjectsStageReport: () => Promise<IProjectsStageReport | null>;
  getDealReportingByStage: () => Promise<IDealReportByStage | null>;
  getPipelineReportByYear: (
    projectStage: ProjectStage,
  ) => Promise<IPipelineReportByYear | null>;
  getDashboardMetrics: () => Promise<IDashboardMetrics | null>;
  getDashboardCharts: () => Promise<IDashboardChartData>;
  getProjects: (
    params: IGetProjectsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
}

export default function DashboardView({
  getDealReportingByStage,
  getProjectsStageReport,
  getPipelineReportByYear,
  getDashboardMetrics,
  getDashboardCharts,
  getProjects,
}: IProps): JSX.Element {
  const { userId, orgId } = useAuth();
  const navigate = useNavigate();

  const styles = useStyles();

  const { darkMode } = React.useContext(ThemeContext);
  const { ctrlPressed } = React.useContext(AppContext);

  const [pipelineByCodTab, setPipelineByCodTab] =
    useSessionStorage<ProjectStage>(`${userId}_${orgId}_pipelineTab`, "");

  const [dealReportByStage, setDealReportByStage] =
    React.useState<IDealReportByStage>();
  const [projectsStageReport, setProjectsStageReport] =
    React.useState<IProjectsStageReport>();
  const [pipelineReportByYear, setPipelineReportByYear] =
    React.useState<IPipelineReportByYear>();
  const [dashboardMetrics, setDashboardMetrics] =
    React.useState<IDashboardMetrics>();
  const [dashboardChartData, setDashboardChartData] =
    React.useState<IDashboardChartData>();
  const [projects, setProjects] = React.useState<IProject[]>();

  const handleGetProjectsStageReport = async () => {
    const response = await getProjectsStageReport().catch(() => null);

    if (response) {
      setProjectsStageReport(response);
    }
  };

  const handleGetDealReportingByStage = async () => {
    const response = await getDealReportingByStage().catch(() => null);

    if (response) {
      setDealReportByStage(response);
    }
  };

  const handleGetPipelineReportByYear = async () => {
    const response = await getPipelineReportByYear(pipelineByCodTab).catch(
      () => null,
    );

    if (response) {
      setPipelineReportByYear(response);
    }
  };

  const getChartsData = async () => {
    await Promise.all([
      handleGetProjectsStageReport(),
      handleGetDealReportingByStage(),
      handleGetPipelineReportByYear(),
    ]);
  };

  const { callAPI: getChartsDataCallAPI, loading: loadingCharts } = useAPI(
    getChartsData,
    { initialLoading: true },
  );

  const {
    callAPI: getDashboardMetricsCallAPI,
    loading: loadingDashboardMetrics,
    errored: getDashboardMetricsFailed,
  } = useAPI(getDashboardMetrics, { initialLoading: true });

  const {
    callAPI: getDashboardChartsCallAPI,
    loading: loadingDashboardCharts,
    errored: getDashboardChartsFailed,
  } = useAPI(getDashboardCharts, { initialLoading: true });

  React.useEffect(() => {
    getChartsDataCallAPI();
    getDashboardMetricsCallAPI().then((res) => {
      res && setDashboardMetrics(res);
    });
    getDashboardChartsCallAPI().then((res) => {
      res && setDashboardChartData(res);
    });
    getProjects({})
      .then((res) => {
        res && setProjects(res?.results);
      })
      .catch(() => null);
  }, []);

  React.useEffect(() => {
    handleGetPipelineReportByYear();
  }, [pipelineByCodTab]);

  const handleTabChange = (tab: ITab) => {
    setPipelineByCodTab(tab.value as ProjectStage);
  };

  const gotoList = (type: "deal" | "project") => {
    let url = "";
    switch (type) {
      case "deal":
        url = "/deal-list/";
        break;
      case "project":
        url = "/project-list/";
        break;
      default:
        url = "/";
    }

    if (ctrlPressed) {
      window.open(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <ViewWrapper
        loading={loadingDashboardMetrics}
        error={getDashboardMetricsFailed}
      >
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={6} lg={3}>
            <DashboardCard
              onClick={() => gotoList("deal")}
              color="green"
              dataPw="deals-count"
            >
              <DashboardCardHeader>
                <Typography>Deals</Typography>
                <DealsIcon fillColor={darkMode ? "white" : "black"} />
              </DashboardCardHeader>
              <DashboardCardDescription>
                {dashboardMetrics?.deal_count}
              </DashboardCardDescription>
            </DashboardCard>
          </Grid>
          <Grid item xs={6} lg={3}>
            <DashboardCard
              onClick={() => gotoList("project")}
              color="purple"
              dataPw="project-count"
            >
              <DashboardCardHeader>
                <Typography>Projects</Typography>
                <ProjectsIcon fillColor={darkMode ? "white" : "black"} />
              </DashboardCardHeader>
              <DashboardCardDescription>
                {dashboardMetrics?.project_count}
              </DashboardCardDescription>
            </DashboardCard>
          </Grid>
          <Grid item xs={6} lg={3}>
            <DashboardCard
              onClick={() => gotoList("project")}
              color="orange"
              dataPw="total-capacity"
            >
              <DashboardCardHeader>
                <Typography>Total Capacity (AC)</Typography>
                <CapacityIcon />
              </DashboardCardHeader>
              <DashboardCardDescription>
                {numberWithCommas.format(
                  dashboardMetrics?.total_capacity_ac ?? 0,
                )}{" "}
                MW
              </DashboardCardDescription>
            </DashboardCard>
          </Grid>
          <Grid item xs={6} lg={3}>
            <DashboardCard
              onClick={() => gotoList("project")}
              color="blue"
              dataPw="total-cost"
            >
              <DashboardCardHeader>
                <Typography>Total FMV</Typography>
                <DollarIcon />
              </DashboardCardHeader>
              <DashboardCardDescription>
                ${numberWithCommas.format(dashboardMetrics?.total_fmv ?? 0)}
              </DashboardCardDescription>
            </DashboardCard>
          </Grid>
        </Grid>
      </ViewWrapper>
      <ViewWrapper loading={loadingCharts} error={false}>
        {projectsStageReport && pipelineReportByYear && dealReportByStage && (
          <>
            <ChartWrapper title="Deal Stage">
              <Charts
                projectsStage={projectsStageReport}
                pipelineByYear={pipelineReportByYear}
                dealByStage={dealReportByStage}
                showChart="dealStage"
              />
            </ChartWrapper>

            <Box className={styles.classes.projectChartsContainer}>
              <ChartWrapper title="Project Stage">
                <Charts
                  projectsStage={projectsStageReport}
                  pipelineByYear={pipelineReportByYear}
                  dealByStage={dealReportByStage}
                  showChart={"projectsStage"}
                />
              </ChartWrapper>
              <ChartWrapper title="Pipeline by COD Year">
                <Box className={styles.classes.chartContainerWithTabs}>
                  <Tabs
                    borderBottom={false}
                    tabs={[
                      {
                        label: "All",
                        value: "",
                        path: "",
                        pathToMatch: "",
                      },
                      {
                        label: "Development",
                        value: "DEV",
                        path: "",
                        pathToMatch: "",
                      },
                      {
                        label: "Construction",
                        value: "CON",
                        path: "",
                        pathToMatch: "",
                      },
                      {
                        label: "Operation",
                        value: "OP",
                        path: "",
                        pathToMatch: "",
                      },
                    ]}
                    onClick={handleTabChange}
                    selectedTab={pipelineByCodTab}
                  />
                  <Charts
                    projectsStage={projectsStageReport}
                    pipelineByYear={pipelineReportByYear}
                    dealByStage={dealReportByStage}
                    showChart="pipelineByYear"
                    filter={pipelineByCodTab}
                  />
                </Box>
              </ChartWrapper>
            </Box>
          </>
        )}
      </ViewWrapper>
      <ViewWrapper
        loading={loadingDashboardCharts}
        error={getDashboardChartsFailed}
      >
        {projects?.length && dashboardChartData && (
          <Grid container marginBlock={1} spacing={2}>
            <Grid item xs={6} lg={3}>
              <ChartWrapper title="Technology Type">
                <DashboardChart
                  technologyTypeData={dashboardChartData?.technology_type}
                  stateData={dashboardChartData?.state}
                  powerMarketData={dashboardChartData?.power_market}
                  taxCreditTypeData={dashboardChartData?.tax_credit_type}
                  type="technologyType"
                />
              </ChartWrapper>
            </Grid>
            <Grid item xs={6} lg={3}>
              <ChartWrapper title="State">
                <DashboardChart
                  technologyTypeData={dashboardChartData?.technology_type}
                  stateData={dashboardChartData?.state}
                  powerMarketData={dashboardChartData?.power_market}
                  taxCreditTypeData={dashboardChartData?.tax_credit_type}
                  type="state"
                />
              </ChartWrapper>
            </Grid>
            <Grid item xs={6} lg={3}>
              <ChartWrapper title="Power Market">
                <DashboardChart
                  technologyTypeData={dashboardChartData?.technology_type}
                  stateData={dashboardChartData?.state}
                  powerMarketData={dashboardChartData?.power_market}
                  taxCreditTypeData={dashboardChartData?.tax_credit_type}
                  type="powerMarket"
                />
              </ChartWrapper>
            </Grid>
            <Grid item xs={6} lg={3}>
              <ChartWrapper title="Tax Credit Type">
                <DashboardChart
                  technologyTypeData={dashboardChartData?.technology_type}
                  stateData={dashboardChartData?.state}
                  powerMarketData={dashboardChartData?.power_market}
                  taxCreditTypeData={dashboardChartData?.tax_credit_type}
                  type="taxCreditType"
                />
              </ChartWrapper>
            </Grid>
          </Grid>
        )}
      </ViewWrapper>
    </>
  );
}
