import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./styles";

interface IProps {
  open: boolean;
  onClose: () => void;
  label: string;
  children: JSX.Element;
}

export default function LogsWrapper({
  open,
  onClose,
  label,
  children,
}: IProps) {
  const styles = useStyles();

  return (
    <Drawer
      anchor="right"
      classes={{
        paperAnchorRight: styles.classes.logsDrawer,
      }}
      open={open}
      onClose={onClose}
    >
      <Box className={styles.classes.title}>
        <Typography variant="h6">{label}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Drawer>
  );
}
