import Badge from "@mui/material/Badge";

import HomeIcon from "../components/logos-and-icons/home-icon";
import ProjectsIcon from "../components/logos-and-icons/projects-icon";
import DealsIcon from "../components/logos-and-icons/deals-icon";
import CompareIcon from "../components/logos-and-icons/compare-icon";
import CozeIcon from "../components/logos-and-icons/coze-icon";
import ConfigurationIcon from "../components/logos-and-icons/configuration-icon";
import { IConfirmDeleteModalProps } from "../components/confirm-delete-modal";
import {
  cn,
  enumToOptions,
  getITableColumn,
} from "../utils/helpers";
import {
  ISidebarLink,
  IProjectProforma,
  ProformaRowNumberRecord,
  IProformaDetailTableConfig,
} from "../interfaces";

export enum US_STATES {
  AL = "Alabama",
  AK = "Alaska",
  AZ = "Arizona",
  AR = "Arkansas",
  CA = "California",
  CO = "Colorado",
  CT = "Connecticut",
  DE = "Delaware",
  DC = "District of Columbia",
  FL = "Florida",
  GA = "Georgia",
  GU = "Guam",
  HI = "Hawaii",
  ID = "Idaho",
  IL = "Illinois",
  IN = "Indiana",
  IA = "Iowa",
  KS = "Kansas",
  KY = "Kentucky",
  LA = "Louisiana",
  ME = "Maine",
  MD = "Maryland",
  MA = "Massachusetts",
  MI = "Michigan",
  MN = "Minnesota",
  MS = "Mississippi",
  MO = "Missouri",
  MT = "Montana",
  NE = "Nebraska",
  NV = "Nevada",
  NH = "New Hampshire",
  NJ = "New Jersey",
  NM = "New Mexico",
  NY = "New York",
  NC = "North Carolina",
  ND = "North Dakota",
  OH = "Ohio",
  OK = "Oklahoma",
  OR = "Oregon",
  PA = "Pennsylvania",
  PR = "Puerto Rico",
  RI = "Rhode Island",
  SC = "South Carolina",
  SD = "South Dakota",
  TN = "Tennessee",
  TX = "Texas",
  UT = "Utah",
  VI = "Virgin Islands",
  VT = "Vermont",
  VA = "Virginia",
  WA = "Washington",
  WV = "West Virginia",
  WI = "Wisconsin",
  WY = "Wyoming",
}

export enum MW_IN_OPERATION {
  CSML = "0-100",
  SML = "100-500",
  MED = "500-1000",
  LAR = "1000-3000",
  VLAR = "3000+",
}

export enum MARKETS {
  CAISO = "CAISO",
  ERCOT = "ERCOT",
  PJM = "PJM",
  SPP = "SPP",
  MISO = "MISO",
  NW = "Northwest",
  NYISO = "NYISO",
  ISONE = "ISO-NE",
  SW = "Southwest",
  SE = "Southeast",
  NA = "N/A",
}

export const MARKETS_OPTIONS = enumToOptions(MARKETS);

export enum ORGANIZATION_TYPE {
  DEV = "Developer",
}

export const US_STATES_OPTIONS = enumToOptions(US_STATES);

const renderBetaBadge = (label: string) => (
  <Badge badgeContent="Beta" color="warning" className={cn("mr-[5px]")}>
    <span style={{ paddingRight: "12.5px" }}>{label}&ensp;&ensp;</span>
  </Badge>
);

export const SIDEBAR_NAVIGATION: ISidebarLink[] = [
  { label: "Home", path: "/", icon: HomeIcon },
  {
    label: "Projects",
    path: "/project-list",
    icon: ProjectsIcon,
    subLinks: [
      {
        label: "General",
        path: "/project/:projectId/general",
      },
      { label: "Timing", path: "/project/:projectId/timing" },
      {
        label: "Cost",
        path: "/project/:projectId/cost",
        subLinks: [
          {
            label: "Dashboard",
            value: "cost-summary",
            path: "/project/:projectId/cost",
          },
          {
            label: "Project Costs",
            value: "cost-inputs",
            path: "/project/:projectId/cost-inputs",
          },
          {
            label: "Credit Support",
            value: "credit-support",
            path: "/project/:projectId/cost-credit-support",
          },
        ],
      },
      {
        label: "Pro Forma",
        path: "/project/:projectId/pro-forma",
        pathToMatch: "/project/:projectId/pro-forma",
        subLinks: [
          {
            label: "Dashboard",
            value: "pro-forma",
            path: "/project/:projectId/pro-forma",
          },
          {
            label: "Production",
            value: "production",
            path: "/project/:projectId/pro-forma/production",
          },
          {
            label: "Revenues",
            value: "revenue",
            path: "/project/:projectId/pro-forma/revenue",
          },
          {
            label: "Expenses",
            value: "expenses",
            path: "/project/:projectId/pro-forma/expenses",
          },
          {
            label: "Other",
            value: "other-flows",
            path: "/project/:projectId/pro-forma/other-flows",
            pathToMatch: "/project/:projectId/pro-forma/other-flows",
          },
        ],
      },
      {
        label: "Tax",
        path: "/project/:projectId/tax/depreciation",
        pathToMatch: "/project/:projectId/tax",
        subLinks: [
          {
            label: "Depreciation",
            value: "depreciation",
            path: "/project/:projectId/tax/depreciation",
          },
          {
            label: "Tax Credits",
            value: "tax-credit",
            path: "/project/:projectId/tax/tax-credit",
          },
        ],
      },
    ],
  },
  {
    label: "Deals",
    path: "/deal-list",
    icon: DealsIcon,
    subLinks: [
      {
        label: "General",
        path: "/deal/:dealId/case-deal/:caseDealId/general",
      },
      {
        label: "Timing",
        path: "/deal/:dealId/case-deal/:caseDealId/timing",
      },
      {
        label: "Components",
        path: "/deal/:dealId/case-deal/:caseDealId/component/pro-forma",
        pathToMatch: "/deal/:dealId/case-deal/:caseDealId/component",
        subLinks: [
          {
            label: "Pro Forma",
            value: "pro-forma",
            path: "/deal/:dealId/case-deal/:caseDealId/component/pro-forma",
          },
          {
            label: "Costs",
            value: "cost",
            path: "/deal/:dealId/case-deal/:caseDealId/component/cost",
          },
          {
            label: "Depreciation",
            value: "depreciation",
            path: "/deal/:dealId/case-deal/:caseDealId/component/depreciation",
          },
          {
            label: "Tax Credits",
            value: "tax-credits",
            path: "/deal/:dealId/case-deal/:caseDealId/component/tax-credits",
          },
        ],
      },
      {
        label: "Sizing",
        path: "/deal/:dealId/case-deal/:caseDealId/sizing/tax-equity",
        pathToMatch: "/deal/:dealId/case-deal/:caseDealId/sizing",
        subLinks: [
          {
            label: "Tax Equity",
            value: "tax-equity",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/tax-equity",
            visibility: {
              OR: {
                structure: ["TEP", "IL"],
              },
            },
          },
          {
            label: "Cash Equity",
            value: "cash-equity",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/cash-equity",
            visibility: {
              OR: {
                structure: ["CEP"],
              },
            },
          },
          {
            label: "Sponsor Equity",
            value: "sponsor-equity",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/sponsor-equity",
            visibility: {
              OR: {
                structure: ["TEP", "CEP", "EO"],
              },
            },
          },
          {
            label: "Term Debt",
            value: "term-debt",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/term-debt",
            visibility: {
              OR: {
                structure: ["TEP", "CEP", "EO"],
              },
            },
          },
          {
            label: "Construction Debt",
            value: "construction-debt",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/construction-debt",
            visibility: {
              OR: {
                structure: ["TEP", "CEP", "EO"],
              },
            },
          },
          {
            label: "Transfer",
            value: "transfer",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/transfer",
            visibility: {
              OR: {
                tax_credit_structure: ["TR"],
              },
            },
          },
          {
            label: "Direct Pay",
            value: "direct-pay",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/direct-pay",
            visibility: {
              OR: {
                tax_credit_structure: ["DP"],
              },
            },
          },
          {
            label: "Lessor",
            value: "sale-leaseback",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/sale-leaseback",
            visibility: {
              OR: {
                structure: ["SL"],
              },
            },
          },
          {
            label: "Fees",
            value: "deal-fee",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/deal-fee",
          },
          {
            label: "Other",
            value: "other",
            path: "/deal/:dealId/case-deal/:caseDealId/sizing/other",
          },
        ],
      },
      {
        label: "Output",
        path: "/deal/:dealId/case-deal/:caseDealId/output/partnership/summary",
        pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output",
        subLinks: [
          {
            label: "Partnership",
            value: "partnership",
            path: "/deal/:dealId/case-deal/:caseDealId/output/partnership",
            visibility: {
              OR: {
                structure: ["TEP", "CEP"],
              },
            },
          },
          {
            label: "Portfolio",
            value: "partnership",
            path: "/deal/:dealId/case-deal/:caseDealId/output/partnership",
            visibility: {
              OR: {
                structure: ["EO", "IL"],
              },
            },
          },
          {
            label: "Tax Equity",
            value: "tax-equity",
            path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity",
            visibility: {
              OR: {
                structure: ["TEP"],
              },
            },
          },
          {
            label: "Cash Equity",
            value: "tax-equity",
            path: "/deal/:dealId/case-deal/:caseDealId/output/tax-equity",
            visibility: {
              OR: {
                structure: ["CEP"],
              },
            },
          },
          {
            label: "Sponsor Equity",
            value: "sponsor-equity",
            path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity",
            visibility: {
              OR: {
                structure: ["TEP", "CEP", "EO"],
              },
            },
          },
          {
            label: renderBetaBadge("Sponsor Equity (BOL)"),
            value: "sponsor-equity-bol",
            path: "/deal/:dealId/case-deal/:caseDealId/output/sponsor-equity-bol",
            visibility: {
              OR: {
                structure: ["TEP", "CEP", "EO"],
              },
            },
          },
          {
            label: renderBetaBadge("Construction Debt"),
            value: "construction-debt",
            path: "/deal/:dealId/case-deal/:caseDealId/output/construction-debt",
            visibility: {
              OR: {
                is_construction_debt_added: [true],
              },
            },
          },
          {
            label: renderBetaBadge("Developer"),
            value: "developer",
            path: "/deal/:dealId/case-deal/:caseDealId/output/developer",
          },
          {
            label: "Term Debt",
            value: "term-debt",
            path: "/deal/:dealId/case-deal/:caseDealId/output/term-debt",
            visibility: {
              OR: {
                debt: ["1"],
              },
            },
          },
          {
            label: "Transfer",
            value: "transfer",
            path: "/deal/:dealId/case-deal/:caseDealId/output/transfer",
            visibility: {
              OR: {
                tax_credit_structure: ["TR"],
              },
            },
          },
          {
            label: "Direct Pay",
            value: "transfer",
            path: "/deal/:dealId/case-deal/:caseDealId/output/transfer",
            visibility: {
              OR: {
                tax_credit_structure: ["DP"],
              },
            },
          },
          // TODO: re-enable for SLB when "real" ?
          //{
          //  label: "Portfolio",
          //  value: "lease",
          //  path: "/deal/:dealId/case-deal/:caseDealId/output/lease",
          //  visibility: {
          //    OR: {
          //      structure: ["SL"],
          //    },
          //  },
          //},
          {
            label: "Lessor",
            value: "lessor",
            path: "/deal/:dealId/case-deal/:caseDealId/output/lessor",
            visibility: {
              OR: {
                structure: ["SL"],
              },
            },
          },
          {
            label: "Lessee",
            value: "lessee",
            path: "/deal/:dealId/case-deal/:caseDealId/output/lessee",
            visibility: {
              OR: {
                structure: ["SL"],
              },
            },
          },
        ],
      },
      {
        label: "divider", // This is used as a divider in UI
        path: "--",
      },
      {
        label: "Cases",
        path: "/deal/:dealId/cases",
        pathToMatch: "/deal/:dealId/case",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO", "IL"], // disable for SL
          },
        },
      },
      {
        label: "Analysis",
        path: "/deal/:dealId/analysis",
        visibility: {
          OR: {
            structure: ["TEP", "CEP", "EO", "IL"], // disable for SL
          },
        },
      },
      // {
      //   label: "Access",
      //   path: "/deal/:dealId/access"
      // },
    ],
  },
  {
    label: "Compare",
    path: "/analysis/deal-comparison-list",
    pathToMatch: "/analysis/deal-comparison",
    icon: CompareIcon,
    // subLinks: [
    //   {
    //     label: "Deal Comparison",
    //     path: "/analysis/deal-comparison-list",
    //     pathToMatch: "/analysis/deal-comparison",
    //   },
    // ],
  },
  { label: "CoZe", path: "/coze", icon: CozeIcon },
  // {
  //   label: "Workflows",
  //   path: "/workflows",
  //   icon: FormatListBulletedIcon,
  //   subLinks: [
  //     { label: "Timeline", path: "/workflow/timeline" },
  //     { label: "Working Group List", path: "/workflow/working-group-list" },
  //     { label: "NDA", path: "/workflow/n-d-a" },
  //     { label: "Marketing Material", path: "/workflow/marketing-material" },
  //     { label: "Data Room", path: "/workflow/data-room" },
  //     { label: "Documents", path: "/workflow/documents" },
  //     { label: "Third Party Reports", path: "/workflow/third-party-reports" },
  //     { label: "Model", path: "/workflow/model" },
  //   ],
  // },
  // { label: "Team", path: "/team", icon: GroupIcon },
  {
    label: "Configuration",
    path: "/configuration/data/curves",
    pathToMatch: "/configuration/",
    icon: ConfigurationIcon,
    subLinks: [
      {
        label: "Data",
        value: "data",
        path: "/configuration/data/curves",
        pathToMatch: "/configuration/data",
      },
      {
        label: "Organization",
        value: "organization",
        path: "/configuration/organization/profile",
        pathToMatch: "/configuration/organization",
      },
    ],
  },
];

export const CONFIRM_DELETE_MODAL_DEFAULT_PROPS: IConfirmDeleteModalProps = {
  open: false,
  title: "",
  loading: false,
  description: "",
  onClose: () => undefined,
  onConfirm: () => Promise.resolve(),
};

export enum MONTHS {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export const MONTH_OPTIONS = Object.keys(MONTHS).map((key) => {
  const monthNumber = Object.keys(MONTHS).indexOf(key) + 1;
  return {
    label: key,
    value: monthNumber.toString(),
  };
});

export enum DASHBOARD_MARKETS {
  CAISO = "CAISO",
  ERCOT = "ERCOT",
  PJM = "PJM",
  SPP = "SPP",
  MISO = "MISO",
  NW = "Northwest",
  NYISO = "NYISO",
  "ISO-NE" = "ISO-NE",
  SW = "Southwest",
  SE = "Southeast",
  NA = "N/A",
}

// The list of backend object types that are there, used mainly for activity logs.
export type OBJECT_TYPE =
  | "deal"
  | "project"
  | "projectproduction"
  | "dealtiming"
  | "dealdepreciation"
  | "dealinputs"
  | "dealtermdebt"
  | "dealfee"
  | "projectmembership"
  | "transfersizing"
  | "slbsizing"
  | "partnershipinvestorconfiguration"
  | "dealothersizing"
  | "portfolio"
  | "projectmerchantrevenue"
  | "projectexpense"
  | "project"
  | "storageprojectproduction"
  | "projectrevenuecontractotherrevenue"
  | "projecttaxcredit"
  | "projectrevenuecontract"
  | "projectdepreciation"
  | "projecttiming"
  | "projectcost"
  | "projectdepreciationstream"
  | "projectscheduleoverride"
  | "projectproduction"
  | "projectcreditsupport"
  | "sponsorequityconfiguration"
  | "dealconstructiondebtgeneralsizing"
  | "dealconstructiondebt"
  | "dealinvestorbridgedebt"
  | "dealtransferbridgedebt"
  | "projectcostitem";

export type ObjectTypeMap = {
  [key in OBJECT_TYPE]: { name: string };
};

export const objectTypeMap: ObjectTypeMap = {
  deal: { name: "Deal" },
  project: { name: "Project" },
  dealtermdebt: { name: "Term Debt" },
  projectproduction: { name: "Project Production" },
  dealconstructiondebtgeneralsizing: {
    name: "Construction Debt General Sizing",
  },
  dealconstructiondebt: { name: "Construction Loan" },
  dealinvestorbridgedebt: { name: "Investor Bridge Loan" },
  dealtransferbridgedebt: { name: "Transfer Bridge Loan" },
  dealtiming: { name: "Deal Timing" },
  dealfee: { name: "Deal Fee" },
  dealdepreciation: { name: "Deal Depreciation" },
  dealinputs: { name: "Deal Inputs" },
  projectmembership: { name: "Project Membership" },
  transfersizing: { name: "Transfer Sizing" },
  slbsizing: { name: "SLB Sizing" },
  partnershipinvestorconfiguration: {
    name: "Partnership Investor Configuration",
  },
  dealothersizing: { name: "Other Sizing" },
  portfolio: { name: "Portfolio" },
  projectmerchantrevenue: { name: "Merchant Revenue" },
  projectexpense: { name: "Project Expense" },
  storageprojectproduction: { name: "Storage Project Production" },
  projectrevenuecontractotherrevenue: {
    name: "Revenue Contract Other Revenue",
  },
  projecttaxcredit: { name: "Project Tax Credit" },
  projectrevenuecontract: { name: "Revenue Contract" },
  projectdepreciation: { name: "Project Depreciation" },
  projecttiming: { name: "Project Timing" },
  projectcost: { name: "Cost" },
  projectdepreciationstream: { name: "Project Depreciation Stream" },
  projectscheduleoverride: { name: "Project Schedule Override" },
  sponsorequityconfiguration: { name: "Sponsor Equity Configuration" },
  projectcreditsupport: { name: "Project Credit Support" },
  projectcostitem: { name: "Project Cost" },
};

export const PROFORMA_TABLE_CONFIG: IProformaDetailTableConfig = {
  contracted_revenue_type_hedge: getITableColumn(
    "contracted_revenue_type_hedge",
    "Hedge",
    80,
    "right",
    true,
    "#8064A2",
  ),
  contracted_revenue_type_capacity: getITableColumn(
    "contracted_revenue_type_capacity",
    "Capacity",
    80,
    "right",
    true,
    "#4AACC6",
  ),
  merchant_revenue_type_energy: getITableColumn(
    "merchant_revenue_type_energy",
    "Merchant Energy",
    80,
    "right",
    true,
    "#2C4D75",
  ),
  merchant_revenue_type_rec: getITableColumn(
    "merchant_revenue_type_rec",
    "Merchant REC",
    80,
    "right",
    true,
    "#772C2A",
  ),
  merchant_revenue_type_capacity: getITableColumn(
    "merchant_revenue_type_capacity",
    "Merchant Capacity",
    80,
    "right",
    true,
    "#5E7430",
  ),
  expense_type_oandm: getITableColumn(
    "expense_type_oandm",
    "O&M (Scheduled)",
    80,
    "right",
    true,
    "#B65607",
  ),
  expense_type_oandm_un: getITableColumn(
    "expense_type_oandm_un",
    "O&M (Unscheduled)",
    80,
    "right",
    true,
    "#719ACA",
  ),
  expense_type_assmgmt: getITableColumn(
    "expense_type_assmgmt",
    "Asset Management",
    80,
    "right",
    true,
    "#CD7271",
  ),
  expense_type_admin: getITableColumn(
    "expense_type_admin",
    "Administrative",
    80,
    "right",
    true,
    "#AFC97A",
  ),
  expense_type_ins: getITableColumn(
    "expense_type_ins",
    "Insurance",
    80,
    "right",
    true,
    "#9883B5",
  ),
  expense_type_ins_un: getITableColumn(
    "expense_type_ins_un",
    "Insurance (Unscheduled)",
    80,
    "right",
    true,
    "#9f3c38",
  ),

  expense_type_land: getITableColumn(
    "expense_type_land",
    "Land Lease",
    80,
    "right",
    true,
    "#6EBDD0",
  ),
  expense_type_proptx: getITableColumn(
    "expense_type_proptx",
    "Property Tax",
    80,
    "right",
    true,
    "#F9AA6B",
  ),
  expense_type_majmain: getITableColumn(
    "expense_type_majmain",
    "Major Maintenance",
    80,
    "right",
    true,
    "#3A679C",
  ),
  expense_type_loc: getITableColumn(
    "expense_type_loc",
    "Letter of Credit",
    80,
    "right",
    true,
    "#9E3B38",
  ),
  expense_type_taxes: getITableColumn(
    "expense_type_taxes",
    "Taxes",
    80,
    "right",
    true,
    "#7E9D40",
  ),
  expense_type_other: getITableColumn(
    "expense_type_other",
    "Other Operations",
    80,
    "right",
    true,
    "#654F83",
  ),
  expense_type_other_no: getITableColumn(
    "expense_type_other_no",
    "Other Non-Operational",
    80,
    "right",
    true,
    "#368EA6",
  ),
  expense_type_cont: getITableColumn(
    "expense_type_cont",
    "Contingency",
    80,
    "right",
    true,
    "#8B4513",
  ),
  gross_production: getITableColumn(
    "gross_production",
    "Gross Production",
    80,
    "right",
    true,
    "#4E81BD",
  ),
  production_losses: getITableColumn(
    "production_lossees",
    "Production Losses",
    80,
    "right",
    true,
    "#BF504D",
  ),
  net_production: getITableColumn(
    "net_production",
    "Net Production",
    80,
    "right",
    true,
    "#9ABB59",
  ),
  gross_capacity: getITableColumn(
    "gross_capacity",
    "Gross Capacity",
    80,
    "right",
    false,
    "#4E81BD",
  ),
  net_capacity: getITableColumn(
    "net_capacity",
    "Net Capacity",
    80,
    "right",
    false,
    "#4E81BD",
  ),
  date_formatted: getITableColumn("date_formatted", "Date", 120, "left", true),
  contracted_revenue_type_ppa: getITableColumn(
    "contracted_revenue_type_ppa",
    "PPA",
    80,
    "right",
    true,
    "#6A5ACD",
  ),
  contracted_revenue_type_vppa: getITableColumn(
    "contracted_revenue_type_vppa",
    "VPPA",
    80,
    "right",
    true,
    "#8064a2",
  ),
  contracted_revenue_type_rec: getITableColumn(
    "contracted_revenue_type_rec",
    "REC",
    80,
    "right",
    true,
    "#FF4500",
  ),
  contracted_revenue_type_other: getITableColumn(
    "contracted_revenue_type_other",
    "Other",
    80,
    "right",
    true,
    "#772c2a",
  ),
  contracted_revenue_total: getITableColumn(
    "contracted_revenue_total",
    "Contracted Revenue",
    80,
    "right",
    true,
    "#5f7531",
  ),

  merchant_revenue_type_other: getITableColumn(
    "merchant_revenue_type_other",
    "Merchant Other",
    80,
    "right",
    true,
    "#8B4513",
  ),
  merchant_revenue_total: getITableColumn(
    "merchant_revenue_total",
    "Merchant Revenue",
    80,
    "right",
    true,
    "#cd7372",
  ),
  revenue_total: getITableColumn(
    "revenue_total",
    "Total Revenue",
    80,
    "right",
    true,
    "#afc97a",
  ),
  expenses: getITableColumn("expenses", "Total Expenses", 80, "right", true),
  ebitda: getITableColumn("ebitda", "EBITDA", 80, "right", true),
  other_flow_cash_total: getITableColumn(
    "other_flow_cash_total",
    "Total Other Flow Cash",
    80,
    "right",
    true,
    "#aaaaaa",
  ),
  other_flow_tax_total: getITableColumn(
    "other_flow_tax_total",
    "Total Other Flow Tax",
    80,
    "right",
    true,
    "#aaaaaa",
  ),
};

export const PROFORMA_DETAIL_COLUMNS: string[] = [
  PROFORMA_TABLE_CONFIG.date_formatted.id,
  PROFORMA_TABLE_CONFIG.gross_production.id,
  PROFORMA_TABLE_CONFIG.production_losses.id,
  PROFORMA_TABLE_CONFIG.net_production.id,
  PROFORMA_TABLE_CONFIG.gross_capacity.id,
  PROFORMA_TABLE_CONFIG.net_capacity.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_type_ppa.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_type_vppa.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_type_hedge.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_type_rec.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_type_capacity.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_type_other.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_total.id,
  PROFORMA_TABLE_CONFIG.merchant_revenue_type_energy.id,
  PROFORMA_TABLE_CONFIG.merchant_revenue_type_rec.id,
  PROFORMA_TABLE_CONFIG.merchant_revenue_type_capacity.id,
  PROFORMA_TABLE_CONFIG.merchant_revenue_type_other.id,
  PROFORMA_TABLE_CONFIG.merchant_revenue_total.id,
  PROFORMA_TABLE_CONFIG.revenue_total.id,
  PROFORMA_TABLE_CONFIG.expense_type_oandm.id,
  PROFORMA_TABLE_CONFIG.expense_type_oandm_un.id,
  PROFORMA_TABLE_CONFIG.expense_type_ins.id,
  PROFORMA_TABLE_CONFIG.expense_type_ins_un.id,
  PROFORMA_TABLE_CONFIG.expense_type_assmgmt.id,
  PROFORMA_TABLE_CONFIG.expense_type_admin.id,
  PROFORMA_TABLE_CONFIG.expense_type_land.id,
  PROFORMA_TABLE_CONFIG.expense_type_proptx.id,
  PROFORMA_TABLE_CONFIG.expense_type_majmain.id,
  PROFORMA_TABLE_CONFIG.expense_type_loc.id,
  PROFORMA_TABLE_CONFIG.expense_type_taxes.id,
  PROFORMA_TABLE_CONFIG.expense_type_cont.id,
  PROFORMA_TABLE_CONFIG.expense_type_other.id,
  PROFORMA_TABLE_CONFIG.expense_type_other_no.id,
  PROFORMA_TABLE_CONFIG.expenses.id,
  PROFORMA_TABLE_CONFIG.ebitda.id,
  PROFORMA_TABLE_CONFIG.other_flow_cash_total.id,
  PROFORMA_TABLE_CONFIG.other_flow_tax_total.id,
];

export const PROFORMA_SUMARY_COLUMNS: string[] = [
  PROFORMA_TABLE_CONFIG.date_formatted.id,
  PROFORMA_TABLE_CONFIG.net_production.id,
  PROFORMA_TABLE_CONFIG.net_capacity.id,
  PROFORMA_TABLE_CONFIG.contracted_revenue_total.id,
  PROFORMA_TABLE_CONFIG.merchant_revenue_total.id,
  PROFORMA_TABLE_CONFIG.revenue_total.id,
  PROFORMA_TABLE_CONFIG.expenses.id,
  PROFORMA_TABLE_CONFIG.ebitda.id,
  PROFORMA_TABLE_CONFIG.other_flow_cash_total.id,
  PROFORMA_TABLE_CONFIG.other_flow_tax_total.id,
];

const emptyProformaNumberRecord: Record<string, ProformaRowNumberRecord> = {
  colname: {
    suffix: "",
    value: 0,
    display: false,
  },
};

export const PROFORMA_DEFAULT_STATE: IProjectProforma = {
  totals: {
    date_formatted: "",
    gross_production: 0,
    production_losses: 0,
    net_production: 0,
    gross_capacity: 0,
    net_capacity: 0,
    contracted_energy_production: emptyProformaNumberRecord,
    contracted_revenue_type_ppa: emptyProformaNumberRecord,
    contracted_revenue_type_vppa: emptyProformaNumberRecord,
    contracted_revenue_type_hedge: emptyProformaNumberRecord,
    contracted_revenue_type_rec: emptyProformaNumberRecord,
    contracted_revenue_type_capacity: emptyProformaNumberRecord,
    contracted_revenue_type_other: emptyProformaNumberRecord,
    contracted_revenue_total: 0,
    merchant_revenue_type_energy: emptyProformaNumberRecord,
    merchant_revenue_type_rec: emptyProformaNumberRecord,
    merchant_revenue_type_capacity: emptyProformaNumberRecord,
    merchant_revenue_type_other: emptyProformaNumberRecord,
    merchant_revenue_total: 0,
    revenue_total: 0,
    expense_type_oandm: emptyProformaNumberRecord,
    expense_type_oandm_un: emptyProformaNumberRecord,
    expense_type_ins: emptyProformaNumberRecord,
    expense_type_ins_un: emptyProformaNumberRecord,
    expense_type_assmgmt: emptyProformaNumberRecord,
    expense_type_admin: emptyProformaNumberRecord,
    expense_type_land: emptyProformaNumberRecord,
    expense_type_proptx: emptyProformaNumberRecord,
    expense_type_majmain: emptyProformaNumberRecord,
    expense_type_loc: emptyProformaNumberRecord,
    expense_type_taxes: emptyProformaNumberRecord,
    expense_type_cont: emptyProformaNumberRecord,
    expense_type_other: emptyProformaNumberRecord,
    expense_type_other_no: emptyProformaNumberRecord,
    expenses: 0,
    ebitda: 0,
    other_flow_cash_total: 0,
    other_flow_tax_total: 0,
  },
  proforma_data: [],
};

export enum PROFORMA_BASIS {
  cash = "Cash Basis",
  accrual = "Accrual",
}

export const PROFORMA_BASIS_OPTIONS = enumToOptions(PROFORMA_BASIS);

export enum GLOBAL_CURVE_TIMING_PERIODICITY {
  MO = "Monthly",
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const GLOBAL_CURVE_TIMING_PERIODICITY_OPTIONS = enumToOptions(
  GLOBAL_CURVE_TIMING_PERIODICITY,
);

export const DATE_FORMAT_REGEX =
  /^((0?[1-9])|(1[0-2]))\/((0?[1-9])|([1-2][0-9])|(3[0-1]))\/\d{4}$/;
