import React from "react";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import SelectInput from "../select-input";
import TextInput from "../text-input";
import {
  IDealFee,
  IDealFeeForm,
  IDealFeeFormErrors,
  SetStateAction,
} from "../../interfaces";
import {
  DEAL_FEE_TYPES_OPTIONS,
  DEAL_FEE_ALLOCATION_METHODS_OPTIONS,
  DEAL_FEE_COST_WEIGHTING_METHODS_OPTIONS,
  DEAL_FEE_FORM_DEFAULT_STATE,
} from "../../constants";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IDealFeeFormErrors;
  setFormErrors: SetStateAction<IDealFeeFormErrors | undefined>;
  form: IDealFeeForm;
  setForm: SetStateAction<IDealFeeForm>;
  onClose: () => void;
  onConfirm: (form: IDealFeeForm) => Promise<IDealFee | undefined>;
}

export default function DealFeeFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    if (field == "allocation_method" && e.target.value == "DFA") {
      setForm((prevState) => ({
        ...prevState,
        amortization_term: 20,
      }));
    } else if (field == "allocation_method" && e.target.value == "PIC") {
      setForm((prevState) => ({
        ...prevState,
        cost_weighting_method: "WBMW",
      }));
    } else if (field == "allocation_method" && e.target.value == "DFE") {
      setForm((prevState) => ({
        ...prevState,
        amortization_term: null,
        cost_weighting_method: "",
      }));
    }

    setForm((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const expense = await onConfirm(form);
    expense && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(DEAL_FEE_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <>
        <SelectInput
          required
          label="Fee Type"
          selected={form.fee_type}
          items={DEAL_FEE_TYPES_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("fee_type")}
          onChange={(e) => handleSelectInputChange(e, "fee_type")}
          error={Boolean(formErrors?.fee_type)}
          helperText={formErrors?.fee_type}
          disabled={loading}
          fullWidth={false}
          tooltip="<b>Legal</b> - Fee paid for legal service.\n
                   <b>Advisory</b> - Fee paid for financial advisory service.\n
                   <b>Consultant</b> - Fee paid for consulting service.\n
                   <b>Upfront</b> - Fee paid for structuring, underwriting, commitment, or placement."
        />
        <TextInput
          type="text"
          label="Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
          fullWidth={false}
        />

        <SelectInput
          required
          label="Allocation Method"
          selected={form.allocation_method}
          items={DEAL_FEE_ALLOCATION_METHODS_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("allocation_method")}
          onChange={(e) => handleSelectInputChange(e, "allocation_method")}
          error={Boolean(formErrors?.allocation_method)}
          helperText={formErrors?.allocation_method}
          disabled={loading}
          fullWidth={false}
          tooltip="<b>Project Indirect Cost</b> - Fee is included in FMV, eligible basis, and depreciable basis.\n
                   <b>Deal Fees Expensed</b> - Fee is excluded from FMV, eligible basis, and depreciable basis. Fee is expensed by the owner(s) when incurred.\n
                   <b>Deal Fees Amortized</b> - Fee is excluded from FMV, eligible basis, and depreciable basis. Fee is amortized by the owner(s) over a specified term."
        />
        <TextInput
          isNumeric
          label="Amount"
          name="amount"
          value={form.amount}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.amount)}
          helperText={formErrors?.amount}
          disabled={loading}
          fullWidth={false}
          endAdornment={<>$</>}
        />
        {form.allocation_method == "DFA" ? (
          <TextInput
            isNumeric
            label="Amortization Term"
            name="amortization_term"
            value={form.amortization_term}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.amortization_term)}
            helperText={formErrors?.amortization_term}
            disabled={loading}
            fullWidth={false}
            endAdornment={<>Yr</>}
          />
        ) : null}
        {form.allocation_method == "PIC" ? (
          <SelectInput
            required
            label="Cost Weighting Method"
            selected={form.cost_weighting_method}
            items={DEAL_FEE_COST_WEIGHTING_METHODS_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("cost_weighting_method")
            }
            onChange={(e) =>
              handleSelectInputChange(e, "cost_weighting_method")
            }
            error={Boolean(formErrors?.cost_weighting_method)}
            helperText={formErrors?.cost_weighting_method}
            disabled={loading}
            fullWidth={false}
          />
        ) : null}
      </>
    </Modal>
  );
}
