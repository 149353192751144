import React from "react";

import { useAPI } from "./api.hooks";
import { getLogs } from "../../apis/common";
import { OBJECT_TYPE } from "../../constants";
import { ILog, IPagination } from "../../interfaces";

export const useLogs = () => {
  const [logs, setLogs] = React.useState<ILog[]>([]);
  const [pagination, setPagination] = React.useState<IPagination>({
    totalItems: 0,
    limit: 10,
    offset: 0,
    pageNumber: 1,
  });
  const { callAPI: getLogsCallAPI, loading: loadingLogs } = useAPI(
    (type, id, offset) => getLogs(type, id, pagination?.limit, offset),
  );

  const onOpenLogs = (type: OBJECT_TYPE, id: number) => {
    getLogsCallAPI(type, id, pagination.offset)
      .then((res) => {
        res && res.results && setLogs(res.results);
        res &&
          res.count &&
          setPagination((prev) => ({ ...prev, totalItems: res.count }));
      })
      .catch(() => null);
  };

  const onCloseLogs = () => {
    setPagination({ limit: 10, offset: 0, pageNumber: 1, totalItems: 0 });
  };

  const loadMoreLogs = (type: OBJECT_TYPE, id: number) => {
    const { pageNumber, limit } = pagination;
    setPagination((prev) => ({
      ...prev,
      pageNumber: prev.pageNumber + 1,
      offset: prev.pageNumber * prev.limit,
    }));
    getLogsCallAPI(type, id, pageNumber * limit).then((res) => {
      res && setLogs((prevLogs) => [...prevLogs, ...res.results]);
      res && setPagination((prev) => ({ ...prev, totalItems: res.count }));
    });
  };

  return {
    logs,
    onOpenLogs,
    onCloseLogs,
    loadMoreLogs,
    loadingLogs,
    pagination,
  };
};
