import TabbedLayout from "../../../components/tabbed-layout";
import DealPagesLayout from "../../../components/deal-pages-layout";
import DealDirectPayView from "../../../views/deal/sizing/direct-pay";
import { updateDealTransferConstraints } from "../../../apis/deal/base";
import {
  getDealTaxCreditDirectPayDetails,
  updateDealTaxCreditTransferDetails,
} from "../../../apis/deal/tax-credit";

export default function DirectPay(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealDirectPayView
          getDealTaxCreditDirectPayDetails={getDealTaxCreditDirectPayDetails}
          updateDealTaxCreditDirectPayDetails={
            updateDealTaxCreditTransferDetails
          }
          updateDeal={updateDealTransferConstraints}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
