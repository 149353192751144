import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ fullWidth: boolean }>()((theme, { fullWidth }) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    header: {
      ".MuiTableCell-root": {
        fontWeight: 600,
        backgroundColor: theme.palette.grey[100],
      },
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.background.paper,
      zIndex: 12,
    },
    tablePaper: {
      maxWidth: fullWidth ? "100%" : "calc(100vw - 268px)",
    },
    table: {
      "& th": {
        backgroundColor: selectedTheme.color.aboveAll,
      },
      "& td": {
        backgroundColor: selectedTheme.color.aboveAll,
      },
      "& th:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 10,
      },
      "& td:first-of-type": {
        position: "sticky",
        left: 0,
        zIndex: 9,
        backgroundColor: selectedTheme.color.aboveAll,
      },
    },
    iconButton: {
      padding: 0,
      marginLeft: theme.spacing(1),
      maxHeight: 14,
    },
    sticky: {
      position: "sticky",
      top: "50px !important",
      zIndex: 10,
      backgroundColor: selectedTheme.color.aboveAll,
    },
    tableContainer: {
      maxHeight: "calc(100vh - 150px)", // Adjust height as needed
      overflowY: "auto",
    },
  };
});
