import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import IconButton from "../../../components/icon-button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import { format, startOfMonth, startOfYear } from "date-fns";

import ViewWrapper from "../../../components/view-wrapper";
import CurveFormModal from "../../../components/curve-form-modal";
import DetailsCard from "../../../components/details-card";
import CurveFieldLabel from "../../../components/curve-field-label";
import DateSchedule from "../../../components/date-schedule";
import ActionButton from "../../../components/action-button";
import { AppContext } from "../../../utils/context/app-context";
import { useAPI } from "../../../utils/hooks";
import { cn, trimString } from "../../../utils/helpers";
import {
  CURVE_TYPE,
  PRICE_TERM_TYPE,
  GLOBAL_CURVE_TIMING_PERIODICITY,
  USER_PERMISSIONS,
} from "../../../constants";
import {
  ICurveGroup,
  IDateScheduleParams,
  IDateScheduleParamsErrors,
  IOrganizationCurve,
  ISelectOption,
  ICurveForm,
  ICurveFormErrors,
  ServerPaginatedResponse,
  IUserData,
} from "../../../interfaces";

interface IProps {
  getCurveGroups: () => Promise<ServerPaginatedResponse<ICurveGroup[]>>;
  getCurve: (id: number) => Promise<IOrganizationCurve>;
  editCurve: (
    form: ICurveForm,
    id: number,
    method: "put" | "patch",
  ) => Promise<IOrganizationCurve>;
  deleteCurve: (id: number) => Promise<boolean>;
  getDateSchedule: (
    params: IDateScheduleParams,
  ) => Promise<{ schedule_dates: string[] }>;
  getUser: () => Promise<IUserData>;
}

export default function CurvesDetailView({
  getCurve,
  editCurve,
  deleteCurve,
  getDateSchedule,
  getCurveGroups,
  getUser,
}: IProps) {
  const { curveId } = useParams();
  const navigate = useNavigate();
  const {
    setConfirmDeleteModalProps,
    currentOrganizationCurveGroups,
    setCurrentOrganizationCurveGroups,
  } = React.useContext(AppContext);

  const [curveDetails, setCurveDetails] = React.useState<IOrganizationCurve>();
  const [editCurveForm, setEditCurveForm] = React.useState<ICurveForm>({
    curve_group: "",
    curve_type: "",
    name: "",
    periodicity: "",
    price_term: "",
    start_date: "",
    term_years: "",
    curve_data: null,
  });
  const [dateScheduleForEditModal, setDateScheduleForEditModal] =
    React.useState<string[]>([]);
  const [editCurveModalOpen, setEditCurveModalOpen] =
    React.useState<boolean>(false);
  const [showMoreCurve, setShowMoreCurve] = React.useState<boolean>(false);

  const {
    callAPI: getCurveCallAPI,
    loading: loadingGetCurve,
    errored: getCurveFailed,
  } = useAPI((id: number) => getCurve(Number(id)), { initialLoading: true });
  const { callAPI: getUserCallAPI, response: userData } = useAPI(getUser, {
    initialLoading: true,
  });
  const {
    callAPI: editCurveCallAPI,
    loading: editCurveLoading,
    fieldErrors: editCurveFormErrors,
    setFieldErrors: SetEditCurveFormErrors,
  } = useAPI<IOrganizationCurve, ICurveFormErrors>(
    (form: ICurveForm, id: number, method: "put" | "patch") =>
      editCurve(form, id, method),
  );

  const { callAPI: getDateScheduleCallAPI, loading: loadingDateSchedule } =
    useAPI<{ schedule_dates: string[] }, IDateScheduleParamsErrors>(
      (params: IDateScheduleParams) => getDateSchedule(params),
    );

  const { callAPI: deleteCurveCallAPI } = useAPI(
    (id: number) => deleteCurve(id),
    { setConfirmModalLoading: true },
  );

  const handleGetCurveGroups = async () => {
    const groups = await getCurveGroups().catch(() => null);
    groups && setCurrentOrganizationCurveGroups(groups.results);
  };

  const handleOpenEditCurveModal = async () => {
    if (curveDetails) {
      // get all the curve group options
      await handleGetCurveGroups();

      const {
        curve_group,
        curve_type,
        name,
        periodicity,
        price_term,
        start_date,
        term_years,
        curve_data,
      } = curveDetails;

      // get dates schedule
      await getDateScheduleCallAPI({
        periodicity,
        start_date,
        term_years,
      })
        .then((res) => res && setDateScheduleForEditModal(res.schedule_dates))
        .catch(() => null);

      setEditCurveForm({
        curve_group,
        curve_type,
        name,
        periodicity,
        price_term,
        start_date,
        term_years,
        curve_data,
      });
      setEditCurveModalOpen(true);
    }
  };

  const handleCloseEditCurveModal = () => {
    setEditCurveModalOpen(false);
    setDateScheduleForEditModal([]);
  };

  const onEditCurve = async (form: ICurveForm) => {
    let updatedForm = { ...form };
    let method: "put" | "patch" = "put";
    const updatedStartDate = format(
      startOfMonth(new Date(form.start_date)),
      "MM/dd/yyyy",
    );
    updatedForm.start_date = updatedStartDate;
    if (!curveDetails?.allow_full_editing) {
      method = "patch";
      updatedForm = {
        name: form.name,
        curve_group: form.curve_group,
        curve_data: form.curve_data,
      } as ICurveForm;
    }
    const oldCurve = curveDetails;
    const curve = await editCurveCallAPI(updatedForm, curveId, method);
    if (curve) {
      // when curve group is updated navigate to that curve group
      if (oldCurve && oldCurve?.curve_group !== curve.curve_group) {
        navigate(`/configuration/data/curves/${curve.curve_group}/${curveId}`);
      }
      // call curve details api
      getCurveDetails(Number(curveId));
    }
    return curve;
  };

  const handleDeleteCurve = async () => {
    if (curveDetails) {
      const { id } = curveDetails;
      const deletedCurve = await deleteCurveCallAPI(id);
      if (deletedCurve) {
        navigate("/configuration/data/curves");
      }
    }
  };

  const handleOnDelete = () => {
    setConfirmDeleteModalProps({
      open: true,
      title: "Delete Curve",
      description: "Are you sure you want to delete?",
      onConfirm: handleDeleteCurve,
    });
  };

  React.useEffect(() => {
    if (curveId) {
      getCurveDetails(Number(curveId));
    }
  }, [curveId]);

  React.useEffect(() => {
    getUserCallAPI();
  }, []);

  const getCurveDetails = async (curveId: number) => {
    getCurveCallAPI(curveId).then((res) => res && setCurveDetails(res));
  };

  // get date schedules for edit modal when the following field changes in edit curve modal.
  React.useEffect(() => {
    if (editCurveModalOpen) {
      const { periodicity, start_date, term_years } = editCurveForm;
      if (periodicity && start_date && term_years?.toString()) {
        let updatedStartDate = format(
          startOfMonth(new Date(start_date)),
          "MM/dd/yyyy",
        );
        if (periodicity === "AN") {
          updatedStartDate = format(
            startOfYear(new Date(start_date)),
            "MM/dd/yyyy",
          );
        }
        getDateScheduleCallAPI({
          periodicity,
          start_date: updatedStartDate,
          term_years,
        })
          .then((res) => res && setDateScheduleForEditModal(res.schedule_dates))
          .catch(() => null);
      }
    }
  }, [
    editCurveForm.periodicity,
    editCurveForm?.start_date,
    editCurveForm.term_years,
  ]);

  // prepare curve group options
  const curveGroupsOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrganizationCurveGroups?.map((g) => {
      return {
        label: g.name,
        value: String(g.id),
      };
    });
  }, [currentOrganizationCurveGroups]);

  return (
    <ViewWrapper loading={loadingGetCurve} error={getCurveFailed}>
      <Box className={cn("my-4")}>
        <IconButton
          onClick={() =>
            navigate(
              `/configuration/data/curves?curve_group=${curveDetails?.curve_group}`,
            )
          }
        >
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box className={cn("flex justify-between items-center my-4")}>
        <Box className={cn("flex flex-col items-start gap-4")}>
          <Chip
            label={
              CURVE_TYPE[curveDetails?.curve_type as keyof typeof CURVE_TYPE]
            }
          />
          <Typography variant="h4">
            {trimString(curveDetails?.name, 30)}
          </Typography>
        </Box>
        {/* condition below to see if created_by is same as this user or has EDITOR permission */}
        <Protect
          condition={(has) => {
            return (
              has({ permission: USER_PERMISSIONS.ORG_CURVE_EDITOR }) ||
              userData?.id === curveDetails?.created_by
            );
          }}
        >
          <Box className={cn("flex gap-4")}>
            <ActionButton
              canOpenUpgrade
              onClick={handleOnDelete}
              actionType="delete"
            >
              Delete
            </ActionButton>
            <ActionButton
              canOpenUpgrade
              onClick={handleOpenEditCurveModal}
              actionType="edit"
            >
              Edit
            </ActionButton>
          </Box>
        </Protect>
      </Box>
      <Box className={cn("grid md:grid-cols-2 gap-4")}>
        {curveDetails && (
          <DetailsCard
            heading="Curve Details"
            autoHeight
            sections={[
              {
                fields: [
                  {
                    label: "Curve Name",
                    value: {
                      text: curveDetails?.name ?? "N/A",
                    },
                  },
                  {
                    label: "Curve Group",
                    value: {
                      text:
                        currentOrganizationCurveGroups?.find(
                          (g) => g.id === curveDetails?.curve_group,
                        )?.name ?? "N/A",
                    },
                  },
                  {
                    label: "Curve Type",
                    value: {
                      text:
                        CURVE_TYPE[
                          curveDetails?.curve_type as keyof typeof CURVE_TYPE
                        ] ?? "N/A",
                    },
                  },
                  {
                    label: "Periodicity",
                    value: {
                      text:
                        GLOBAL_CURVE_TIMING_PERIODICITY[
                          curveDetails?.periodicity as keyof typeof GLOBAL_CURVE_TIMING_PERIODICITY
                        ] ?? "N/A",
                    },
                  },
                  ...(curveDetails?.curve_type !== "IRC"
                    ? [
                        {
                          label:
                            curveDetails?.curve_type === "PC"
                              ? "Price Terms"
                              : "Price Term",
                          value: {
                            text:
                              PRICE_TERM_TYPE[
                                curveDetails?.price_term as keyof typeof PRICE_TERM_TYPE
                              ] ?? "N/A",
                          },
                        },
                      ]
                    : []),
                  {
                    label: "Term",
                    value: {
                      text: (curveDetails?.term_years || 0) + " Yrs",
                    },
                  },
                  {
                    label:
                      curveDetails?.periodicity === "AN"
                        ? "Start Year"
                        : "Start Date",
                    value: {
                      text: curveDetails?.start_date
                        ? curveDetails?.periodicity === "AN"
                          ? format(new Date(curveDetails?.start_date), "yyyy")
                          : format(
                              new Date(curveDetails?.start_date),
                              "MM/dd/yyyy",
                            )
                        : "N/A",
                    },
                  },
                  ...(curveDetails.date_schedule.length > 0
                    ? [
                        {
                          label: "End Date",
                          value: {
                            text: format(
                              new Date(
                                curveDetails?.date_schedule?.at(-1) || 0,
                              ),
                              "MM/dd/yyyy",
                            ),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]}
            height="fit-content"
          />
        )}

        {curveDetails && curveDetails?.date_schedule?.length > 0 && (
          <DetailsCard
            heading="Curve"
            autoHeight
            sections={[
              {
                fields: [
                  {
                    label: (
                      <CurveFieldLabel
                        label={
                          CURVE_TYPE[
                            curveDetails?.curve_type as keyof typeof CURVE_TYPE
                          ]
                        }
                        primaryAction={setShowMoreCurve}
                        value={curveDetails?.curve_data || []}
                        toggled={showMoreCurve}
                      />
                    ),
                    value: {
                      text: (
                        <DateSchedule
                          curve={curveDetails?.curve_data || []}
                          dateSchedule={curveDetails?.date_schedule || []}
                          inUSD={curveDetails?.curve_type === "PC"}
                          toggle={showMoreCurve}
                          handleToggle={setShowMoreCurve}
                          showOnlyYear={curveDetails?.periodicity === "AN"}
                        />
                      ),
                    },
                  },
                ],
              },
            ]}
            height="fit-content"
          />
        )}
      </Box>

      <CurveFormModal
        form={editCurveForm}
        setForm={setEditCurveForm}
        headerLabel="Edit Curve"
        loading={editCurveLoading}
        onClose={handleCloseEditCurveModal}
        onConfirm={onEditCurve}
        open={editCurveModalOpen}
        setFormErrors={SetEditCurveFormErrors}
        formErrors={editCurveFormErrors}
        org_groups={curveGroupsOptions}
        date_schedule={dateScheduleForEditModal}
        loadingDateSchedule={loadingDateSchedule}
        allowEdit={curveDetails?.allow_full_editing}
      />
    </ViewWrapper>
  );
}
