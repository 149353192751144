export const validateEnvironmentVariables = (): void => {
  const requiredEnvVariables = [
    "REACT_APP_NODE_ENV",
    "REACT_APP_SERVER_URL",
    "REACT_APP_CLERK_PUBLISHABLE_KEY",
    "REACT_APP_SENTRY_DSN",
    "REACT_APP_MUI_X_LICENSE_KEY",
  ];

  const missingEnvVariables = requiredEnvVariables.filter(
    (envVariable) => !process.env[envVariable],
  );

  if (missingEnvVariables.length) {
    throw new Error(`Missing: ${missingEnvVariables.join(", ")}`);
  }
};

export function isEnvUatOrProduction() {
  return (
    process.env.REACT_APP_NODE_ENV === "uat" ||
    process.env.REACT_APP_NODE_ENV === "prod"
  );
}
