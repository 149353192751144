import React from "react";

import ProductPlansModal from "../product-plans-modal";
import ConfirmDeleteModal, {
  IConfirmDeleteModalProps,
} from "../confirm-delete-modal";
import { AppContext } from "../../utils/context/app-context";
import {
  CONFIRM_DELETE_MODAL_DEFAULT_PROPS,
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
} from "../../constants";
import {
  DealOutputPageTypes,
  ICurveGroup,
  IDealCase,
  IDealDebt,
  IDealDetails,
  IOrganization,
  IOrganizationCurve,
  IProductUsageDetails,
  IProjectDetails,
} from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

export default function AppContextProvider({ children }: IProps): JSX.Element {
  const [confirmDeleteModalProps, setConfirmDeleteModalProps] =
    React.useState<IConfirmDeleteModalProps>(
      CONFIRM_DELETE_MODAL_DEFAULT_PROPS,
    );
  const [currentProject, setCurrentProject] = React.useState<IProjectDetails>();
  const [currentDeal, setCurrentDeal] = React.useState<IDealDetails>();
  const [currentDealDebt, setCurrentDealDebt] = React.useState<
    IDealDebt | null | undefined
  >();
  const [ctrlPressed, setCtrlPressed] = React.useState<boolean>(false);
  const [shiftPressed, setShiftPressed] = React.useState<boolean>(false);
  const [selectedDealOutputPageTab, setSelectedDealOutputPageTab] =
    React.useState<{
      [k in DealOutputPageTypes | "dealId"]: string;
    }>(DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS);
  const [dealOutputLoaded, setDealOutputLoaded] =
    React.useState<boolean>(false);
  const [casesOfDeal, setCasesOfDeal] = React.useState<IDealCase[]>([]);
  const [contentLoaded, setContentLoaded] = React.useState<boolean>(false);
  const [currentOrganization, setCurrentOrganization] = React.useState<
    IOrganization | undefined
  >();
  const [productUsageDetails, setProductUsageDetails] =
    React.useState<IProductUsageDetails | null>(null);
  const [showUsageLimitHitModal, setShowUsageLimitHitModal] =
    React.useState<boolean>(false);
  const [currentOrganizationCurveGroups, setCurrentOrganizationCurveGroups] =
    React.useState<ICurveGroup[]>([]);
  const [currentOrganizationCurves, setCurrentOrganizationCurves] =
    React.useState<IOrganizationCurve[]>([]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setContentLoaded(Boolean(document.getElementById("content-loaded")));
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <AppContext.Provider
      value={{
        currentProject,
        setCurrentProject,
        currentDeal,
        setCurrentDeal,
        currentDealDebt,
        setCurrentDealDebt,
        confirmDeleteModalProps,
        setConfirmDeleteModalProps,
        ctrlPressed,
        setCtrlPressed,
        shiftPressed,
        setShiftPressed,
        selectedDealOutputPageTab,
        setSelectedDealOutputPageTab,
        dealOutputLoaded,
        setDealOutputLoaded,
        casesOfDeal,
        setCasesOfDeal,
        contentLoaded,
        setContentLoaded,
        currentOrganization,
        setCurrentOrganization,
        productUsageDetails,
        setProductUsageDetails,
        showUsageLimitHitModal,
        setShowUsageLimitHitModal,
        currentOrganizationCurveGroups,
        setCurrentOrganizationCurveGroups,
        currentOrganizationCurves,
        setCurrentOrganizationCurves,
      }}
    >
      <>
        {children}
        <ConfirmDeleteModal {...confirmDeleteModalProps} />
        <ProductPlansModal />
      </>
    </AppContext.Provider>
  );
}
