import Layout from "../../components/layout";
import DashboardView from "../../views/home/dashboard";
import HomePagesLayout from "../../components/home-pages-layout";
import { getProjectsStageReport } from "../../apis/report/project";
import { getDealReportingByStage } from "../../apis/report/deal";
import { getPipelineReportByYear } from "../../apis/report/pipeline";
import { getProjects } from "../../apis/project/base";
import {
  getDashboardCharts,
  getDashboardMetrics,
} from "../../apis/report/dashboard";

export default function DashboardPage(): JSX.Element {
  return (
    <Layout title="Dashboard">
      <HomePagesLayout>
        <DashboardView
          getDealReportingByStage={getDealReportingByStage}
          getProjectsStageReport={getProjectsStageReport}
          getPipelineReportByYear={getPipelineReportByYear}
          getDashboardMetrics={getDashboardMetrics}
          getDashboardCharts={getDashboardCharts}
          getProjects={getProjects}
        />
      </HomePagesLayout>
    </Layout>
  );
}
