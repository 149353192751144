import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import ProductPlanCards from "../product-plan-cards";
import { AppContext } from "../../utils/context/app-context";
import { useNavigate } from "react-router-dom";

export default function ProductPlansModal(): JSX.Element {
  const navigate = useNavigate();

  const { showUsageLimitHitModal, setShowUsageLimitHitModal } =
    React.useContext(AppContext);

  const handleOnClose = () => {
    setShowUsageLimitHitModal(false);
  };

  const goToBillingPage = async () => {
    navigate("/configuration/organization/billing-and-usage");
    setShowUsageLimitHitModal(false);
  };

  return (
    <Modal
      open={showUsageLimitHitModal}
      onClose={handleOnClose}
      heading="Subscribe"
      onConfirm={goToBillingPage}
      textContent="You have hit the limits of your plan, please contact us to subscribe to upgrade."
      cancelBtnLabel="Close"
      confirmBtnLabel="Billing and Usage"
      maxWidth="lg"
    >
      <Box style={{ marginTop: 8 }}>
        <ProductPlanCards />
      </Box>
    </Modal>
  );
}
