import React from "react";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import {
  useAuth,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";

import Header from "../header";
import Sidebar from "../sidebar";
import useStyles from "./styles";
import { useAPI } from "../../utils/hooks";
import { AppContext } from "../../utils/context/app-context";
import { DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS } from "../../constants";
import { getProductUsageDetails } from "../../apis/configuration/base";

interface IProps {
  children: JSX.Element;
  title?: string;
}

export default function Layout({ children, title }: IProps): JSX.Element {
  const styles = useStyles();

  const { signOut } = useAuth();

  const { organization } = useOrganization();
  const { setActive: setActiveOrg, userMemberships } = useOrganizationList({
    userMemberships: true,
  });

  const { pathname } = useLocation();

  const {
    setCtrlPressed,
    setShiftPressed,
    setCurrentDealDebt,
    setCurrentDeal,
    setCurrentProject,
    setDealOutputLoaded,
    setCasesOfDeal,
    setSelectedDealOutputPageTab,
    productUsageDetails,
    setProductUsageDetails,
  } = React.useContext(AppContext);

  const [mobileSidebarOpen, setMobileSidebarOpen] =
    React.useState<boolean>(false);

  const environment = process.env.REACT_APP_NODE_ENV;
  const environmentLabel = React.useMemo(() => {
    switch (environment) {
      case "dev":
        return "Development";
      case "staging":
        return "Staging";
      case "uat":
        return "UAT";
      case "test":
        return "Test";
      default:
        return "";
    }
  }, [environment]);

  const helmetTitle: string = title
    ? `${title} ${
        environment !== "prod" ? `(${environmentLabel}) ` : ""
      }- CapeZero`
    : `CapeZero ${environment !== "prod" ? `(${environmentLabel})` : ""}`;

  React.useEffect(() => {
    if (!organization?.name && !userMemberships.isLoading) {
      if (userMemberships.data?.length && setActiveOrg) {
        setActiveOrg({
          organization: userMemberships.data[0].organization,
        });
        window.location.reload();
      } else {
        signOut();
      }
    }
  }, [organization, userMemberships]);

  React.useEffect(() => {
    if (!productUsageDetails && !erroredGetProductUsageDetails) {
      hadnleGetProductUsageDetails();
    }

    if (!["/deal/", "/project/"].some((i) => pathname.includes(i))) {
      setCurrentProject(undefined);
      setCurrentDeal(undefined);
      setCurrentDealDebt(undefined);
      setCasesOfDeal([]);
      setDealOutputLoaded(false);
      setSelectedDealOutputPageTab({
        ...DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
        dealId: "",
      });
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleFocusBlur);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.addEventListener("blur", handleFocusBlur);
    };
  }, []);

  const {
    callAPI: getProductUsageDetailsCallAPI,
    errored: erroredGetProductUsageDetails,
  } = useAPI(getProductUsageDetails, {
    initialLoading: true,
  });

  const hadnleGetProductUsageDetails = async () => {
    const response = await getProductUsageDetailsCallAPI();
    if (response) {
      setProductUsageDetails(response);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      setCtrlPressed(true);
    }
    if (e.key === "Shift") {
      setShiftPressed(true);
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      setCtrlPressed(false);
    }
    if (e.key === "Shift") {
      setShiftPressed(false);
    }
  };

  const handleFocusBlur = () => {
    setCtrlPressed(false);
  };

  const toggleSidebar = () => {
    setMobileSidebarOpen((s) => !s);
  };

  return (
    <Box className={styles.classes.root}>
      <Helmet title={helmetTitle} />
      <Header toggleSidebar={toggleSidebar} />

      <Box className={styles.classes.container}>
        <Sidebar
          mobileSidebarOpen={mobileSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <Box className={styles.classes.content}>{children}</Box>
      </Box>
    </Box>
  );
}
