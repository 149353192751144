import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { isNumber } from "lodash";
import { Protect } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";

import useStyles from "./styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import EditTaxCreditTransfer from "../../../../components/edit-tax-credit-transfer-form-modal";
import EditDealTaxCreditTransfer from "../../../../components/edit-deal-tax-credit-transfer-form-modal";
import { AppContext } from "../../../../utils/context/app-context";
import { numberToUSD } from "../../../../utils/helpers";
import { useAPI } from "../../../../utils/hooks";
import {
  USER_PERMISSIONS,
  DEAL_TAX_CREDIT_TRANSFER_PERIODICITY,
  DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IDealTaxCreditTransfer,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferResults,
  IDealTaxCreditTransferFormErrors,
  IUpdateDealTaxCreditTranferConstraintsForm,
  IUpdateDealTaxCreditTranferConstraintsFormErrors,
  IDealDetails,
} from "../../../../interfaces";

interface IProps {
  getDealTaxCreditTransferDetails: (
    id: number,
  ) => Promise<IDealTaxCreditTransfer[]>;
  updateDealTaxCreditTransferDetails: (
    dealId: number,
    transferSizingId: number,
    form: IDealTaxCreditTransferForm,
  ) => Promise<IDealTaxCreditTransferResults>;
  updateDeal: (
    dealId: number,
    form: IUpdateDealTaxCreditTranferConstraintsForm,
  ) => Promise<IDealDetails>;
}

export default function DealTaxCreditTransferView({
  getDealTaxCreditTransferDetails,
  updateDealTaxCreditTransferDetails,
  updateDeal,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId, caseDealId } = useParams();

  const { currentDeal, setCurrentDeal } = React.useContext(AppContext);

  const [dealTaxCreditTransfers, setdealTaxCreditTransfer] = React.useState<
    IDealTaxCreditTransfer[]
  >([]);
  const [form, setForm] = React.useState<IDealTaxCreditTransferForm>(
    DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  );

  const [
    taxCreditTransferConstraintsForm,
    setTaxCreditTransferConstraintsForm,
  ] = React.useState<IUpdateDealTaxCreditTranferConstraintsForm>(
    UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  );

  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [openDealEditModal, setOpenDealEditModal] =
    React.useState<boolean>(false);

  const {
    callAPI: getDealTaxCreditDetailsCallAPI,
    errored: getDealProformaFailed,
    loading: loadingDealTaxCreditTransfer,
  } = useAPI((dealId: number) => getDealTaxCreditTransferDetails(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealCallAPI,
    errored: updateDealFailed,
    loading: loadingDealUpdate,
    fieldErrors: dealFormErrors,
    setFieldErrors: setDealFormErrors,
  } = useAPI<IDealDetails, IUpdateDealTaxCreditTranferConstraintsFormErrors>(
    (dealId: number, form: IUpdateDealTaxCreditTranferConstraintsForm) =>
      updateDeal(dealId, form),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateDealTaxCreditTransferDetailsCallAPI,
    loading: loadingUpdateDealTaxCreditTransferDetails,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealTaxCreditTransferResults, IDealTaxCreditTransferFormErrors>(
    (
      dealId: number,
      form: IDealTaxCreditTransferForm,
      transferSizingId: number,
    ) => updateDealTaxCreditTransferDetails(dealId, transferSizingId, form),
  );

  const getDealTaxCreditDetails = (dealId: number) => {
    getDealTaxCreditDetailsCallAPI(dealId).then((response) => {
      response && setdealTaxCreditTransfer(response);
    });
  };

  React.useEffect(() => {
    getDealTaxCreditDetails(Number(caseDealId));
  }, [caseDealId]);

  const rows = [
    {
      key: "Key Terms",
    },
    {
      key: "Tax Credit Type",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.project_credit_type || "N/A",
        ]),
      ),
    },
    {
      key: "Tax Credit",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          numberToUSD.format(Number(d?.tax_credits_without_adders)) || "N/A",
        ]),
      ),
    },
    {
      key: "Tax Credit Adder",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          numberToUSD.format(Number(d?.tax_credits_adders)) || "N/A",
        ]),
      ),
    },
    {
      key: "Tax Credit Total",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          numberToUSD.format(Number(d?.tax_credits_total)) || "N/A",
        ]),
      ),
    },
    {
      key: "Transfer Portion",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          d?.transfer_sizing?.transfer_portion + "%" || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Rate",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          Number(d?.transfer_sizing?.payment_rate).toFixed(4) + " $/Credit" ||
            "N/A",
        ]),
      ),
    },
    {
      key: "Payment Total",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          numberToUSD.format(Number(d?.payment_total)) || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Periodicity",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d?.project?.name,
          DEAL_TAX_CREDIT_TRANSFER_PERIODICITY[
            d?.transfer_sizing
              ?.payment_periodicity as keyof typeof DEAL_TAX_CREDIT_TRANSFER_PERIODICITY
          ] || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Reference Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.payment_reference_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Dates Lag",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.transfer_sizing?.payment_dates_lag + " months",
        ]),
      ),
    },
    {
      key: "Payment Date Num 1",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.payment_date_num_1), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Key Dates",
    },
    {
      key: "Placed in Service Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.placed_in_service_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Pre-Filing Date (per project)",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(new Date(d?.transfer_sizing?.pre_filing_date), "M/dd/yyyy") ||
            "N/A",
        ]),
      ),
    },
    {
      key: "Seller's Tax Filing Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(
            new Date(d?.transfer_sizing?.tct_seller_tax_filing_date) || "N/A",
            "M/dd/yyyy",
          ),
        ]),
      ),
    },
    {
      key: "Buyer's Tax Filing Date",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          format(
            new Date(d?.transfer_sizing?.tct_buyer_tax_filing_date) || "N/A",
            "M/dd/yyyy",
          ),
        ]),
      ),
    },
    {
      key: "Seller's Tax Year Num 1",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.transfer_sizing?.tct_sellers_tax_year_num_1,
        ]),
      ),
    },
    {
      key: "Buyer's Tax Year Num 1",
      ...Object.fromEntries(
        dealTaxCreditTransfers.map((d) => [
          d.project.name,
          d?.transfer_sizing?.tct_buyers_tax_year_num_1,
        ]),
      ),
    },
  ];

  const columns = dealTaxCreditTransfers.map((d) => {
    return {
      name: d.project.name,
      id: d.transfer_sizing?.id,
      projectId: d?.project?.id,
    };
  });

  const openDealTaxCreditEditModal = (id: number) => {
    setOpenEditModal(true);
    const dealTaxCreditTransfer = dealTaxCreditTransfers.find((d) => {
      return d.transfer_sizing.id === id;
    });
    setForm({
      id: String(id),
      payment_dates_lag:
        dealTaxCreditTransfer?.transfer_sizing?.payment_dates_lag ?? "",
      payment_date_num_1: dealTaxCreditTransfer?.payment_date_num_1 ?? "",
      payment_periodicity:
        dealTaxCreditTransfer?.transfer_sizing?.payment_periodicity ?? "",
      payment_rate: dealTaxCreditTransfer?.transfer_sizing?.payment_rate ?? "",
      placed_in_service_date:
        dealTaxCreditTransfer?.placed_in_service_date ?? "",
      pre_filing_date:
        dealTaxCreditTransfer?.transfer_sizing?.pre_filing_date ?? "",
      transfer_portion:
        dealTaxCreditTransfer?.transfer_sizing?.transfer_portion ?? "",
      isPTC: dealTaxCreditTransfer?.project_credit_type === "PTC",
      isITC: dealTaxCreditTransfer?.project_credit_type === "ITC",
      creditStartDate: "itc start date",
      creditEndDate: "itc end date",
    });
  };

  const openDealTaxCreditPortfolioEditModal = () => {
    setOpenDealEditModal(true);
    setTaxCreditTransferConstraintsForm({
      transfer_maximum_payment: currentDeal?.transfer_maximum_payment ?? "",
      transfer_minimum_roi: currentDeal?.transfer_minimum_roi ?? "",
      transfer_discount_rate: currentDeal?.transfer_discount_rate ?? "",
    });
  };

  const handleEditDealTaxCreditTransfer = async (
    form: IDealTaxCreditTransferForm,
  ) => {
    const updatedForm = {
      payment_dates_lag: form.payment_dates_lag,
      payment_date_num_1: form.payment_date_num_1,
      payment_periodicity: form.isPTC ? form.payment_periodicity : null,
      payment_rate: form.payment_rate,
      placed_in_service_date: form.placed_in_service_date,
      pre_filing_date: form.pre_filing_date,
      transfer_portion: form.transfer_portion,
    };
    const { id } = form;
    const dealTaxCreditTransfer =
      await updateDealTaxCreditTransferDetailsCallAPI(
        Number(caseDealId),
        updatedForm,
        Number(id),
      );
    dealTaxCreditTransfer && getDealTaxCreditDetails(Number(caseDealId));
    dealTaxCreditTransfer && setOpenEditModal(false);

    return dealTaxCreditTransfer;
  };

  const handleEditDealTaxCreditTransferConstraint = async (
    form: IUpdateDealTaxCreditTranferConstraintsForm,
  ) => {
    const updatedDeal = await updateDealCallAPI(Number(caseDealId), form);
    updatedDeal && setCurrentDeal(updatedDeal);
    updatedDeal && setOpenDealEditModal(false);
    return updatedDeal;
  };

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  return (
    <>
      <ViewWrapper
        loading={loadingDealTaxCreditTransfer}
        error={getDealProformaFailed}
      >
        <Box>
          {dealTaxCreditTransfers.length === 0 ? (
            <Box className={styles.classes.message}>
              <Typography variant="body1" className={styles.classes.message}>
                This Deal does not have any Projects that have elected Tax
                Credit Transfer.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Grid container marginBottom={2}>
                <Grid item xs={12} sm={6}>
                  <DetailsCard
                    heading="Portfolio Settings"
                    sections={[
                      {
                        heading: "Additional Parameters",
                        fields: [
                          {
                            label: "Discount Rate",
                            value: {
                              text: isNumber(
                                currentDeal?.transfer_discount_rate,
                              )
                                ? currentDeal?.transfer_discount_rate + "%"
                                : "N/A",
                            },
                          },
                        ],
                      },
                      {
                        heading: "Constraints",
                        fields: [
                          {
                            label: "Minimum ROI",
                            value: {
                              text: isNumber(currentDeal?.transfer_minimum_roi)
                                ? currentDeal?.transfer_minimum_roi + "%"
                                : "N/A",
                            },
                          },
                          {
                            label: "Maximum Payment",
                            value: {
                              text: isNumber(
                                currentDeal?.transfer_maximum_payment,
                              )
                                ? numberToUSD.format(
                                    currentDeal?.transfer_maximum_payment ?? 0,
                                  )
                                : "N/A",
                            },
                          },
                        ],
                      },
                    ]}
                    onEditClick={
                      !isOnCase
                        ? openDealTaxCreditPortfolioEditModal
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead className={styles.classes.header}>
                      <TableRow>
                        <TableCell></TableCell>
                        {columns.map((c, idx) => {
                          return (
                            <TableCell key={idx}>
                              <Link
                                to={`/project/${c?.projectId}/tax/tax-credit`}
                                target="_blank"
                              >
                                {c?.name}
                              </Link>
                              <br />
                              {!isOnCase && (
                                <Protect
                                  permission={USER_PERMISSIONS.DEALS_CRUD}
                                >
                                  <Button
                                    canOpenUpgrade
                                    btnType="primary"
                                    label="Edit"
                                    size="small"
                                    startIcon={<EditIcon />}
                                    onClick={() =>
                                      openDealTaxCreditEditModal(c?.id)
                                    }
                                  />
                                </Protect>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map((row: { [key: string]: string }, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                fontWeight: ["Key Dates", "Key Terms"].includes(
                                  row?.key,
                                )
                                  ? "bold"
                                  : "",
                              }}
                            >
                              {row.key}
                            </TableCell>
                            {columns.map((c, sIdx) => {
                              return (
                                <TableCell key={sIdx}>{row[c?.name]}</TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Box>
      </ViewWrapper>

      <EditTaxCreditTransfer
        headerLabel="Edit Tax Credit Transfer"
        loading={loadingUpdateDealTaxCreditTransferDetails}
        form={form}
        setForm={setForm}
        onClose={() => setOpenEditModal(false)}
        open={openEditModal}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        onConfirm={handleEditDealTaxCreditTransfer}
      />

      <EditDealTaxCreditTransfer
        headerLabel="Edit Portfolio Tax Credit Transfer"
        loading={loadingUpdateDealTaxCreditTransferDetails}
        form={taxCreditTransferConstraintsForm}
        setForm={setTaxCreditTransferConstraintsForm}
        onClose={() => setOpenDealEditModal(false)}
        open={openDealEditModal}
        formErrors={dealFormErrors}
        setFormErrors={setDealFormErrors}
        onConfirm={handleEditDealTaxCreditTransferConstraint}
      />
    </>
  );
}
