import React from "react";

import { IConfirmDeleteModalProps } from "../../components/confirm-delete-modal";
import {
  DealOutputPageTypes,
  ICurveGroup,
  IDealCase,
  IDealDebt,
  IDealDetails,
  IOrganization,
  IOrganizationCurve,
  IProductUsageDetails,
  IProjectDetails,
  SetStateAction,
} from "../../interfaces";
import {
  CONFIRM_DELETE_MODAL_DEFAULT_PROPS,
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
} from "../../constants";

interface IAppContext {
  currentProject: IProjectDetails | undefined;
  setCurrentProject: SetStateAction<IProjectDetails | undefined>;
  currentDeal: IDealDetails | undefined;
  setCurrentDeal: SetStateAction<IDealDetails | undefined>;
  currentDealDebt: IDealDebt | null | undefined;
  setCurrentDealDebt: SetStateAction<IDealDebt | null | undefined>;
  confirmDeleteModalProps: IConfirmDeleteModalProps;
  setConfirmDeleteModalProps: SetStateAction<IConfirmDeleteModalProps>;
  ctrlPressed: boolean;
  setCtrlPressed: SetStateAction<boolean>;
  shiftPressed: boolean;
  setShiftPressed: SetStateAction<boolean>;
  selectedDealOutputPageTab: {
    [k in DealOutputPageTypes | "dealId"]: string;
  };
  setSelectedDealOutputPageTab: SetStateAction<{
    [k in DealOutputPageTypes | "dealId"]: string;
  }>;
  dealOutputLoaded: boolean;
  setDealOutputLoaded: SetStateAction<boolean>;
  casesOfDeal: IDealCase[];
  setCasesOfDeal: SetStateAction<IDealCase[]>;
  contentLoaded: boolean;
  setContentLoaded: SetStateAction<boolean>;
  currentOrganization: IOrganization | undefined;
  setCurrentOrganization: SetStateAction<IOrganization | undefined>;
  productUsageDetails: IProductUsageDetails | null;
  setProductUsageDetails: SetStateAction<IProductUsageDetails | null>;
  showUsageLimitHitModal: boolean;
  setShowUsageLimitHitModal: SetStateAction<boolean>;
  currentOrganizationCurveGroups: ICurveGroup[];
  setCurrentOrganizationCurveGroups: SetStateAction<ICurveGroup[]>;
  currentOrganizationCurves: IOrganizationCurve[];
  setCurrentOrganizationCurves: SetStateAction<IOrganizationCurve[]>;
}

export const AppContext = React.createContext<IAppContext>({
  currentProject: undefined,
  setCurrentProject: () => undefined,
  currentDeal: undefined,
  setCurrentDeal: () => undefined,
  currentDealDebt: undefined,
  setCurrentDealDebt: () => undefined,
  confirmDeleteModalProps: CONFIRM_DELETE_MODAL_DEFAULT_PROPS,
  setConfirmDeleteModalProps: () => undefined,
  ctrlPressed: false,
  setCtrlPressed: () => undefined,
  shiftPressed: false,
  setShiftPressed: () => undefined,
  selectedDealOutputPageTab: DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
  setSelectedDealOutputPageTab: () => undefined,
  dealOutputLoaded: false,
  setDealOutputLoaded: () => undefined,
  casesOfDeal: [],
  setCasesOfDeal: () => undefined,
  contentLoaded: false,
  setContentLoaded: () => undefined,
  currentOrganization: undefined,
  setCurrentOrganization: () => undefined,
  productUsageDetails: null,
  setProductUsageDetails: () => undefined,
  showUsageLimitHitModal: false,
  setShowUsageLimitHitModal: () => undefined,
  currentOrganizationCurveGroups: [],
  setCurrentOrganizationCurveGroups: () => undefined,
  currentOrganizationCurves: [],
  setCurrentOrganizationCurves: () => undefined,
});
