import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { ITableRow, ITableColumn } from "../../interfaces";

interface IProps {
  columns: ITableColumn[];
  rows: ITableRow[];
  fullWidth?: boolean;
  hideCols?: string[];
  ignore?: string[];
  boldTotal?: boolean;
  hideZeroesColumn?: boolean; // New prop to hide columns with all zero values
  stickyTotal?: boolean;
}

export default function ReportTable({
  columns,
  rows,
  fullWidth,
  hideCols = [],
  ignore = [],
  boldTotal,
  hideZeroesColumn, // Default value for hideZeroes is false
  stickyTotal,
}: IProps): JSX.Element {
  const styles = useStyles({ fullWidth: Boolean(fullWidth) });

  // Function to check if all values in a column are zeros
  const hasColumnOnlyZeroes = (columnIndex: number) => {
    for (const row of rows) {
      if (row.cells[columnIndex]?.value != 0) {
        return false;
      }
    }
    return true;
  };

  // filter out columns which are passed in hideCols
  columns = columns.filter((col) => !hideCols.includes(col.id));

  // Filter out columns with all zero values if hideZeroes is true
  const filteredColumns = hideZeroesColumn
    ? columns.filter((_, index) => !hasColumnOnlyZeroes(index))
    : columns;

  return (
    <Paper classes={{ root: styles.classes.tablePaper }}>
      <TableContainer
        classes={{ root: stickyTotal ? styles.classes.tableContainer : "" }}
      >
        <Table stickyHeader className={styles.classes.table}>
          <TableHead className={styles.classes.header}>
            <TableRow>
              {filteredColumns.map((column, idx) => (
                <TableCell
                  key={idx}
                  align={column.align as "right" | "left"}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: column.fontLight ? "normal" : "bold",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              if (ignore.includes(String(row.cells[0]?.value))) {
                return undefined;
              }
              const boldRow =
                boldTotal && (idx === 0 || idx === rows.length - 1);
              return (
                <TableRow
                  className={
                    stickyTotal && idx === 0 ? styles.classes.sticky : ""
                  }
                  hover
                  key={idx}
                  tabIndex={-1}
                >
                  {row.cells.map((cell, cIdx) => {
                    // Skip rendering cell if corresponding column is filtered out
                    if (hideZeroesColumn && hasColumnOnlyZeroes(cIdx)) {
                      return undefined;
                    }
                    return (
                      <TableCell
                        key={cIdx}
                        align={cell.align}
                        style={{
                          fontWeight: cell.bold || boldRow ? "bold" : "normal",
                          borderBottom: cell.borderBottom
                            ? cell.borderBottom
                            : "",
                        }}
                      >
                        {cell.icon?.description ? (
                          <>
                            <>{cell.value}</>
                            <Tooltip title={cell.icon?.description}>
                              <IconButton
                                className={styles.classes.iconButton}
                                size="small"
                              >
                                <InfoIcon fontSize="small" color="warning" />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : cell.value === "undefined%" ||
                          cell.value === "NaN" ? (
                          ""
                        ) : (
                          cell.value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
