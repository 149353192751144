import React from "react";
import { AxiosError } from "axios";

import { AppContext } from "../context/app-context";
import { CONFIRM_DELETE_MODAL_DEFAULT_PROPS } from "../../constants";

interface IError extends AxiosError {
  fieldErrors?: { [k: string]: string | string[] };
}

interface IUseAPIOptions {
  initialLoading?: boolean;
  setConfirmModalLoading?: boolean;
}

interface IUseAPIErrorResponse {
  status?: number;
  data?: any;
}

interface IErrorObj {
  heading?: string;
  description?: string;
}

const getErrorObj = (e?: IError): IErrorObj | undefined => {
  const responseData: any = e?.response?.data;

  if (e?.response?.status === 400 && responseData?.status === "error") {
    return {
      heading: " ",
      description:
        "An error has occurred, please try again in a few minutes or re-check on Deal or Project details.",
    };
  }
  return undefined;
};

export const useAPI = <Response, FieldErrors>(
  apiFunc: (...a: any) => Promise<Response>,
  options?: IUseAPIOptions,
) => {
  const { setConfirmDeleteModalProps } = React.useContext(AppContext);

  const [loading, setLoading] = React.useState<boolean>(
    Boolean(options?.initialLoading),
  );
  const [response, setResponse] = React.useState<Response>();
  const [errored, setErrored] = React.useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = React.useState<FieldErrors>();
  const [errorObj, setErrorObj] = React.useState<IErrorObj>();
  const [errorResponse, setErrorResponse] =
    React.useState<IUseAPIErrorResponse>();
  const [showUsageLimitHitScreen, setShowUsageLimitHitScreen] =
    React.useState<boolean>(false);

  const callAPI = React.useCallback(async (...a: any) => {
    setLoading(true);
    if (options?.setConfirmModalLoading) {
      setConfirmDeleteModalProps((p) => ({ ...p, loading: true }));
    }

    try {
      const res = await apiFunc(...a);
      setResponse(res);

      setConfirmDeleteModalProps(CONFIRM_DELETE_MODAL_DEFAULT_PROPS);

      return res;
    } catch (e: any) {
      const error: IError = e;
      setFieldErrors(error.fieldErrors as FieldErrors);
      setErrorResponse({
        status: error.response?.status,
        data: error.response?.data,
      });

      const errorObj = getErrorObj(error);
      setErrorObj(errorObj);

      setConfirmDeleteModalProps((p) => ({ ...p, loading: false }));

      if (
        e?.response?.status === 429 &&
        e?.response?.data?.status === "capped"
      ) {
        setShowUsageLimitHitScreen(true);
      }

      setErrored(true);
    } finally {
      setLoading(false);
    }
    return undefined;
  }, []);

  return {
    callAPI,
    loading,
    response,
    errored,
    errorResponse,
    fieldErrors,
    setFieldErrors,
    errorObj,
    showUsageLimitHitScreen,
  };
};
