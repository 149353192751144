import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Refresh";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import MuiButton from "@mui/material/Button";
import format from "date-fns/format";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import DealFilters from "../../components/deal-filters";
import ProjectFormModal from "../../components/project-form-modal";
import AddDealFormModal from "../../components/add-deal-form-modal";
import ViewWrapper from "../../components/view-wrapper";
import Searchbar from "../../components/search-bar";
import Button from "../../components/button";
import useStyles from "./styles";
import { AppContext } from "../../utils/context/app-context";
import { useAPI } from "../../utils/hooks";
import {
  DEAL_STAGES,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  ADD_DEAL_FORM_DEFAULT_VAULES,
  DEAL_TAX_CREDIT_TYPE,
  USER_PERMISSIONS,
  PROJECT_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  addProjectCountFieldToDealsList,
  trimString,
  sortArrayOfObjects,
  cn,
} from "../../utils/helpers";
import {
  IAddDealForm,
  IAddDealFormErrors,
  IAddDealResponse,
  IProjectForm,
  IProjectFormErrors,
  IAddUpdateProjectResponse,
  IDeal,
  IGetDealsParams,
  IGetProjectsParams,
  IProject,
  ISelectOption,
  ITableSort,
  ServerPaginatedResponse,
} from "../../interfaces";

const columns = [
  { id: "name", label: "Deal Name", minWidth: 180, align: "left" },
  { id: "stage", label: "Stage", minWidth: 100, align: "left" },
  { id: "structure", label: "Structure", minWidth: 100, align: "left" },
  {
    id: "tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 80,
    align: "left",
  },
  {
    id: "tax_credit_type",
    label: "Tax Credit Type",
    minWidth: 80,
    align: "left",
  },
  { id: "type_string", label: "Project Type(s)", minWidth: 120, align: "left" },
  {
    id: "project_count",
    label: "Number of Projects",
    minWidth: 35,
    align: "left",
  },
  { id: "capacity_ac", label: "Capacity (AC)", minWidth: 50, align: "left" },
  { id: "modified", label: "Last Modified", minWidth: 120, align: "left" },
];

interface IProps {
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
  getProjects: (
    params: IGetProjectsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
  addDeal: (form: IAddDealForm) => Promise<IAddDealResponse>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
}

export default function DealList({
  getDeals,
  getProjects,
  addDeal,
  addProject,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useSearchParams();

  const { ctrlPressed, productUsageDetails, setShowUsageLimitHitModal } =
    React.useContext(AppContext);
  const [deals, setDeals] = React.useState<IDeal[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [projectOptions, setProjectOptions] = React.useState<ISelectOption[]>(
    [],
  );
  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);
  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [searchString, setSearchString] = React.useState<string>("");
  const [addDealModalOpen, setAddDealModalOpen] =
    React.useState<boolean>(false);
  const [addDealFormData, setAddDealFormData] = React.useState<IAddDealForm>(
    ADD_DEAL_FORM_DEFAULT_VAULES,
  );

  const isFiltersApplied = React.useMemo(() => {
    const stage = filterParams.get("stage");
    const structure = filterParams.get("structure");
    const tax_credit_structure = filterParams.get("tax_credit_structure");
    return Boolean(stage || structure || tax_credit_structure);
  }, []);

  const [isFilterOpen, setIsFilterOpen] = React.useState(isFiltersApplied);

  React.useEffect(() => {
    const stage = filterParams.get("stage");
    const structure = filterParams.get("structure");
    const tax_credit_structure = filterParams.get("tax_credit_structure");

    getDealsCallAPI(stage, structure, tax_credit_structure).then((response) => {
      response && setDeals(addProjectCountFieldToDealsList(response.results));
    });
  }, [filterParams]);

  const {
    callAPI: getDealsCallAPI,
    errored: getDealsFailed,
    loading: loadingDeals,
  } = useAPI(
    (stage, structure, tax_credit_structure) =>
      getDeals({ stage, structure, tax_credit_structure }),
    { initialLoading: true },
  );

  const {
    callAPI: addDealCallAPI,
    fieldErrors: addDealFormErrors,
    setFieldErrors: setAddDealFormErrors,
    loading: addDealLoading,
  } = useAPI<IAddDealResponse, IAddDealFormErrors>((form: IAddDealForm) =>
    addDeal(form),
  );

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);

    if (project) {
      setAddDealFormData((prev) => ({
        ...prev,
        project_ids: [...prev.project_ids, String(project.id)],
      }));
      setProjectOptions((prev) => [
        ...prev,
        { label: project.name, value: String(project.id) },
      ]);
    }
    return project;
  };

  const handleAddDeal = async (form: IAddDealForm) => {
    const deal = await addDealCallAPI(form);
    deal && goToDeal(deal.id);
    return deal;
  };

  const filteredBySearchDeals = React.useMemo(() => {
    return deals.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [deals, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredBySearchDeals,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredBySearchDeals, sortTable, page, rowsPerPage],
  );

  const handleGetProjects = async () => {
    const projects = await getProjects({}).catch(() => null);
    if (projects) {
      setProjectOptions(
        projects.results.map((p) => ({ label: p.name, value: String(p.id) })),
      );
    }
  };

  const handleOpenAddDealModal = async () => {
    await handleGetProjects();
    setAddDealModalOpen(true);
  };

  const handleCloseAddDealModal = () => {
    setAddDealModalOpen(false);
  };

  const handleOpenAddProjectModal = () => {
    if (productUsageDetails?.strawman_plan === "TRIAL") {
      setShowUsageLimitHitModal(true);
      return;
    }
    setAddProjectModalOpen(true);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const goToDeal = (dealId: number) => {
    if (ctrlPressed) {
      window.open(`/deal/${dealId}/case-deal/${dealId}/general`, "_blank");
      return;
    }
    navigate(`/deal/${dealId}/case-deal/${dealId}/general`);
  };

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const clearFilter = () => {
    setFilterParams();
    setIsFilterOpen(false);
  };

  const styles = useStyles({ isFilterOpen });

  return (
    <>
      <Box className={cn("p-4")}>
        <Box
          className={cn(
            "flex flex-col md:flex-row md:justify-between md:items-start gap-4 mb-4",
          )}
        >
          <Box className={cn("flex flex-col md:flex-row md:items-start gap-4")}>
            <Searchbar
              searchString={searchString}
              onTextChange={onSearchStringChange}
            />
            <MuiButton
              className={`${styles.classes.button} ${
                isFiltersApplied && styles.classes.activeButton
              }`}
              variant="outlined"
              size="large"
              endIcon={<FilterIcon />}
              onClick={() => setIsFilterOpen((v) => !v)}
            >
              Filters
            </MuiButton>
            {isFilterOpen && (
              <MuiButton
                className={cn("!capitalize")}
                variant="outlined"
                size="large"
                endIcon={<ClearIcon />}
                onClick={clearFilter}
              >
                Clear All
              </MuiButton>
            )}
          </Box>
          <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add Deal"
              onClick={handleOpenAddDealModal}
            />
          </Protect>
        </Box>
        <Box>{isFilterOpen && <DealFilters />}</Box>

        <ViewWrapper loading={loadingDeals} error={getDealsFailed}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer classes={{ root: styles.classes.tableContainer }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {columns.map((column, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left" | "right"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            disabled={column.id === "actions"}
                            active={sortTable.orderBy === column.id}
                            direction={
                              sortTable.orderBy === column.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={columns.length}>
                        {isFiltersApplied
                          ? "No Deals match the filters applied, please clear the filters or add a new Deal"
                          : "There are no Deals in place currently, please add one."}
                      </TableCell>
                    </TableRow>
                  )}
                  {visibleRows.map((deal, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        hover
                        tabIndex={-1}
                        className={cn("cursor-pointer")}
                        onClick={() => goToDeal(deal.id)}
                      >
                        <TableCell align="left" title={deal.name}>
                          {trimString(deal.name, 40)}
                        </TableCell>
                        <TableCell align="left">
                          {DEAL_STAGES[deal?.stage as keyof typeof DEAL_STAGES]}
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_STRUCTURE_TYPE[
                              deal?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                              deal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {
                            DEAL_TAX_CREDIT_TYPE[
                              deal?.tax_credit_type as keyof typeof DEAL_TAX_CREDIT_TYPE
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">{deal.type_string}</TableCell>
                        <TableCell align="left">
                          {deal?.project_count || 0}
                        </TableCell>
                        <TableCell align="left">
                          {deal.capacity_ac} MW
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(deal.modified), "M/d/yyyy")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={deals.length}
              className={cn("bg-table-gray")}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </ViewWrapper>
      </Box>

      <AddDealFormModal
        open={addDealModalOpen}
        formErrors={addDealFormErrors}
        loading={addDealLoading}
        setFormErrors={setAddDealFormErrors}
        form={addDealFormData}
        setForm={setAddDealFormData}
        onClose={handleCloseAddDealModal}
        existingProjects={projectOptions}
        onAdd={handleAddDeal}
        openAddProjectModal={handleOpenAddProjectModal}
      />

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
      />
    </>
  );
}
