import { IUpdateDealTimingForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_TIMING_DEVELOPER_HORIZON {
  DSE = "Develop & Sell-Exit",
  DSD = "Develop & Sell-Down",
  DO = "Develop & Operate",
}

export const DEAL_TIMING_DEVELOPER_HORIZON_OPTIONS = enumToOptions(
  DEAL_TIMING_DEVELOPER_HORIZON,
  ["DSE", "DSD"],
);

export const UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE: IUpdateDealTimingForm = {
  tax_equity_date_1: "",
  developer_horizon: "",
  tct_seller_tax_year_end_month: "",
  tct_buyer_tax_year_end_month: "",
  tct_seller_tax_filing_month: "",
  tct_buyer_tax_filing_month: "",
};
