import React from "react";
import { delay } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import LoaderScreen from "../../../../components/loader-screen";
import { AppContext } from "../../../../utils/context/app-context";

export default function DealOutputConstructionDebtView(): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { selectedDealOutputPageTab } = React.useContext(AppContext);

  React.useEffect(() => {
    delay(() => {
      navigate(
        `${pathname}/${selectedDealOutputPageTab["construction-debt"]}`,
        {
          replace: true,
        },
      );
    }, 200);
  }, []);

  return <LoaderScreen />;
}
