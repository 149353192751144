import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

import useStyles from "./styles";

interface IProps extends CheckboxProps {
  label: string;
  tooltip?: string;
}

export default function CheckboxInput({
  label,
  tooltip,
  ...checkboxProps
}: IProps): JSX.Element {
  const styles = useStyles();

  return (
    <Tooltip
      title={tooltip}
      placement="top-end"
      classes={{ tooltip: styles.classes.tooltip }}
    >
      <FormControlLabel
        control={<Checkbox {...checkboxProps} />}
        label={label}
      />
    </Tooltip>
  );
}
