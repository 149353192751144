import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useLocation, useSearchParams } from "react-router-dom";

import useStyles from "./styles";
import Menu from "../menu";
import CheckboxInput from "../checkbox-input";
import { OutputReportPeriodicityType, ReportTerm } from "../../interfaces";
import {
  OUTPUT_REPORT_PERIODICITY,
  OUTPUT_REPORT_PERIODICITY_OPTIONS,
  OUTPUT_REPORT_TERM,
  OUTPUT_REPORT_TERM_OPTIONS,
} from "../../constants";

interface IProps {
  periodicityType: OutputReportPeriodicityType;
  setPeriodicityType: (periodicityType: OutputReportPeriodicityType) => void;
  buyout: ReportTerm;
  setBuyout: (buyout: ReportTerm) => void;
  showPeriodicity?: boolean;
  downloadReport?: () => void;
  showBuyout?: boolean;
  disableDownload?: boolean;
  showEbo?: boolean;
  showTaxFlip?: boolean;
}

export default function DealOutputFilterFields({
  periodicityType,
  setPeriodicityType,
  buyout,
  setBuyout,
  showPeriodicity,
  downloadReport,
  showBuyout,
  disableDownload,
  showEbo,
  showTaxFlip,
}: IProps): JSX.Element {
  const styles = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const isTermTaxFlip = searchParams.get("term") === "taxflip";
  const isOutputTaxEquityCash = pathname.includes("/output/tax-equity/cash");

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get(
        "periodicity",
      ) as OutputReportPeriodicityType;
      setPeriodicityType(periodicity);
    } else {
      searchParams.append("periodicity", periodicityType);
      setSearchParams(searchParams);
    }

    if (searchParams.has("term")) {
      const buyout = searchParams.get("term") as ReportTerm;
      if (isTermTaxFlip && !isOutputTaxEquityCash) {
        setBuyout("default");
        searchParams.set("term", "default");
        setSearchParams(searchParams);
      } else {
        setBuyout(buyout);
      }
    } else {
      searchParams.append("term", buyout);
      setSearchParams(searchParams);
    }
  }, []);

  const onPeriodicityChange = (v: OutputReportPeriodicityType) => {
    setPeriodicityType(v);
    searchParams.set("periodicity", v);
    setSearchParams(searchParams);
  };

  const onBuyoutChange = (v: ReportTerm) => {
    setBuyout(v);
    searchParams.set("term", v);
    setSearchParams(searchParams);
  };

  return (
    <Box className={styles.classes.filtersContainer}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          width: "50%",
        }}
      >
        {showPeriodicity && (
          <Menu
            selectedItem={
              periodicityType ? OUTPUT_REPORT_PERIODICITY[periodicityType] : ""
            }
            placeholder="Periodicity"
            menuItems={OUTPUT_REPORT_PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () => {
                onPeriodicityChange(o.value as OutputReportPeriodicityType);
              },
            }))}
          />
        )}
        <Menu
          selectedItem={buyout ? OUTPUT_REPORT_TERM[buyout] : ""}
          placeholder="Term"
          menuItems={OUTPUT_REPORT_TERM_OPTIONS.filter((o) => {
            if (o.value === "buyout" && !showBuyout) return false;
            if (o.value === "ebo" && !showEbo) return false;
            if (o.value === "taxflip" && !showTaxFlip) return false;
            return true;
          }).map((o) => ({
            label: o.label,
            onClick: () => {
              onBuyoutChange(o.value as ReportTerm);
            },
          }))}
        />
      </Box>
      {downloadReport && (
        <IconButton
          title="Download Report"
          onClick={downloadReport}
          disabled={disableDownload}
        >
          <DownloadIcon />
        </IconButton>
      )}
    </Box>
  );
}
