import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { format, isValid } from "date-fns";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import DatePicker from "../date-picker";
import SelectInput from "../select-input";
import CheckboxInput from "../checkbox-input";
import SchedulerInput from "../scheduler-input";
import CurrencyInput from "../currency-input";
import AutocompleteField from "../autocomplete-field";
import { AppContext } from "../../utils/context/app-context";
import { cn } from "../../utils/helpers";
import {
  PROJECT_ESCALATOR_BASE_YEAR_OPTIONS,
  PROJECT_REVENUE_CONTRACT_TYPE,
  PROJECT_REVENUE_CONTRACT_TYPE_OPTIONS,
  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES_OPTIONS,
  TERM_END_POINT_TYPES_OPTIONS,
  TERM_INPUT_METHOD_TYPE_OPTIONS,
  START_INPUT_METHOD_TYPE_OPTIONS,
  TERM_START_POINT_TYPES_OPTIONS,
  PROJECT_MERCHANT_PRICE_INPUT_TYPES_OPTIONS,
  PROJECT_REVENUE_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  IProjectRevenueFormErrors,
  IProjectRevenueForm,
  IProjectRevenue,
  ISelectOption,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  dateSchedule: string[];
  formErrors?: IProjectRevenueFormErrors;
  setFormErrors: SetStateAction<IProjectRevenueFormErrors | undefined>;
  form: IProjectRevenueForm;
  setForm: SetStateAction<IProjectRevenueForm>;
  onClose: () => void;
  onConfirm: (
    form: IProjectRevenueForm,
  ) => Promise<IProjectRevenue | undefined>;
  org_curves: ISelectOption[];
}

const NO_CURVES = [{ label: "No Curves", value: "", disabled: true }];

export default function ProjectRevenueContractFormModal({
  open,
  headerLabel,
  loading,
  dateSchedule,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  org_curves,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { currentProject } = React.useContext(AppContext);

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    const { value } = e.target;
    const formDetails = form;

    if (field === "price_input_type") {
      form.price = null;
      form.price_curve = null;
    }
    if (field === "type") {
      if (value === "OTHER") {
        formDetails.differential_method = null;
        formDetails.start_input_method = null;
        formDetails.term_start_point = null;
        formDetails.start_date = null;
        formDetails.term_input_method = null;
        formDetails.term_end_point = null;
        formDetails.term_years = null;
        formDetails.price_input_type = null;
        formDetails.price = null;
        formDetails.price_curve = null;
        formDetails.basis_differential = null;
        formDetails.basis_curve = null;
        formDetails.escalator = null;
        formDetails.base_year = null;
        formDetails.org_curve = null;
        formDetails.volume_percentage = null;
        formDetails.includes_recs = false;
        formDetails.includes_capacity = false;
      }
      if (value === "REC" || value === "CAPACITY" || value === "MERCHANT") {
        formDetails.includes_capacity = false;
        formDetails.includes_recs = false;
      }
      if (value !== "VPPA" && value !== "HEDGE") {
        formDetails.differential_method = null;
        form.basis_differential = null;
        form.basis_curve = null;
      }
    }

    if (field === "differential_method") {
      form.basis_differential = null;
      form.basis_curve = null;
    }

    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleCurveChange = (
    value: (number | string | null)[],
    field: string,
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const revenue = await onConfirm(form);
    revenue && handleOnClose();
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const getAllowedTypes = () => {
    const allowedTypesForBAST = [
      PROJECT_REVENUE_CONTRACT_TYPE.OTHER,
      PROJECT_REVENUE_CONTRACT_TYPE.CAPACITY,
    ];
    return currentProject?.type === "BAST"
      ? PROJECT_REVENUE_CONTRACT_TYPE_OPTIONS.filter((item) =>
          allowedTypesForBAST.includes(item.label),
        ).map((item) => {
          // Change label for Capacity to Tolling Agreement for Battery Storage
          if (item.label === PROJECT_REVENUE_CONTRACT_TYPE.CAPACITY) {
            return { ...item, label: "Tolling Agreement" };
          }
          return item;
        })
      : PROJECT_REVENUE_CONTRACT_TYPE_OPTIONS;
  };

  const handleResetForm = () => {
    setForm(PROJECT_REVENUE_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: cn("!min-w-[50%]") }}
    >
      <Box className={cn("grid lg:grid-cols-2 lg:gap-4")}>
        <Box>
          <SelectInput
            required
            label="Type"
            selected={form.type || ""}
            items={getAllowedTypes()}
            onFocus={() => clearNonTextFieldErrorOnFocus("type")}
            onChange={(e) => handleSelectInputChange(e, "type")}
            error={Boolean(formErrors?.type)}
            helperText={formErrors?.type}
            disabled={loading}
            fullWidth={false}
          />
          <TextInput
            type="string"
            label="Name"
            name="name"
            value={form.name}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.name)}
            helperText={formErrors?.name}
            disabled={loading}
            fullWidth={false}
          />
          {form.type !== "OTHER" ? (
            <>
              <SelectInput
                required
                label="Price Input Type"
                selected={String(form.price_input_type)}
                items={PROJECT_MERCHANT_PRICE_INPUT_TYPES_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("price_input_type")
                }
                onChange={(e) => handleSelectInputChange(e, "price_input_type")}
                error={Boolean(formErrors?.price_input_type)}
                helperText={formErrors?.price_input_type}
                disabled={loading}
                fullWidth={false}
              />
              {form.price_input_type === "CURVE" && (
                <SchedulerInput
                  label={`Price Curve ${
                    form.type === "CAPACITY" ? "($/kW-Month)" : "($/MWh)"
                  }`}
                  name="price_curve"
                  dateSchedule={dateSchedule || []}
                  value={form?.price_curve || []}
                  error={formErrors?.price_curve || ""}
                  clearErrorOnFocus={clearErrorOnFocus}
                  onChange={(v) => handleCurveChange(v, "price_curve")}
                  disabled={loading}
                  formatValue
                  startAdornment="$"
                  endAdornment={`${
                    form.type === "CAPACITY" ? "/kW-Month" : "/MWh"
                  }`}
                />
              )}
              {form.price_input_type === "FIXED" && (
                <CurrencyInput
                  required
                  label="Price"
                  name="price"
                  endAdornment={
                    form.type === "CAPACITY" ? <>/kW-Month</> : <>/MWh</>
                  }
                  value={form?.price?.toString() || ""}
                  onFocus={clearErrorOnFocus}
                  onChange={handleTextChange}
                  error={Boolean(formErrors?.price)}
                  helperText={formErrors?.price}
                  disabled={loading}
                  fullWidth={false}
                />
              )}

              {form.price_input_type === "OC" && (
                <AutocompleteField
                  label="Organization Curve"
                  name="org_curve"
                  onChange={handleSingleAutoCompleteChange}
                  options={org_curves?.length ? org_curves : NO_CURVES}
                  value={String(form.org_curve)}
                  helperText={formErrors?.org_curve}
                  onFocus={() => {
                    clearSelectErrorOnFocus("org_curve");
                  }}
                  disabled={loading}
                  fullWidth={false}
                  tooltip={
                    <Link
                      target="_blank"
                      className={cn("underline")}
                      to="/configuration/data/curves"
                    >
                      Go to curves list
                    </Link>
                  }
                />
              )}

              <TextInput
                required
                isNumeric
                label="Escalator"
                name="escalator"
                endAdornment={<>%</>}
                value={form.escalator}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.escalator)}
                helperText={formErrors?.escalator}
                disabled={loading}
                fullWidth={false}
              />
              <SelectInput
                required
                label="Escalator Base Year"
                selected={String(form.base_year || "")}
                items={PROJECT_ESCALATOR_BASE_YEAR_OPTIONS}
                onFocus={() => clearNonTextFieldErrorOnFocus("base_year")}
                onChange={(e) => handleSelectInputChange(e, "base_year")}
                error={Boolean(formErrors?.base_year)}
                helperText={formErrors?.base_year}
                disabled={loading}
                fullWidth={false}
              />
              <TextInput
                required
                isNumeric
                label="Volume %"
                name="volume_percentage"
                endAdornment={<>%</>}
                value={form.volume_percentage}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.volume_percentage)}
                helperText={formErrors?.volume_percentage}
                disabled={loading}
                fullWidth={false}
              />
              {form.type === "PPA" ||
              form.type === "VPPA" ||
              form.type === "HEDGE" ? (
                <>
                  <CheckboxInput
                    label="Includes RECs"
                    name="includes_recs"
                    checked={form.includes_recs}
                    onChange={handleCheckboxChange}
                    disabled={loading}
                  />
                  <CheckboxInput
                    label="Includes Capacity"
                    name="includes_capacity"
                    checked={form.includes_capacity}
                    onChange={handleCheckboxChange}
                    disabled={loading}
                  />
                </>
              ) : null}
            </>
          ) : (
            <SchedulerInput
              label="Revenue Schedule"
              name="revenue_curve"
              dateSchedule={dateSchedule || []}
              value={form?.revenue_curve || []}
              error={formErrors?.revenue_curve || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={(v) => handleCurveChange(v, "revenue_curve")}
              disabled={loading}
              formatValue
            />
          )}
        </Box>
        <Box>
          <Divider classes={{ root: styles.classes.divider }} textAlign="left">
            Timing
          </Divider>
          <SelectInput
            required
            label="Start Input Method"
            selected={form.start_input_method || ""}
            items={START_INPUT_METHOD_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("start_input_method")}
            onChange={(e) => handleSelectInputChange(e, "start_input_method")}
            error={Boolean(formErrors?.start_input_method)}
            helperText={formErrors?.start_input_method}
            disabled={loading}
            tooltip="Allows you to tie the start of the Revenue Contract to a particular start date (e.g. COD) instead of a fixed specific date"
            fullWidth={false}
          />
          {form.start_input_method === "YR" ? (
            <>
              <DatePicker
                label="Start Date"
                value={form.start_date ? new Date(form.start_date) : null}
                onChange={(v) => handleDateChange(v, "start_date")}
                onOpen={() => clearNonTextFieldErrorOnFocus("start_date")}
                error={Boolean(formErrors?.start_date)}
                helperText={formErrors?.start_date}
                disabled={loading}
                fullWidth={false}
              />
            </>
          ) : form.start_input_method === "SP" ? (
            <SelectInput
              required
              label="Start Point"
              selected={form.term_start_point || ""}
              items={TERM_START_POINT_TYPES_OPTIONS}
              onFocus={() => clearNonTextFieldErrorOnFocus("term_start_point")}
              onChange={(e) => handleSelectInputChange(e, "term_start_point")}
              error={Boolean(formErrors?.term_start_point)}
              helperText={formErrors?.term_start_point}
              disabled={loading}
              fullWidth={false}
            />
          ) : null}

          <SelectInput
            required
            label="Term Input Method"
            selected={form.term_input_method || ""}
            items={TERM_INPUT_METHOD_TYPE_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("term_input_method")}
            onChange={(e) => handleSelectInputChange(e, "term_input_method")}
            error={Boolean(formErrors?.term_input_method)}
            helperText={formErrors?.term_input_method}
            tooltip="Allows you to tie the start of the Revenue Contract to a particular start date (e.g. Project End of Life Date) instead of a fixed specific date"
            disabled={loading}
            fullWidth={false}
          />
          {form.term_input_method === "YR" ? (
            <>
              <TextInput
                required
                isNumeric
                label="Term"
                name="term_years"
                endAdornment={<>Yrs</>}
                value={form.term_years}
                onFocus={clearErrorOnFocus}
                onChange={handleTextChange}
                error={Boolean(formErrors?.term_years)}
                helperText={formErrors?.term_years}
                disabled={loading}
                fullWidth={false}
              />
            </>
          ) : form.term_input_method === "EP" ? (
            <SelectInput
              required
              label="Time Horizon"
              selected={form.term_end_point || ""}
              items={TERM_END_POINT_TYPES_OPTIONS}
              onFocus={() => clearNonTextFieldErrorOnFocus("term_end_point")}
              onChange={(e) => handleSelectInputChange(e, "term_end_point")}
              error={Boolean(formErrors?.term_end_point)}
              helperText={formErrors?.term_end_point}
              disabled={loading}
              fullWidth={false}
            />
          ) : null}
          <TextInput
            required
            isNumeric
            label="Cash Lag"
            name="cash_basis_lag"
            endAdornment={<>Months</>}
            value={form.cash_basis_lag}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.cash_basis_lag)}
            helperText={formErrors?.cash_basis_lag}
            disabled={loading}
            fullWidth={false}
          />
          {form.type === "HEDGE" || form.type === "VPPA" ? (
            <>
              <Divider
                classes={{ root: styles.classes.divider }}
                textAlign="left"
              >
                Other
              </Divider>
              <SelectInput
                required
                label="Basis Differential Method"
                selected={form.differential_method || ""}
                items={PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("differential_method")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "differential_method")
                }
                error={Boolean(formErrors?.differential_method)}
                helperText={formErrors?.differential_method}
                disabled={loading}
                fullWidth={false}
              />
            </>
          ) : null}
          {(form.type === "HEDGE" || form.type === "VPPA") &&
          form.differential_method === "BDP" ? (
            <CurrencyInput
              required
              label="Basis Differential"
              name="basis_differential"
              endAdornment={<>/MWh</>}
              value={form.basis_differential?.toString() || ""}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.basis_differential)}
              helperText={formErrors?.basis_differential}
              disabled={loading}
              fullWidth={false}
              tooltip="Enter negative numbers when node price is less than hub price. \nPositive basis indicated node price is greater than hub price."
            />
          ) : null}
          {(form.type === "HEDGE" || form.type === "VPPA") &&
          form.differential_method === "BDC" ? (
            <SchedulerInput
              label="Basis Curve"
              name="basis_curve"
              dateSchedule={dateSchedule || []}
              value={form?.basis_curve || []}
              error={formErrors?.basis_curve || ""}
              clearErrorOnFocus={clearErrorOnFocus}
              onChange={(v) => handleCurveChange(v, "basis_curve")}
              disabled={loading}
              tooltip="Enter negative numbers when node price is less than hub price. \nPositive basis indicated node price is greater than hub price."
              startAdornment="$"
              endAdornment=" / MWh"
            />
          ) : null}
        </Box>
      </Box>
    </Modal>
  );
}
