import React from "react";
import Box from "@mui/material/Box";
import LogsIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import ProjectCostChart from "./chart";
import Tabs from "../../../../components/tabs";
import Logs from "../../../../components/logs";
import LogsWrapper from "../../../../components/logs-wrapper";
import ViewWrapper from "../../../../components/view-wrapper";
import ChartWrapper from "../../../../components/chart-wrapper";
import ProjectCostDetailChart from "./detail-chart";
import ReportTable from "../../../../components/report-table";
import ProjectCostFormModal from "../../../../components/project-cost-form-modal";
import Typography from "@mui/material/Typography";
import { useAPI } from "../../../../utils/hooks";
import { useLogs } from "../../../../utils/hooks/logs.hooks";
import { useDrawer } from "../../../../utils/hooks/drawer.hooks";
import { downloadProjectCostsReport } from "../../../../apis/report/project";
import {
  generateProjectCostScheduleColumns,
  numberToUSD,
} from "../../../../utils/helpers";
import {
  cn,
  generateProjectCostScheduleDetailsColumns,
  generateProjectCostScheduleDetailsRows,
  generateProjectCostScheduleRows,
} from "../../../../utils/helpers";
import {
  PROJECT_COST_REPORT_TYPE,
  PROJECT_COST_TYPE,
  USER_PERMISSIONS,
} from "../../../../constants";
import {
  ITableColumn,
  IProjectCostSummaryResponse,
  IProjectCostForm,
  IProjectCostFormErrors,
  ITab,
  IProjectCostSchduleResponse,
  ILogsConfiguration,
} from "../../../../interfaces";

export const PROJECT_COST_TABLE_COLUMNS: ITableColumn[] = [
  { id: "category", label: "Category", minWidth: 80, align: "left" },
  { id: "cost", label: "Cost ($)", minWidth: 80, align: "left" },
  {
    id: "cost_per_capacity",
    label: "Cost ($/Wdc)",
    minWidth: 80,
    align: "left",
  },
  { id: "cost_percentage", label: "Cost (%)", minWidth: 80, align: "left" },
];

const DASHBOARD_TABS: ITab[] = [
  { label: "Summary", value: "cost_summary" },
  { label: "Detail", value: "cost_detail" },
];

interface IProps {
  getProjectCostSummary: (
    projectId: number,
  ) => Promise<IProjectCostSummaryResponse>;
  updateProjectCosts: (
    form: { profit_percent: number },
    projectId: number,
    costId: number,
  ) => Promise<unknown>;
  getProjectCostsSchedule: (
    projectId: number,
    type: keyof typeof PROJECT_COST_REPORT_TYPE,
  ) => Promise<IProjectCostSchduleResponse>;
}

export default function ProjectCostView({
  getProjectCostSummary,
  updateProjectCosts,
  getProjectCostsSchedule,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { projectId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<
    "cost_summary" | "cost_detail"
  >("cost_summary");
  const [projectCostSchedule, setProjectCostSchedule] =
    React.useState<IProjectCostSchduleResponse>();
  const [projectCostSummary, setProjectCostSummary] =
    React.useState<IProjectCostSummaryResponse>();
  const [projectCostForm, setProjectCostForm] =
    React.useState<IProjectCostForm>({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });
  const [updateProjectCostModalOpen, setUpdateProjectCostModalOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    getProjectCostSummaryData();
    getProjectCostScheduleCallAPI(projectId, selectedTab).then((res) => {
      res && setProjectCostSchedule(res);
    });
  }, [projectId, selectedTab]);

  const getProjectCostSummaryData = () => {
    getProjectCostSummaryCallAPI(Number(projectId)).then((response) => {
      response && setProjectCostSummary(response);
    });
  };

  const {
    callAPI: getProjectCostSummaryCallAPI,
    errored: getCostSummaryFailed,
    loading: loadingCostSummary,
  } = useAPI((projectId: number) => getProjectCostSummary(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectCostScheduleCallAPI,
    errored: getCostScheduleFailed,
    loading: loadingCostSchedule,
  } = useAPI(
    (projectId: number, type: keyof typeof PROJECT_COST_REPORT_TYPE) =>
      getProjectCostsSchedule(projectId, type),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateProjecCostCallAPI,
    loading: loadingUpdateProjecCost,
    fieldErrors: updateProjecCostFormErrors,
    setFieldErrors: setUpdateProjecCostFormErrors,
  } = useAPI<unknown, IProjectCostFormErrors>(
    (form, projectId: number, costId: number) =>
      updateProjectCosts(form, projectId, costId),
  );

  const handleOpenUpdateCostModalOpen = () => {
    setUpdateProjectCostModalOpen(true);
    setProjectCostForm({
      fmv_step_up_input_type:
        projectCostSummary?.data.fmv_step_up_input_type || "",
      profit_percent:
        projectCostSummary?.data.fmv_step_up_percentage.toString() || "",
      fmv_step_up: projectCostSummary?.data.fmv_step_up_amount.toString() || "",
    });
  };
  const handleCloseUpdateCostModalOpen = () => {
    setUpdateProjectCostModalOpen(false);
  };

  const onEditProjectCost = async (form: IProjectCostForm) => {
    const res = await updateProjecCostCallAPI(
      form,
      projectId,
      projectCostSummary?.data.project_cost_id,
    );
    res && getProjectCostSummaryData();
    return res;
  };

  const handleSelectTab = (tab: ITab) => {
    setSelectedTab(tab.value as "cost_summary" | "cost_detail");
  };

  const rows = React.useMemo(() => {
    if (projectCostSchedule) {
      if (selectedTab === "cost_summary") {
        return generateProjectCostScheduleRows(projectCostSchedule?.data);
      }
      return generateProjectCostScheduleDetailsRows(projectCostSchedule.data);
    }
    return [];
  }, [projectCostSchedule]);

  const downloadCostReport = async () => {
    setIsDownloading(true);
    await downloadProjectCostsReport(Number(projectId), "cost_detail").catch(
      () => null,
    );
    setIsDownloading(false);
  };

  const projectCostLogConfiguration: ILogsConfiguration = {
    id: Number(projectCostSummary?.data?.project_cost_id),
    type: "projectcost",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectCostLogConfiguration.type,
      projectCostLogConfiguration.id,
    );
  };

  return (
    <>
      <Tabs
        onClick={handleSelectTab}
        tabs={DASHBOARD_TABS}
        selectedTab={selectedTab}
      />

      <ViewWrapper loading={loadingCostSummary} error={getCostSummaryFailed}>
        <>
          {projectCostSummary && (
            <Box>
              <Box className={styles.classes.header}>
                <Box className={cn("flex items-center my-2")}>
                  <IconButton onClick={handleOnOpenLogs}>
                    <LogsIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box className={styles.classes.content}>
                {selectedTab === "cost_summary" && (
                  <Box className={cn("flex flex-col gap-4")}>
                    <TableContainer component={Paper}>
                      <Table classes={{ root: styles.classes.table }}>
                        <TableHead>
                          <TableRow>
                            {PROJECT_COST_TABLE_COLUMNS.map((col, idx) => (
                              <TableCell key={idx}>{col.label}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projectCostSummary.data.cost_items.map(
                            (row, idx) => (
                              <TableRow key={idx}>
                                <TableCell>
                                  <Link
                                    component="button"
                                    variant="body2"
                                    onClick={() => {
                                      navigate(
                                        `/project/${projectId}/cost-inputs`,
                                      );
                                    }}
                                    classes={{ root: styles.classes.link }}
                                  >
                                    {
                                      PROJECT_COST_TYPE[
                                        row.cost_type as keyof typeof PROJECT_COST_TYPE
                                      ]
                                    }
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {numberToUSD.format(row.cost_in_dollars)}
                                </TableCell>
                                <TableCell>
                                  {numberToUSD.format(
                                    row.cost_in_dollars_per_watt,
                                  )}
                                </TableCell>
                                <TableCell>
                                  {row.cost_percentage.toFixed(2) + "%"}
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                          <TableRow className={styles.classes.boldRow}>
                            <TableCell>Total Project Cost</TableCell>
                            <TableCell>
                              {numberToUSD.format(
                                projectCostSummary.data.total_cost,
                              )}
                            </TableCell>
                            <TableCell>
                              {numberToUSD.format(
                                projectCostSummary.data.total_cost_per_watt,
                              )}
                            </TableCell>
                            <TableCell>
                              {Number(100).toFixed(2) + "%"}
                            </TableCell>
                            {/* assuming total project cost to be 100% */}
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              FMV Step Up Percent
                              <Protect
                                permission={USER_PERMISSIONS.PROJECTS_CRUD}
                              >
                                <IconButton
                                  onClick={handleOpenUpdateCostModalOpen}
                                >
                                  <EditIcon
                                    classes={{
                                      root: cn("ml-1 text-secondary"),
                                    }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Protect>
                            </TableCell>
                            <TableCell>
                              {projectCostSummary.data.fmv_step_up_percentage +
                                "%"}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              FMV Step Up Amount
                              <Protect
                                permission={USER_PERMISSIONS.PROJECTS_CRUD}
                              >
                                <IconButton
                                  onClick={handleOpenUpdateCostModalOpen}
                                >
                                  <EditIcon
                                    classes={{
                                      root: cn("ml-1 text-secondary"),
                                    }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Protect>
                            </TableCell>
                            <TableCell>
                              {numberToUSD.format(
                                projectCostSummary.data.fmv_step_up_amount,
                              )}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>FMV</TableCell>
                            <TableCell>
                              {numberToUSD.format(projectCostSummary.data.fmv)}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box className={styles.classes.chartContainer}>
                      {projectCostSummary && (
                        <ChartWrapper title="Cost Components" fontWeight="bold">
                          <ProjectCostChart data={projectCostSummary?.data} />
                        </ChartWrapper>
                      )}
                    </Box>
                    <ViewWrapper
                      loading={loadingCostSchedule}
                      error={getCostScheduleFailed}
                    >
                      <Paper elevation={2} classes={{ root: cn("p-2") }}>
                        <Box
                          className={cn(
                            "flex justify-between items-center mb-2",
                          )}
                        >
                          <Typography variant="h6" fontWeight="bold">
                            Cost Schedule
                          </Typography>
                          <IconButton
                            disabled={isDownloading}
                            onClick={downloadCostReport}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Box>
                        <ReportTable
                          columns={generateProjectCostScheduleColumns(
                            projectCostSchedule?.data,
                          )}
                          rows={rows}
                          fullWidth
                          stickyTotal
                          boldTotal
                        />
                      </Paper>
                    </ViewWrapper>
                    {projectCostSchedule && (
                      <ChartWrapper title="">
                        <ProjectCostDetailChart
                          data={projectCostSchedule?.data}
                        />
                      </ChartWrapper>
                    )}
                  </Box>
                )}
                {selectedTab === "cost_detail" && (
                  <Paper classes={{ root: cn("p-2") }}>
                    <Box
                      className={cn("flex justify-between items-center mb-2")}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        Cost Schedule Detail
                      </Typography>
                      <IconButton
                        disabled={isDownloading}
                        onClick={downloadCostReport}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                    <Box className={cn("flex flex-col gap-4")}>
                      <ViewWrapper
                        loading={loadingCostSchedule}
                        error={getCostScheduleFailed}
                      >
                        <ReportTable
                          columns={generateProjectCostScheduleDetailsColumns(
                            projectCostSchedule?.data,
                            "Project Cost",
                          )}
                          rows={rows}
                          fullWidth
                          stickyTotal
                        />
                      </ViewWrapper>
                    </Box>
                  </Paper>
                )}
              </Box>
            </Box>
          )}
        </>
      </ViewWrapper>

      <ProjectCostFormModal
        form={projectCostForm}
        setForm={setProjectCostForm}
        headerLabel="Edit FMV Step Up"
        loading={loadingUpdateProjecCost}
        onClose={handleCloseUpdateCostModalOpen}
        onConfirm={onEditProjectCost}
        open={updateProjectCostModalOpen}
        setFormErrors={setUpdateProjecCostFormErrors}
        formErrors={updateProjecCostFormErrors}
      />

      <LogsWrapper
        label="Activity"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
      >
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectCostLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectCostLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
