import { ThemeProvider } from "@mui/material";

import ThemeContext from "../../utils/context/theme-context";
import { useLocalStorage } from "../../utils/hooks";
import { darkTheme, lightTheme } from "../../utils/theme";

interface IProps {
  children: JSX.Element[];
}

export default function ThemeContextProvider({ children }: IProps) {
  const [darkMode, setDarkMode] = useLocalStorage<boolean>("cz-theme", false);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}
