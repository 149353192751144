import TabbedLayout from "../../../components/tabbed-layout";
import DealPagesLayout from "../../../components/deal-pages-layout";
import DealTaxCreditTransferView from "../../../views/deal/sizing/transfer";
import { updateDealTransferConstraints } from "../../../apis/deal/base";
import {
  getDealTaxCreditTransferDetails,
  updateDealTaxCreditTransferDetails,
} from "../../../apis/deal/tax-credit";

export default function Transfer(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealTaxCreditTransferView
          getDealTaxCreditTransferDetails={getDealTaxCreditTransferDetails}
          updateDealTaxCreditTransferDetails={
            updateDealTaxCreditTransferDetails
          }
          updateDeal={updateDealTransferConstraints}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
