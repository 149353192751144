import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import LinearProgress from "@mui/material/LinearProgress";

import useStyles from "./styles";
import ViewWrapper from "../../../../components/view-wrapper";
import ProductPlanCards from "../../../../components/product-plan-cards";
import { IProductUsageDetails } from "../../../../interfaces";
import { PRODUCT_PLANS } from "../../../../constants";
import { useAPI } from "../../../../utils/hooks";
import { AppContext } from "../../../../utils/context/app-context";
import { numberWithCommas } from "../../../../utils/helpers";
import { useOrganization } from "@clerk/clerk-react";

interface IProps {
  getProductUsageDetails: () => Promise<IProductUsageDetails>;
}

export default function ConfigurationOrganizationBillingAndUsageView({
  getProductUsageDetails,
}: IProps) {
  const styles = useStyles();

  const { organization } = useOrganization();

  const { productUsageDetails, setProductUsageDetails } =
    React.useContext(AppContext);

  React.useEffect(() => {
    hadnleGetProductUsageDetails();
  }, []);

  const {
    callAPI: getProductUsageDetailsCallAPI,
    errored: erroredGetProductUsageDetails,
    loading: loadingGetProductUsageDetails,
  } = useAPI(getProductUsageDetails, {
    initialLoading: true,
  });

  const hadnleGetProductUsageDetails = async () => {
    const response = await getProductUsageDetailsCallAPI();
    if (response) {
      setProductUsageDetails(response);
    }
  };

  const currentPlan = React.useMemo(
    () =>
      PRODUCT_PLANS.find(
        (plan) => plan.code === productUsageDetails?.strawman_plan,
      ),
    [productUsageDetails],
  );

  const usersAdded = React.useMemo(() => {
    const tempLoaderVal = Math.floor(
      ((organization?.membersCount || 0 - 0) * 100) /
        (currentPlan?.users || 0 - 0),
    );
    const loaderVal = tempLoaderVal > 100 ? 100 : tempLoaderVal; // in case its > 100 set to max
    const planMemberCount =
      (organization?.membersCount || 0) > (currentPlan?.users || 0)
        ? organization?.membersCount
        : currentPlan?.users;
    const text = `${organization?.membersCount}/${planMemberCount}`;
    const percentage = `${loaderVal}%`;
    return {
      loaderVal: loaderVal,
      text,
      percentage,
    };
  }, [productUsageDetails]);

  return (
    <ViewWrapper
      error={erroredGetProductUsageDetails}
      loading={loadingGetProductUsageDetails}
    >
      <Box>
        <Paper classes={{ root: styles.classes.section }}>
          <Typography fontSize={14}>Current Plan Details</Typography>
          <Typography fontSize={28} marginTop={1}>
            {currentPlan?.heading}
          </Typography>

          <Box className={styles.classes.planItems}>
            {currentPlan?.features.map((feature, idx) => (
              <Typography key={idx}>
                <CheckIcon fontSize="small" /> {feature}
              </Typography>
            ))}
          </Box>

          <Box className={styles.classes.bllingDetails}>
            <Box>
              <Typography fontSize={14} color="#959595">
                Start Date
              </Typography>
              <Typography fontSize={14} marginTop={0.4}>
                {productUsageDetails?.contract_start_date || "-"}
              </Typography>
            </Box>

            {productUsageDetails?.strawman_plan !== "TRIAL" && (
              <Box>
                <Typography fontSize={14} color="#959595">
                  Next Billing Date
                </Typography>
                <Typography fontSize={14} marginTop={0.4}>
                  -
                </Typography>
              </Box>
            )}

            <Box>
              <Typography fontSize={14} color="#959595">
                Next Billing Amount
              </Typography>
              <Typography fontSize={14} marginTop={0.4}>
                {productUsageDetails?.strawman_plan === "TRIAL"
                  ? "N/A (Trial)"
                  : "Contact us for pricing"}
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Paper classes={{ root: styles.classes.section }}>
          <Typography fontSize={14}>Plan Usage</Typography>

          <Box className={styles.classes.usageDetails}>
            <Box>
              <Typography fontSize={14} marginBottom={0.4}>
                Users
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  productUsageDetails?.strawman_plan === "PRO"
                    ? 0
                    : usersAdded.loaderVal
                }
                color="success"
              />
              <Typography fontSize={14} color="#959595" marginTop={0.4}>
                {productUsageDetails?.strawman_plan === "PRO"
                  ? "Unlimited"
                  : `${usersAdded.text} are used (${usersAdded.percentage})`}
              </Typography>
            </Box>

            <Box>
              <Typography fontSize={14} marginBottom={0.4}>
                Report calls
              </Typography>
              <LinearProgress
                variant="determinate"
                color="error"
                value={
                  ((productUsageDetails?.usage.report_calls_used || 0 - 0) *
                    100) /
                  (productUsageDetails?.usage.total_report_calls || 1000 - 0)
                }
              />
              <Typography fontSize={14} color="#959595" marginTop={0.4}>
                {numberWithCommas.format(
                  productUsageDetails?.usage.report_calls_used || 0,
                )}
                /
                {numberWithCommas.format(
                  productUsageDetails?.usage.total_report_calls || 0,
                )}{" "}
                are used ({productUsageDetails?.usage.usage_percentage + "%"})
              </Typography>
            </Box>
          </Box>
        </Paper>

        <Box className={styles.classes.plans}>
          <Typography fontSize={20} margin={1} fontWeight="bold">
            Upgrade Options
          </Typography>

          <ProductPlanCards planToExclude="TRIAL" />
        </Box>
      </Box>
    </ViewWrapper>
  );
}
