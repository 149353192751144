import { format } from "date-fns";
import { enumToOptions } from "../utils/helpers";
import { ICoZeForm, ICoZeConvertToDealForm } from "../interfaces";

enum CO_ZE_PROJECT_TYPES_WIND {
  ONWD = "Onshore Wind",
  OFWD = "Offshore Wind",
}

enum CO_ZE_PROJECT_TYPES_SOLAR {
  USSL = "Utility-Scale Solar",
  CAIS = "C&I Solar",
  RESS = "Residential Solar",
}

enum CO_ZE_PROJECT_TYPES_STORAGE {
  BAST = "Battery Storage",
}

export const CO_ZE_PROJECT_TYPES = {
  ...CO_ZE_PROJECT_TYPES_WIND,
  ...CO_ZE_PROJECT_TYPES_SOLAR,
  ...CO_ZE_PROJECT_TYPES_STORAGE,
};

export const CO_ZE_PROJECT_TYPE_OPTIONS = {
  Wind: enumToOptions(CO_ZE_PROJECT_TYPES_WIND),
  Solar: enumToOptions(CO_ZE_PROJECT_TYPES_SOLAR, ["RESS"]),
  Storage: enumToOptions(CO_ZE_PROJECT_TYPES_STORAGE, ["BAST"]),
};

export enum CO_ZE_TAX_CREDIT_BONUS_ADDRES {
  DC = "Domestic Content",
  EC = "Energy Community",
}

export const CO_ZE_TAX_CREDIT_BONUS_ADDRES_OPTIONS = enumToOptions(
  CO_ZE_TAX_CREDIT_BONUS_ADDRES,
);

export enum CO_ZE_DEAL_STRUCTURE_TYPE {
  TEP = "Tax Equity Partnership",
  CEP = "Cash Equity Partnership",
  EO = "Equity Only",
  SL = "Sale-Leaseback",
}

export const CO_ZE_DEAL_STRUCTURE_TYPE_OPTIONS = enumToOptions(
  CO_ZE_DEAL_STRUCTURE_TYPE,
  ["SL"],
);

export enum CO_ZE_PROJECT_TAX_CREDIT_TYPE {
  ITC = "ITC",
  PTC = "PTC",
}

export const CO_ZE_PROJECT_TAX_CREDIT_TYPE_OPTIONS = enumToOptions(
  CO_ZE_PROJECT_TAX_CREDIT_TYPE,
);

export enum CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE {
  REG = "Regular",
  TR = "Transfer",
  DP = "Direct Pay",
}

export enum CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY {
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY_OPTIONS = enumToOptions(
  CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY,
);

export const CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS = enumToOptions(
  CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE,
);
export enum CO_ZE_DEAL_PREF_RETURN_TYPE {
  NONE = "None",
  PFCASH = "% of Total Cash",
  PCTINV = "% of Investment/Yr",
}
export const CO_ZE_DEAL_PREF_RETURN_TYPE_OPTIONS = enumToOptions(
  CO_ZE_DEAL_PREF_RETURN_TYPE,
);

export enum CO_ZE_TAX_EQUITY_TYPE {
  YBF = "Yield Based Flip",
  FF = "Fixed Flip",
  PR = "Prorata",
}

export const CO_ZE_TAX_EQUITY_TYPE_OPTIONS = enumToOptions(
  CO_ZE_TAX_EQUITY_TYPE,
  [],
);

export enum CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS {
  REG = "Regular Flip",
  P_99_67_99_5 = "99/67/99 Flip",
  PRORATA = "Percentage Interest",
}

export const CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS_OPTIONS = enumToOptions(
  CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS,
);

export enum CO_ZE_TERM_DEBT_TYPE {
  NONE = "None",
  BL = "Back-Leverage",
  FL = "Front Leverage",
}

export const CO_ZE_TERM_DEBT_TYPE_OPTIONS = enumToOptions(
  CO_ZE_TERM_DEBT_TYPE,
  ["FL"],
);

export const DEFAULT_CO_ZE_FORM_VAULES: ICoZeForm = {
  project_type: "USSL",
  project_state: "CA",
  capacity_ac: 100,
  capacity_dc: 130,
  production_method: "NCF",
  production_input: 35.0,
  degradation_rate_percentage: 0.5,
  availability_factor_percentage: 99,
  curtailment_percentage: 1,
  cod: format(new Date(), "M/d/yyyy"), // todays date
  operating_life_years: 35,
  cost: 150000000,
  fmv_step_up_percentage: 15.0,
  ppa_price: 35.0,
  ppa_term_years: 15,
  ppa_escalator: 0,
  merchant_price: 50,
  merchant_escalator: 2,
  opex: 15,
  opex_escalator: 2.0,
  opex_rate: 100,
  tax_credit_type: "ITC",
  tax_credit_bonus_adders: [],
  deal_structure: "TEP",
  deal_tax_credit_structure: "REG",
  tax_equity_type: "YBF",
  tax_equity_target_flip_irr: 7.5,
  tax_equity_target_roi: 20,
  macrs_election: "ADS",
  tax_equity_target_flip_term: 7,
  tax_credit_transfer_portion: 100,
  tax_credit_transfer_discount_rate: 8.5,
  tax_credit_payment_periodicity: "QU",
  tax_credit_transfer_payment_rate: 1.0,
  tax_credit_transfer_lag_period: 6,
  tax_equity_direct_pay_lag: 9,
  tax_equity_pre_flip_cash_split: 20,
  tax_equity_pre_flip_tax_split_percent: 99,
  tax_equity_preferred_return_type: "NONE",
  tax_equity_preferred_return_percent: 2,
  sponsor_return_of_capital: 98,
  buyout_type: "",
  buyout_amount_discount_rate: 0,
  tax_equity_tax_allocation_method: "REG",
  term_debt_type: "BL",
  debt_term: 15,
  debt_interest_rate: 6,
  debt_coverage_ratio: 1.3,
  equity_sponsor_irr: 7.5,
  equity_tax_method: "ATIN",
};

export const DEFAULT_CO_ZE_CONVERT_DEAL_FORM_VAULES: ICoZeConvertToDealForm = {
  project_name: "",
  deal_name: "",
};

export const CO_ZE_SPONSOR_EQUITY_IRR_IGNORE_FIELDS: string[] = [
  "Pre-Tax IRR (Buyout-Term)",
  "After-Tax IRR (Buyout-Term)",
  "Pre-Tax MOIC (Buyout-Term)",
  "After-Tax MOIC (Buyout-Term)",
  "Pre-Tax NPV",
  "After-Tax NPV",
  "Pre-Tax NPV (Buyout-Term)",
  "After-Tax NPV (Buyout-Term)",
  "Pre-Tax Discount Rate",
  "After-Tax Discount Rate",
];

export const CO_ZE_TAX_EQUITY_IRR_IGNORE_FIELDS: string[] = [
  "After-Tax IRR (Buyout-Term)",
  "ROI (Buyout-Term)",
];

export const CO_ZE_TAX_EQUITY_TABLE_IRR_ROWS_TO_IGNORE = [
  "pre_tax_irr_floor",
  "pre_tax_irr_shortfall",
  "actual_flip_tenor",
  "flip_delay",
  "dro_cap",
  "dro_cap_exceedance",
];
