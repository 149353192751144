import React from "react";
import Tooltip from "@mui/material/Tooltip";
import MuiButton, { ButtonProps } from "@mui/material/Button";

import useStyles from "./styles";
import { ButtonType } from "../../interfaces";
import { AppContext } from "../../utils/context/app-context";

interface IProps extends ButtonProps {
  btnType: ButtonType;
  label: string;
  canOpenUpgrade?: boolean;
  dataPw?: string;
  tooltip?: string;
}

export default function Button({
  btnType,
  label,
  canOpenUpgrade = false,
  dataPw,
  tooltip,
  ...btnProps
}: IProps): JSX.Element {
  const styles = useStyles({ type: btnType });

  const { productUsageDetails, setShowUsageLimitHitModal } =
    React.useContext(AppContext);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (canOpenUpgrade) {
      if (productUsageDetails?.strawman_plan === "TRIAL") {
        setShowUsageLimitHitModal(true);
        return;
      }
    }
    btnProps.onClick && btnProps.onClick(e);
  };

  return (
    <Tooltip
      title={tooltip}
      placement="top-end"
      classes={{ tooltip: styles.classes.tooltip }}
    >
      <MuiButton
        classes={{ root: styles.classes.root }}
        data-pw={dataPw || label}
        {...btnProps}
        onClick={handleOnClick}
      >
        {label}
      </MuiButton>
    </Tooltip>
  );
}
