import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { Protect } from "@clerk/clerk-react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";

import useStyles from "./styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import EditTaxCreditDirectPay from "../../../../components/edit-tax-credit-direct-form-modal";
import EditDealTaxCreditDirectPay from "../../../../components/edit-deal-tax-credit-direct-pay-form-modal";
import { AppContext } from "../../../../utils/context/app-context";
import { numberToUSD } from "../../../../utils/helpers";
import { useAPI } from "../../../../utils/hooks";
import {
  USER_PERMISSIONS,
  DEAL_TAX_CREDIT_TRANSFER_PERIODICITY,
  DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IDealDetails,
  IDealTaxCreditTransfer,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferFormErrors,
  IDealTaxCreditTransferResults,
  IUpdateDealTaxCreditTranferConstraintsForm,
  IUpdateDealTaxCreditTranferConstraintsFormErrors,
} from "../../../../interfaces";

interface IProps {
  getDealTaxCreditDirectPayDetails: (
    id: number,
  ) => Promise<IDealTaxCreditTransfer[]>;
  updateDealTaxCreditDirectPayDetails: (
    dealId: number,
    transferSizingId: number,
    form: IDealTaxCreditTransferForm,
  ) => Promise<IDealTaxCreditTransferResults>;
  updateDeal: (
    dealId: number,
    form: IUpdateDealTaxCreditTranferConstraintsForm,
  ) => Promise<IDealDetails>;
}

export default function DealDirectPayView({
  getDealTaxCreditDirectPayDetails,
  updateDealTaxCreditDirectPayDetails,
  updateDeal,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { dealId, caseDealId } = useParams();
  const { currentDeal, setCurrentDeal } = React.useContext(AppContext);

  const [dealTaxCreditDirectPay, setdealTaxCreditDirectPay] = React.useState<
    IDealTaxCreditTransfer[]
  >([]);

  const [form, setForm] = React.useState<IDealTaxCreditTransferForm>(
    DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  );
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
  const [openDealEditModal, setOpenDealEditModal] =
    React.useState<boolean>(false);

  const [
    taxCreditTransferConstraintsForm,
    setTaxCreditTransferConstraintsForm,
  ] = React.useState<IUpdateDealTaxCreditTranferConstraintsForm>(
    UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  );

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const {
    callAPI: getDealTaxCreditDirectPayDetailsCallAPI,
    errored: getDealTaxCreditDirectPayFailed,
    loading: loadingDealTaxCreditDirectPay,
  } = useAPI((dealId: number) => getDealTaxCreditDirectPayDetails(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealCallAPI,
    fieldErrors: dealFormErrors,
    setFieldErrors: setDealFormErrors,
  } = useAPI<IDealDetails, IUpdateDealTaxCreditTranferConstraintsFormErrors>(
    (dealId: number, form: IUpdateDealTaxCreditTranferConstraintsForm) =>
      updateDeal(dealId, form),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: updateDealTaxCreditDirectPayDetailsAPI,
    loading: loadingUpdateDealTaxCreditDirectPayDetailsDetails,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealTaxCreditTransferResults, IDealTaxCreditTransferFormErrors>(
    (form: IDealTaxCreditTransferForm, directPayId: number) =>
      updateDealTaxCreditDirectPayDetails(Number(dealId), directPayId, form),
  );

  const getDealTaxCreditDetails = (dealId: number) => {
    getDealTaxCreditDirectPayDetailsCallAPI(dealId).then((response) => {
      response && setdealTaxCreditDirectPay(response);
    });
  };

  React.useEffect(() => {
    getDealTaxCreditDetails(Number(caseDealId));
  }, [caseDealId]);

  const rows = [
    {
      key: "Key Terms",
    },
    {
      key: "Tax Credit Type",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          d?.project_credit_type || "N/A",
        ]),
      ),
    },
    {
      key: "Tax Credit",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          numberToUSD.format(Number(d?.tax_credits_without_adders)) || "N/A",
        ]),
      ),
    },
    {
      key: "Tax Credit Adder",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          numberToUSD.format(Number(d?.tax_credits_adders)) || "N/A",
        ]),
      ),
    },
    {
      key: "Tax Credit Total",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          numberToUSD.format(Number(d?.tax_credits_total)) || "N/A",
        ]),
      ),
    },
    {
      key: "Direct Pay Portion",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          d?.transfer_sizing?.transfer_portion + "%" || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Rate",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          Number(d?.transfer_sizing?.payment_rate).toFixed(4) + " $/Credit" ||
            "N/A",
        ]),
      ),
    },
    {
      key: "Payment Total",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          numberToUSD.format(Number(d?.payment_total)) || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Periodicity",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d?.project?.name,
          DEAL_TAX_CREDIT_TRANSFER_PERIODICITY[
            d?.transfer_sizing
              ?.payment_periodicity as keyof typeof DEAL_TAX_CREDIT_TRANSFER_PERIODICITY
          ] || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Reference Date",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.payment_reference_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Payment Dates Lag",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          d?.transfer_sizing?.payment_dates_lag + " months",
        ]),
      ),
    },
    {
      key: "Payment Date Num 1",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.payment_date_num_1), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Key Dates",
    },
    {
      key: "Placed in Service Date",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.placed_in_service_date), "M/dd/yyyy") || "N/A",
        ]),
      ),
    },
    {
      key: "Pre-Filing Date (per project)",
      ...Object.fromEntries(
        dealTaxCreditDirectPay.map((d) => [
          d.project.name,
          format(new Date(d?.transfer_sizing?.pre_filing_date), "M/dd/yyyy") ||
            "N/A",
        ]),
      ),
    },
  ];

  const columns = dealTaxCreditDirectPay.map((d) => {
    return {
      name: d.project.name,
      id: d.transfer_sizing?.id,
      projectId: d?.project?.id,
    };
  });

  const openDealTaxCreditEditModal = (id: number) => {
    setOpenEditModal(true);
    const dealTaxCreditDirect = dealTaxCreditDirectPay.find((d) => {
      return d.transfer_sizing.id === id;
    });
    setForm({
      id: String(id),
      payment_date_num_1: dealTaxCreditDirect?.payment_date_num_1 ?? "",
      payment_dates_lag:
        dealTaxCreditDirect?.transfer_sizing?.payment_dates_lag ?? "",
      payment_periodicity:
        dealTaxCreditDirect?.transfer_sizing?.payment_periodicity ?? "",
      payment_rate: dealTaxCreditDirect?.transfer_sizing?.payment_rate ?? "",
      placed_in_service_date: dealTaxCreditDirect?.placed_in_service_date ?? "",
      pre_filing_date:
        dealTaxCreditDirect?.transfer_sizing?.pre_filing_date ?? "",
      transfer_portion:
        dealTaxCreditDirect?.transfer_sizing?.transfer_portion ?? "",
      isPTC: dealTaxCreditDirect?.project_credit_type === "PTC",
      isITC: dealTaxCreditDirect?.project_credit_type === "ITC",
      creditStartDate: "itc start date",
      creditEndDate: "itc end date",
    });
  };

  const openDealTaxCreditPortfolioEditModal = () => {
    setOpenDealEditModal(true);
    setTaxCreditTransferConstraintsForm({
      transfer_maximum_payment: currentDeal?.transfer_maximum_payment || "",
      transfer_minimum_roi: currentDeal?.transfer_minimum_roi || "",
      transfer_discount_rate: currentDeal?.transfer_discount_rate || "",
    });
  };

  const handleEditDealTaxCreditDirectPay = async (
    form: IDealTaxCreditTransferForm,
  ) => {
    const updatedForm = {
      payment_dates_lag: form.payment_dates_lag,
      payment_periodicity: form.isPTC ? form.payment_periodicity : null,
      payment_rate: form.payment_rate,
      placed_in_service_date: form.placed_in_service_date,
      pre_filing_date: form.pre_filing_date,
      transfer_portion: form.transfer_portion,
    };
    const { id } = form;
    const dealTaxCreditDirectPay = await updateDealTaxCreditDirectPayDetailsAPI(
      updatedForm,
      Number(id),
    );
    dealTaxCreditDirectPay && getDealTaxCreditDetails(Number(caseDealId));
    dealTaxCreditDirectPay && setOpenEditModal(false);

    return dealTaxCreditDirectPay;
  };

  const handleEditDealTaxCreditDirectPayConstraint = async (
    form: IUpdateDealTaxCreditTranferConstraintsForm,
  ) => {
    const updatedDeal = await updateDealCallAPI(Number(caseDealId), form);
    updatedDeal && setCurrentDeal(updatedDeal);
    updatedDeal && setOpenDealEditModal(false);
    return updatedDeal;
  };

  return (
    <>
      <ViewWrapper
        loading={loadingDealTaxCreditDirectPay}
        error={getDealTaxCreditDirectPayFailed}
      >
        <Box>
          {dealTaxCreditDirectPay.length === 0 ? (
            <Box className={styles.classes.message}>
              <Typography variant="body1" className={styles.classes.message}>
                This Deal does not have any Projects that have elected Tax
                Credit Direct Pay.
              </Typography>
            </Box>
          ) : (
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      <TableCell>
                        {!isOnCase && (
                          <Button
                            canOpenUpgrade
                            btnType="primary"
                            label="Portfolio"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={openDealTaxCreditPortfolioEditModal}
                          />
                        )}
                      </TableCell>
                      {columns.map((c, idx) => {
                        return (
                          <TableCell key={idx}>
                            <Link
                              to={`/project/${c?.projectId}/tax/tax-credit`}
                              target="_blank"
                            >
                              {c?.name}
                            </Link>
                            <br />
                            {!isOnCase && (
                              <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                                <Button
                                  canOpenUpgrade
                                  btnType="primary"
                                  label="Edit"
                                  size="small"
                                  startIcon={<EditIcon />}
                                  onClick={() =>
                                    openDealTaxCreditEditModal(c?.id)
                                  }
                                />
                              </Protect>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row: { [key: string]: string }) => {
                      return (
                        <TableRow key={row.key}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              fontWeight: ["Key Dates", "Key Terms"].includes(
                                row?.key,
                              )
                                ? "bold"
                                : "",
                            }}
                          >
                            {row.key}
                          </TableCell>
                          {columns.map((c) => {
                            return <TableCell>{row[c?.name]}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </ViewWrapper>

      <EditTaxCreditDirectPay
        headerLabel="Edit Tax Credit Direct Pay"
        loading={loadingUpdateDealTaxCreditDirectPayDetailsDetails}
        form={form}
        setForm={setForm}
        onClose={() => setOpenEditModal(false)}
        open={openEditModal}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        onConfirm={handleEditDealTaxCreditDirectPay}
      />

      <EditDealTaxCreditDirectPay
        headerLabel="Edit Portfolio Tax Credit Direct Pay"
        loading={loadingUpdateDealTaxCreditDirectPayDetailsDetails}
        form={taxCreditTransferConstraintsForm}
        setForm={setTaxCreditTransferConstraintsForm}
        onClose={() => setOpenDealEditModal(false)}
        open={openDealEditModal}
        formErrors={dealFormErrors}
        setFormErrors={setDealFormErrors}
        onConfirm={handleEditDealTaxCreditDirectPayConstraint}
      />
    </>
  );
}
